import { keyframes } from "@emotion/react";
import { ActionIcon, Badge, Center, Grid, LoadingOverlay, Stack, Text, Title, Tooltip, createStyles } from "@mantine/core";
import axios from "axios";
import { useEffect, useState } from "react";
import { Logger } from "../Common/Shared/Logger";
import { IconCaretLeft, IconCaretRight, IconPlayerEject, IconPlayerPause, IconPlayerPlay, IconPlayerStop, IconPlayerTrackNext, IconPlayerTrackPrev } from "@tabler/icons-react";
import YouTube from "react-youtube";
import * as ReactDOM from 'react-dom';

export const keyframeMoveUpDown = keyframes({
    "0%": {
        bottom: 0,
    },
    "100%": {
        bottom: 0,
    },
    "50%": {
        bottom: "100px",
    },
})

const useMiniPlayerStyles = createStyles((theme) => ({
    wrap: {
        maxWidth: "50em",
        backgroundColor: "transparent",
        position: "relative", // loading overlay containment 
    },
    wrapBackgroundGlow: {
        position: "absolute",
        display: "block",
        height: "250%",
        width: "100%",
        background: "linear-gradient(-188deg, rgb(239, 83, 80), rgb(255, 145, 0), rgb(255, 171, 0), rgb(0, 230, 118), rgb(61, 90, 254), rgb(101, 31, 255), rgb(244, 0, 136), rgb(239, 83, 80), rgb(255, 145, 0), rgb(255, 171, 0), rgb(0, 230, 118), rgb(61, 90, 254))",
        zIndex: 999,
    },
    backgroundGlowWrap: {
        position: "absolute",
        height: "calc(100% * 1)",
        width: "100%",
        animation: `${keyframeMoveUpDown} 5s linear infinite`,
        left: 0,
        bottom: 0,
        zIndex: 999,
        opacity: 0.1,
    },
    animationWrap: {
        overflow: "hidden",
        height: "100%",
        width: "100%",
        position: "absolute",
    },
    iframeWrapper: {
        display: "none"
    },
    iframe: {
        display: "none"
    },
    interactable: {
        // height: "100%",
        height: "min-content",
        width: "100%",
        padding: 0,
    }
}))

export function MusicPlayModule(){
    const {classes} = useMiniPlayerStyles();
    const [isPaused, setIsPaused] = useState(true);
    const [playlistFetched, setPlaylistFetched] = useState(false);
    const [videos, setVideos] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideo, setCurrentVideo] = useState({title: "", creator: ""});
    const [currentVideoTarget, setCurrentVideoTarget] = useState({});
    const currentVideoIndex = videos.indexOf(currentVideo) >=0 ? videos.indexOf(currentVideo) : 0;
    const youtubeApiKey = "AIzaSyBunS2RWj23zbqRPS5BrtOX0AzuntfxVIQ";
    const playlistId = "PLs3Nwml3aQR22csEsyxwcCR6w-jL-_ELz";

    const handlePlaylistData = (res) => {
        const data = res.data;
        const items = data.items;
        const itemsProc = videos;

        for(const item of items){
            let proc = {
                title: item.snippet.title,
                id: item.snippet.resourceId.videoId,
                creator: item.snippet.videoOwnerChannelTitle
            };

            if(!(proc.title || proc.id || proc.creator)){
                return;
            } else {
                itemsProc.push(proc);
            }
        }

        setVideos(itemsProc);

        if(data.items.length >= 50) {
            fetchPlaylistData(data.nextPageToken);
        } else {
            setCurrentVideo(videos[0] ?? itemsProc[0] ?? null);
        }
    }

    const previousVideo = () => {
        setIsPlaying(false);

        if(currentVideoIndex === 0){
            setCurrentVideo(videos[videos.length - 1]);
        } else {
            setCurrentVideo(videos[currentVideoIndex - 1]);
        }
    }

    const nextVideo = () => {
        setIsPlaying(false);

        if(currentVideoIndex === videos.length - 1) {
            setCurrentVideo(videos[0]);
        } else {
            setCurrentVideo(videos[currentVideoIndex + 1]);
        }
    }

    const togglePlay = async (e) => {
        setIsPaused(!isPaused);
    }

    useEffect(async () => {
        setIsPlaying(false);
        while(true){
            try {
                await new Promise(r => setTimeout(r, 20));
                ReactDOM.unmountComponentAtNode(document.getElementById("thematic-playlist-player"));
                break;
            } catch {
         
            }
        }
    }, [isPaused, currentVideoTarget])

    const fetchPlaylistData = (token=null) => {
        axios.get(`https://www.googleapis.com/youtube/v3/playlistItems?playlistId=${playlistId}&part=snippet,id&maxResults=50&key=` + youtubeApiKey + (token ? `&pageToken=${token}` : "")).then(handlePlaylistData).catch((e) => {
            Logger.LogEvent(16, e);
        });
    }

    useEffect(() => {
        if(!playlistFetched){
            fetchPlaylistData();
            setPlaylistFetched(true);
        } 
    }, [])

    const handler = { 
        onReady: (e) => {
            // console.log("onReady:", e);

            if(!isPaused){
                e.target.playVideo();
            }
        },
        onPlay: (e) => {
            // console.log("onPlay:", e);

            setIsPaused(false);
            setIsPlaying(true);
        },
        onPause: (e) => {
            // console.log("onPause:", e);

            // setIsPaused(true);
        },
        onEnd: (e) => {
            // console.log("onEnd:", e);

            nextVideo();
        },
        onError: (e) => {
            // console.log("onError:", e);

        },
        onStateChange: (e) => {
            // console.log("onStateChange:", e);
            setCurrentVideoTarget(e.target);
            // console.log("target:",currentVideoTarget)

            if(!isPaused && !isPlaying){
                e.target.playVideo();
            }
        },
        onPlaybackRateChange: (e) => {
            // console.log("onPlaybackRateChange:", e);

        },
        onPlaybackQualityChange: (e) => {
            // console.log("onPlaybackQualityChange:", e);

        },
    }

    return (
        <div id="thematic-playlist-controls-main"
            style={{

            }}
        >
            <div id="thematic-playlist-controls" className={classes.wrap} style={{
                display: videos.length > 0 ? "" : "none"
            }}>
                <LoadingOverlay
                    visible={!isPaused && !isPlaying}
                    // visible={true}
                    overlayBlur={0}
                    zIndex={10000}
                />
                <div className={classes.animationWrap}>
                    <div className={classes.backgroundGlowWrap}>
                        <div className={classes.wrapBackgroundGlow}/>
                    </div>
                </div>
                <Grid style={{
                    margin: 0,
                    position: "relative",
                    zIndex: 1000
                }}>
                    
                    <Grid.Col span={12} w={"100%"} h={"100%"}>
                        <Stack spacing={0} justify="center" w={"100%"} h={"100%"}>
                            <Title order={4} style={{marginRight: "1em"}}>
                                {currentVideo.title}
                            </Title>
                            <Title order={5} color="lightgreen">
                                {"@" + currentVideo.creator}
                            </Title>
                            <hr style={{ width: "100%", marginTop:5, marginBottom: 5, marginLeft: 0, marginRight: 0}}/>

                            <Grid style={{
                                marginRight: 0,
                                marginLeft: 0,
                                paddingRight: 0,
                            }}>
                                <Grid.Col span={8} style={{
                                    paddingLeft: 0,
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <Tooltip
                                        label="Skipping a lot? Pause first."
                                        position="top-start"
                                        withArrow={true}
                                    >   
                                        <div>
                                            <Title order={6}>
                                                {
                                                    `Song ${currentVideoIndex + 1} out of ${videos.length}`
                                                }
                                            </Title>
                                            <Text fz="xs" style={{opacity: 0.5}}>
                                                May play YouTube ads
                                            </Text>
                                        </div>
                                    </Tooltip>
                                </Grid.Col>
                                <Grid.Col span={4} w={"100%"} style={{
                                    justifySelf: "center",
                                    height: "inherit",
                                    justifyContent: "center",
                                    verticalAlign: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    paddingRight: 0,
                                }}>
                                    <div id="thematic-playlist-controls-interactables" style={{
                                        width: "100%", 
                                        height:"100%",
                                        justifyContent: "center",
                                        verticalAlign: "center",
                                    }}>
                                        <Grid style={{
                                            height: "100%",
                                            justifyContent: "space-evenly",
                                            margin: 0,
                                            alignItems: "center",
                                        }}>
                                            <Grid.Col span={3} className={classes.interactable}>
                                                <ActionIcon variant="light" onClick={previousVideo}>
                                                    <IconCaretLeft/>
                                                </ActionIcon>
                                            </Grid.Col>
                                            <Grid.Col span={3} className={classes.interactable}>
                                                <ActionIcon variant="light" onClick={togglePlay}>
                                                    {
                                                        isPaused ? <IconPlayerPlay/> : <IconPlayerPause/>
                                                    }
                                                </ActionIcon>
                                            </Grid.Col>
                                            <Grid.Col span={3} className={classes.interactable}>
                                                <ActionIcon variant="light" onClick={nextVideo}>
                                                    <IconCaretRight/>
                                                </ActionIcon>
                                            </Grid.Col>
                                            {/* <Grid.Col span={0}/> */}
                                        </Grid>
                                        <div className={classes.interactable}>
                                            
                                        </div>
                                    </div>
                                </Grid.Col>
                            </Grid>

                            
                        </Stack>
                    </Grid.Col>
                    
                </Grid>
            </div>
            <div id="thematic-playlist-source">
                {
                    !isPaused && 
                    <YouTube
                    opts={{
                        height: "200",
                        width: "200", // <> "minimum" according to google docs
                        playerVars: {
                            autoplay: 0
                        }
                    }}
                    videoId={currentVideo.id ?? "K8CLS0iINek"}   
                    id="thematic-playlist-player"
                    iframeClassName={classes.iframe}
                    className={classes.iframeWrapper}
                    onReady={handler.onReady}             
                    onPlay={handler.onPlay}              
                    onPause={handler.onPause}             
                    onEnd={handler.onEnd}               
                    onError={handler.onError}             
                    onStateChange={handler.onStateChange}       
                    onPlaybackRateChange={handler.onPlaybackRateChange}
                    onPlaybackQualityChange={handler.onPlaybackQualityChange}    
                    /> 
                }
            </div>
        </div>
    )
}
