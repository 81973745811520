/* eslint-disable no-extend-native */

import React from "react";
import { Logger } from "./Shared/Logger";

// Dienen geschreven te worden middels function(){} syntax om de correcte "this" te kunnen gebruiken
// Bij een arrow function zal "this" anders verwijzen naar window, in dit geval verwijst "this" naar het object zelf 
const LoadExtensionMethods = () => {
    Array.prototype.last = function () {
        return this[this.length - 1];
    }

    // Array of String / Element, any other type gets skipped and logged as event
    Array.prototype.getOccupiedHeight = function () {
        let occupiedHeight = 0;

        for (const element of this) {
            if (typeof element === "string") {
                let ele = element.tryGetElement();

                if (ele) {
                    occupiedHeight += ele.getActualHeight();
                }
            } else if (element instanceof Element) {
                occupiedHeight += element.getActualHeight();
            } else {
                Logger.LogEvent(6, element);
            }
        }

        return occupiedHeight;
    }

    // These 2 functions are to be used when trying to garner a px amount to afix a div to, whereby the provided array are Elements which reside above the div for which this height needs to be garnered
    // e.g.
    // [nav]
    // [header]
    // [div-to-size]
    // height = getRemainingAvailableHeight([nav, header])
    // div-to-size.[height, maxHeight] = height
    Window.prototype.getRemainingAvailableHeight = function (elements) {
        let innerHeight = this.innerHeight;
        let occupiedHeight = elements.getOccupiedHeight();

        return innerHeight - occupiedHeight;
    }

    // NOK
    Document.prototype.getRemainingAvailableHeight = function (elements) {
        let clientHeight = this.documentElement.clientHeight;
        let occupiedHeight = elements.getOccupiedHeight();

        return clientHeight - occupiedHeight;
    }

    // Tries to garner the Element by id using the string value 
    String.prototype.tryGetElement = function () {
        try {
            let res = document.getElementById(this) ?? null;

            if (!res) {
                Logger.LogEvent(5, this);
            }

            return res;
        } catch {
            Logger.LogEvent(4, this);
            return null;
        }
    }

    Element.prototype.getComputedStyle = function () {
        return window.getComputedStyle(this);
    }

    // Solely gets the [rendered, actual] *padding* height of the Element
    Element.prototype.getActualPaddingHeight = function () {
        let styles = this.getComputedStyle();

        return Math.ceil(parseFloat(styles["paddingTop"]) + parseFloat(styles["paddingBottom"]));
    }

    // // QOL
    // Function.prototype.getAssignmentName = function() {
    //     return Object.keys({this: undefined})[0];
    // }

    // Object.prototype.getAssignmentName = function (){
    //     return Object.keys({this: undefined})[0];
    // }

    // Solely gets the [rendered, actual] *margin* height of the Element
    Element.prototype.getActualMarginHeight = function () {
        let styles = this.getComputedStyle();

        return Math.ceil(parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]));
    }

    // Gets the [rendered, actual] height of the Element 
    Element.prototype.getActualHeight = function () {
        let margin = this.getActualMarginHeight();

        return Math.ceil(this.offsetHeight + margin);
    }

    JSON.safeStringify = (obj, indent = 2) => {
        let cache = [];
        const retVal = JSON.stringify(
            obj,
            (key, value) =>
                typeof value === "object" && value !== null
                    ? cache.includes(value)
                        ? undefined // Duplicate reference found, discard key
                        : cache.push(value) && value // Store value in our collection
                    : value,
            indent
        );
        cache = null;
        return retVal;
    };

    // [Deprecated]
    // Element.prototype.getInclusiveHeight = function(extraHeight=0){
    //     const list = [
    //         'margin-top',
    //         'margin-bottom',
    //         'border-top',
    //         'border-bottom',
    //         'padding-top',
    //         'padding-bottom',
    //         'height'
    //     ]

    //     const style = window.getComputedStyle(this)
    //     return list.map(k => parseInt(style.getPropertyValue(k), 10))
    //                .reduce((prev, cur) => prev + cur)
    //                + extraHeight;
    // }

    return true;
}

export default LoadExtensionMethods;