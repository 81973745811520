import { Container } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router"
import { Logger } from "./Shared/Logger";
import { CommonUtil } from "./Shared/CommonUtil";

const cloneDeep = require("lodash.clonedeep");

// Should be used as a wrapper around the page content (excl. navbar and footer), allowing for adjustable styling and whatnot
// Size (width) is fixed to xl by default in both Content and Segment components  
export const Content = ({id, children, style, classes, size}) => {
    if(!id){
        return (
            <>
                <h1>Please provide an id for this Content instance</h1>
            </>
        )
    }

    return (
        <Container 
            id={id} 
            style={style ? style : {

            }} 
            className={classes ? classes : 
                "" // by default the margin and padding responsibilities are at the Segment component defined below
            } 
            size={size ? size : "xl"}
        >
            {children}
        </Container>
    );
}


// Intended for distinct page Content sections
export const Segment = ({id, style, size, children, classes, isFirstSegment=false, isLastSegment=false}) => {
    if(!id){
        return (
            <>
                <h1>Please provide an id for this Segment instance</h1>
            </>
        )
    }

    return (
        <Container 
            id={id} 
            style={style ? style : {
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: "96.5rem" // new , cf. size prop 
            }} 
            className={classes ? classes :
                // if the Segment is the first within the page's Content, the default classes are different 
                isFirstSegment ? "mt-3" : 
                // in a similar fashion, if the Segment is the last in the page's Content
                isLastSegment ? "mt-3 mb-3" :
                // the Segment is somewhere in the middle
                "mt-3"
            } 
            size={size ? size : "xl"}   

        >
            {children}
        </Container>
    )
}

export const DynamicHeightSegment = function({preludeElements, id, children: dependants, style=null, classes=null, isFirstSegment=false, isLastSegment=false}){
    const [remainingAvailableHeight, setRemainingAvailableHeight] = useState(0);

    const handleEvent = () => {
        try {
            const topLevelElement = document.getElementById(id);

            // we get an overbearing amount to start with, any margins or paddings at this point will introduce a scrollbar if none exists so far 
            let availableHeight = window.getRemainingAvailableHeight(preludeElements);

            // we account for the inherent margins and paddings of the toplevel element to which we'd like to "allocate" an amount of height in pixels
            availableHeight -= topLevelElement.getActualMarginHeight();
            availableHeight -= topLevelElement.getActualPaddingHeight();

            setRemainingAvailableHeight(availableHeight >= 0 ? availableHeight : 0);
        } catch (e) { 
            Logger.LogEvent(7);
        }
    }

    useEffect(() => {
        document.addEventListener("DOMContentLoaded", handleEvent);
        window.addEventListener("resize", handleEvent);

        handleEvent();

        /*~*/ return () => {
            document.removeEventListener("DOMContentLoaded", handleEvent);
            window.removeEventListener("resize", handleEvent);
        };
    }, []);

    // ---- Garnering a processed version of prop "style" & defining default style
    // opt paddingLeft|Right
    const unoverrideableStyles = ["height", "maxHeight"];
    const defaultStyles = {
        height: `${remainingAvailableHeight}px`,
        maxHeight: `${remainingAvailableHeight}px`,
        paddingLeft: 0,
        paddingRight: 0,
    }
    const [providedStyles, setProvidedStyles] = useState({});

    useEffect(() => {        
        if(style){
            let newProvidedStyles = cloneDeep(style);

            setProvidedStyles(newProvidedStyles);
        }
    }, []);
    // ----

    return (
        <Segment
            id={id}
            style={{
                ...defaultStyles,
                ...providedStyles
            }}
            classes={classes}
            isFirstSegment={isFirstSegment}
            isLastSegment={isLastSegment}
        >
            {dependants}
        </Segment>
    );
}

// Could be nice to have a Fragment component as well (totally just going by the names belonging together) - but then we're really trying to reinvent the grid and its columns - perhaps when deemed purposeful in the future :
// export 
const Fragment = () => {
    return (
        <>
            <h1>Not implemented</h1>
        </>
    )
}




