import { Button, Center, Checkbox } from "@mantine/core";
import { useState } from "react";
import React from "react";
import { Logout } from "tabler-icons-react";


export const NavbarSignOutModal = ({id, context, innerProps}) => {
    const { authContext } = innerProps;
    const [checked, setChecked] = useState(false);

    return (
        <>
        {
            !authContext.Session.loggedIn &&
            <div>
                <span>You are already signed out. If you experience any issues performing actions on the platform, please refresh your browser window.</span>
            </div>
        }
        {
            authContext.Session.loggedIn &&
            <div>
                <p>Are you sure you wish to sign out?</p>
                <Checkbox
                    label="Sign out of all active sessions for this public identifier"
                    className="mt-1 mb-1"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                />
                <hr/>
                <Center>
                    <Button 
                        leftIcon={<Logout size="1rem"/>} 
                        style={{backgroundColor: "rgba(245, 159, 0, 0.7)"}} 
                        onClick={() => {
                            authContext.fn.SignOut(checked ?? false);
                            context.closeModal(id);
                        }}
                    >
                        Sign out
                    </Button>
                </Center>
        </div>
        }
        </>
    );
}
