import React, { useContext, useState } from "react";
import { AuthContext } from "../Common/Wrappers/AuthWrapper";
import { Button, Center, Checkbox, TextInput, Textarea } from "@mantine/core";
import { Bolt } from "tabler-icons-react";

export const NavbarSignInModal = React.memo(function({id, context, innerProps}) {
    // const authContext = useContext(AuthContext);
    const { authContext } = innerProps;
    const [checked, setChecked] = useState(false);
    const [publicIdentifier, setPublicIdentifier] = useState("");
    const [signedMessage, setSignedMessage] = useState("");

    return (
        <>
        {
            authContext.Session.loggedIn &&
            <div>
                <span>You are already signed in. If you experience any issues performing actions on the platform, please refresh your browser window.</span>
            </div>
        }
        {
            !authContext.Session.loggedIn &&
            <div>
                <p>Create a nyzo.org session message <a style={{color: "white"}} href="https://construct0.github.io/nyzo-org/sessionMessages.html" target="_blank">using the open source sign & validate tool.</a><br/>Enter your public identifier and signed session message below to sign in.</p>
                
                <TextInput
                    placeholder="134c745ace0685684eff306853268b6eea82cfa84376ad7516dd4381b7bdcbd9"
                    label="Public identifier"
                    withAsterisk={true}
                    required={true}
                    spellCheck={"false"}
                    autoComplete={"false"}
                    autoCapitalize={"false"}
                    // minLength={64}
                    // maxLength={64}
                    value={publicIdentifier}
                    onChange={(e) => setPublicIdentifier(e.currentTarget.value)}
                />
                <Textarea
                    className="mt-2 mb-3"
                    placeholder="b82b5c46f748ba28335ecea492204f48d289b422ba756e6ff1bd0db8fa5e81b42bf9683e35adcc3f7a22f65bd4b8f449cda61afeb937112eff3356954a999b005b53455353494f4e3a3a6e797a6f2e6f72673a3a313334633734356163653036383536383465666633303638353332363862366565613832636661383433373661643735313664643433383162376264636264393a3a46524f4d3a3a313732333438343431333a3a554e54494c3a3a313732333438343437333a3a454e445d"
                    label="Signed session message"
                    withAsterisk={true}
                    required={true}
                    minLength={200}
                    maxLength={1000}
                    spellCheck={"false"}
                    autoComplete={"false"}
                    autoCapitalize={"false"}
                    minRows={4}
                    value={signedMessage}
                    onChange={(e) => setSignedMessage(e.currentTarget.value)}
                />
                <hr/>
                <Textarea
                    className="mb-2"
                    disabled={false}
                    label="Privacy policy"
                    withAsterisk={true}
                    contentEditable={false}
                    readOnly={true}
                    spellCheck={false}
                    minRows={6}
                    maxRows={6}
                    value={`You hereby confirm that you're at least 18 years old if you are a resident or citizen of the European Union. If you are not a resident or citizen of the European Union, you hereby confirm that you are at least 13 years old.

You hereby confirm that you agree to use a pseudonym username which does not associate to your personal identifying information. This include, but is not limited to, any association with your social media accounts, websites you own or manage, your ethnicity, location, school, email accounts, workplaces or real name.

You hereby confirm that any actions you perform on the platform will not contain information which may expose your personally identifying information.

You hereby confirm that all data you submit to our website will not identify you and that we are not obligated to remove it based on personal privacy.

You hereby confirm that you will not post information which might personally or associatively identify another person. This includes but is not limited to emails, social media accounts, addresses, screenshots, or real names.

You hereby confirm that all your actions meet the standard for "important public interest" or "journalism" and as such will not be required to be altered or deleted at a later point in time.

You hereby confirm you will not use the platform to conduct deals, trades, sales or other market activity. Your personal identifying information may be stored and even shared for the purpose of preventing, investigating, detecting, and prosecution of legal responsibility and liablility in both civil and criminal law.

You hereby confirm that our storage medium and method which contains your data is within reason. Personal data is retained for a period up to 7 years and is stored in a database format accessible directly only by the administrators. You hereby confirm that site backups may be kept for up to a 6 month period and done on a daily basis. The stored information is only for backup and recovery purposes, this data is not sold or shared and is kept secure according to internet standards.

You consent that administrators have access to the IP address you use to register, sign in, perform actions, or request information with. You consent that administrators have access to any IP address you've used to interact or come in contact with the site. You agree that the exposure of your IP address to administrators is necessary for site function.

You hereby agree that your account may only be requested to be deleted if no publicly available messages have been created by you.

You hereby agree that if you are not a EU citizen or resident that you do not fall under the GDPR (General Data Protection Regulation) law. Citizens and residents of the EU may be required to prove your citizenship or residency to provide you the legal protections and rights of that law.

You hereby agree that for our service to function correctly your IP address and browser information may be shared with intermediaries such as Cloudflare.

You hereby acknowledge and allow that the only cookies stored on your device are those pertaining to session management & preference storage on nyzo.org (so-called functional cookies).

Your participation on the platform should only be done from an anonymous and privacy perspective. We agree to not share or sell your data with thirdparties. This webpage informs all visitors of current policies and is kept current. Only those who consent and agree to the aforementioned may sign in and perform meaningful actions on the platform.`}
                />
                <Checkbox
                    label="I have read and accept the terms of the privacy policy."
                    className="mb-2 mt-1"
                    checked={checked}
                    onChange={(e) => setChecked(e.currentTarget.checked)}
                />
                <hr/>
                {/* <p>A nyzo.org session token (stored as a cookie) can be obtained <b>once</b> per unique signed session message.<br/><b>Do not share the signed session message yourself if it has not been accepted & publicly shared on nyzo.org.<br/>Rejected signed session messages are not recorded by the platform.</b></p> */}
                <Center>
                    <Button 
                        leftIcon={<Bolt size="1rem"/>} 
                        style={{backgroundColor: (checked && publicIdentifier && signedMessage) ? "rgba(245, 159, 0, 0.7)" : ""}} 
                        disabled={(!checked || !publicIdentifier || !signedMessage)}
                        onClick={() => {
                            authContext.fn.SignIn(publicIdentifier, signedMessage);
                            context.closeModal(id);
                        }}
                    >
                        Sign in
                    </Button>
                </Center>
            </div>
        }
        </>
    )
})