import { Badge, Divider } from "@mantine/core";
import React, { useContext } from "react";
import { AppContext } from "../Common/Wrappers/AppWrapper";

const StyledChatEvent = ({label, createdOn, message, isFirstItemInFeed}) => {
    const dt = new Date(createdOn);
    const substriso = `${dt.toLocaleDateString(undefined, {day: "numeric", month:"2-digit"}).split("/").reverse().join("/")} ${dt.getHours().toString().padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt.getSeconds().toString().padStart(2, "0")}`;
    
    // const isostring = dt.toISOString();
    // const substriso = isostring.substring(0, isostring.length - 5).replace("T", " ") + " UTC";
    const { isMobile } = useContext(AppContext);

    return (
        <div 
            title={createdOn}
            style={{
                display: "flex",
                width: "99%",
                marginLeft: "0.5%",
                flexDirection: "row"
            }}
        >
            <div 
                style={{
                    flex: isMobile ? "40" : "20",
                    grow: true,
                    color: "#99c6ff",
                    fontSize: "0.75em"
                }}
            >
                <span style={{ color: "grey", marginRight:"0.5em", width: "6em", display:"inline-block"}}>{substriso}</span> {`${label}: `}
            </div>
            <div
                style={{
                    flex: isMobile ? "60" : "80",
                    grow: true,
                    color: "rgba(215, 217, 216, 0.87)",
                    fontSize: "0.75em",
                    wordBreak: "break-word"
                    // lineHeight: 1,
                    // height: "1.2em",
                }}
            >
                {message}
            </div>
        </div>
    );
}

export const ChatEvent = React.memo(function ChatEvent({id, event}) {
    const { type } = event;

    if(!event.persistent){
        return (<></>);
    }

    switch(type){
        case "SystemEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={"System"}
                    createdOn={event.createdOn}
                    message={event.message}
                />
            )
        case "CycleBlockProducedEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Mesh (${event.observerMajorVersion}.${event.observerMinorVersion})`}
                    createdOn={event.createdOn}
                    message={`Block ${event.frozenEdgeHeight} with ${event.amountOfTransactions} transactions was produced by ${event.verifierPublicIdentifierHexString}`}
                />
            )
        case "CycleFrozenEdgeFallbackEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Mesh (${event.observerMajorVersion}.${event.observerMinorVersion})`}
                    createdOn={event.createdOn}
                    message={`Fallback event - a block was produced and the frozen edge height increased to ${event.frozenEdgeHeight}`}
                />
            )
        case "CycleVerifierDroppedEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Mesh (${event.observerMajorVersion}.${event.observerMinorVersion})`}
                    createdOn={event.createdOn}
                    message={event.blockHeight ? `${event.nickname} (${event.publicIdentifierHexString}) left the cycle at block height ${event.blockHeight}` : `Fallback event - ${event.nickname} (${event.publicIdentifierHexString}) left the cycle`}
                />
            )
        case "CycleVerifierJoinedEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Mesh (${event.observerMajorVersion}.${event.observerMinorVersion})`}
                    createdOn={event.createdOn}
                    message={event.blockHeight ? `${event.nickname} (${event.publicIdentifierHexString}) joined the cycle at block height ${event.blockHeight}` : `Fallback event - ${event.nickname} (${event.publicIdentifierHexString}) joined the cycle`}
                />
            )
        case "VerifierChangedNicknameEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Verifier`}
                    createdOn={event.createdOn}
                    message={`New nickname (${event.nickname}) has been set for verifier ${event.publicIdentifierHexString}`}
                />
            )
        case "VerifierLostNodeEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Verifier`}
                    createdOn={event.createdOn}
                    message={`Node (${event.ipAddress}) communications were lost for verifier ${event.publicIdentifierHexString}`}
                />
            )
        case "VerifierNewNodeEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Verifier`}
                    createdOn={event.createdOn}
                    message={`Node (${event.ipAddress}) communications were (re)established for verifier ${event.publicIdentifierHexString}`}
                />
            )
        case "MessageDeletedEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Moderation`}
                    createdOn={event.createdOn}
                    message={`Message #${event.messageId} by ${event.authorPublicIdentifierHexString} was deleted with reason: ${event.reason?.length ? event.reason : "(none)"}`}
                />
            )
        case "MessageSpoilerHiddenEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Moderation`}
                    createdOn={event.createdOn}
                    message={`Message #${event.messageId} by ${event.authorPublicIdentifierHexString} was spoiler hidden by ${event.byAuthor ? "the author" : "a chat moderator"} with reason: ${event.reason?.length ? event.reason : "(none)"}`}
                />
            )
        case "UserBannedEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Moderation`}
                    createdOn={event.createdOn}
                    message={`Verifier ${event.nickname} (${event.publicIdentifierHexString}) has been banned from chat until ${event.until} UTC with reason: ${event.reason?.length ? event.reason : "(none)"}`}
                />
            )
        case "UserTimedOutEvent":
            return (
                <StyledChatEvent
                    key={id}
                    label={`Moderation`}
                    createdOn={event.createdOn}
                    message={`Verifier ${event.nickname} (${event.publicIdentifierHexString}) has been timed out from chat until ${event.until} UTC with reason: ${event.reason?.length ? event.reason : "(none)"}`}
                />
            )
        default:
            return (<></>);
    }
})