import Navbar from "./Common/Navbar";
import { Route, Routes } from "react-router";
import { RouterPaths } from "./Static/RouterPaths";
import Discuss from "./Pages/Discuss";
import YouTube, {YouTubeProps} from "react-youtube";
import { ActionIcon, Grid, LoadingOverlay, Stack, Title, createStyles, keyframes } from "@mantine/core";
import { Box, Card, Center, Indicator, NavLink, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import axios from "axios";
import { Logger } from "./Common/Shared/Logger";
import { IconPlayerEject, IconPlayerEjectFilled, IconPlayerPause, IconPlayerPlay, IconPlayerTrackNext, IconPlayerTrackPrev } from "@tabler/icons-react";
import * as ReactDOM from 'react-dom';

import { Remarkable } from 'remarkable';
import { RichTextEditor, Link } from "@mantine/tiptap";
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { useEditor } from "@tiptap/react";
import MeshBeta from "./Pages/Mesh";
import Tech from "./Pages/Tech";

// function MarkdownTest(){
//     const [mdRulesApplied, setMdRulesApplied] = useState(false);

//     const md = new Remarkable("full", {
//         html: false,
//         xhtmlOut: false, // <br/> instead of <br>
//         breaks: true, // \n => <br/>
//         linkify: true, // link detect and render as <a>
//         linkTarget: "_blank",
//         // typographer: Boolean,
//         // quotes: String,
//         // highlight: (String, String)

//     });

//     const [data, setData] = useState("# test");

//     const applyData = (res) => {
//         // console.log("res",res);
//         setData(res.data);
//     }

//     const fetchData = () => {
//         axios.get(`https://gist.githubusercontent.com/benjaminvanrenterghem/f80cdb3564179cc62ca1a4d51ecb1e87/raw/0187b083b29cba478045053ae65522fe28dd44be/test.md`).then(applyData).catch((e) => {
//             console.log("err",e);
//         })
//     }

//     useEffect(() => {
//         // todo

//         // md.core.ruler.disable([
//         //     // "htmlblock",
//         //     // "table",
//         // ]);

//         fetchData();

//         setMdRulesApplied(true);
//     }, []);

//     return (
//         <div style={{
//             // backgroundColor: "gray"
//         }}>
//             {
//                 console.log("md", md)
//             }
//             {
//                 console.log("render", md.render(data))
//             }
//             <div dangerouslySetInnerHTML={{__html: md.render(data)}} id="repl-md">

//             </div>
//         </div>
//     )
// }

// const inputContent = "";

// function RTETest() {
//     // const [inputContent, setInputContent] = useState(""); // todo localstorage backup 

//     const editor = useEditor({
//         extensions: [
//             StarterKit,
//             Underline,
//             Link,
//             Superscript,
//             SubScript,
//             Highlight,
//             TextAlign.configure({ types: ['heading', 'paragraph'] }),
//         ],
//         inputContent,
//       });
    
//       return (
//         <RichTextEditor 
//             id="discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte"
//             editor={editor}
//             className={classes.rte}
//         >
//             <RichTextEditor.Toolbar
//                 id="discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte-toolbar"
//                 className={classes.toolbar}
//                 sticky={true} 
//                 stickyOffset={60}
//             >
//                 <RichTextEditor.ControlsGroup>
//                     <RichTextEditor.Bold />
//                     <RichTextEditor.Italic />
//                     <RichTextEditor.Underline />
//                     <RichTextEditor.Strikethrough />
//                     <RichTextEditor.ClearFormatting />
//                     <RichTextEditor.Highlight />
//                     <RichTextEditor.Code />
//                 </RichTextEditor.ControlsGroup>

//                 <RichTextEditor.ControlsGroup>
//                     <RichTextEditor.H1 />
//                     <RichTextEditor.H2 />
//                     <RichTextEditor.H3 />
//                     <RichTextEditor.H4 />
//                 </RichTextEditor.ControlsGroup>

//                 <RichTextEditor.ControlsGroup>
//                     <RichTextEditor.Blockquote />
//                     <RichTextEditor.Hr />
//                     <RichTextEditor.BulletList />
//                     <RichTextEditor.OrderedList />
//                     <RichTextEditor.Subscript />
//                     <RichTextEditor.Superscript />
//                 </RichTextEditor.ControlsGroup>

//                 <RichTextEditor.ControlsGroup>
//                     <RichTextEditor.Link />
//                     <RichTextEditor.Unlink />
//                 </RichTextEditor.ControlsGroup>

//                 <RichTextEditor.ControlsGroup>
//                     <RichTextEditor.AlignLeft />
//                     <RichTextEditor.AlignCenter />
//                     <RichTextEditor.AlignJustify />
//                     <RichTextEditor.AlignRight />
//                 </RichTextEditor.ControlsGroup>
//             </RichTextEditor.Toolbar>
//             <RichTextEditor.Content
//                 id="discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte-content"
//                 className={classes.content}
//             />
//         </RichTextEditor>
//       );

//     return (
//         <DiscussChatDesktopMiddleConsoleRTE
//                                         content={inputContent}
//                                         toolbarControlsGroups={
//                                             [
//                                                 <RichTextEditor.ControlsGroup>
//                                                     <RichTextEditor.Bold />
//                                                     <RichTextEditor.Italic />
//                                                     <RichTextEditor.Underline />
//                                                     <RichTextEditor.Strikethrough />
//                                                     <RichTextEditor.ClearFormatting />
//                                                     <RichTextEditor.Highlight />
//                                                     <RichTextEditor.Code />
//                                                 </RichTextEditor.ControlsGroup>,
                                      
//                                                 <RichTextEditor.ControlsGroup>
//                                                     <RichTextEditor.H1 />
//                                                     <RichTextEditor.H2 />
//                                                     <RichTextEditor.H3 />
//                                                     <RichTextEditor.H4 />
//                                                 </RichTextEditor.ControlsGroup>,
                                        
//                                                 <RichTextEditor.ControlsGroup>
//                                                     <RichTextEditor.Blockquote />
//                                                     <RichTextEditor.Hr />
//                                                     <RichTextEditor.BulletList />
//                                                     <RichTextEditor.OrderedList />
//                                                     <RichTextEditor.Subscript />
//                                                     <RichTextEditor.Superscript />
//                                                 </RichTextEditor.ControlsGroup>,
                                        
//                                                 <RichTextEditor.ControlsGroup>
//                                                     <RichTextEditor.Link />
//                                                     <RichTextEditor.Unlink />
//                                                 </RichTextEditor.ControlsGroup>,
                                        
//                                                 <RichTextEditor.ControlsGroup>
//                                                     <RichTextEditor.AlignLeft />
//                                                     <RichTextEditor.AlignCenter />
//                                                     <RichTextEditor.AlignJustify />
//                                                     <RichTextEditor.AlignRight />
//                                                 </RichTextEditor.ControlsGroup>,
//                                             ]
//                                         }
//                                     />
//     )
// }

// Specific purpose: Common page layout composition and routing

function App() {
    return (
        <div id="app-root">
            <Navbar />
            <Routes>
                <Route path="*" element={<MeshBeta />} />

                <Route path={RouterPaths.User.MESH} element={<MeshBeta />} />
                <Route path={RouterPaths.User.DISCUSS} element={<Discuss />} />

                {/* <Route path={"/test"} element={<Tech/>}/> */}

                
                {/* <Route path={RouterPaths.User.TEST1} element={<RTETest/>}/> */}

                {/* <Route path={UserPaths.AUTH} element={<Auth/>}/> */}

                {/* todo remove: */}
                {/* <Route path="/test" element={<Test/>}/> */}
            </Routes>
        </div>
    );
}

export default App;
