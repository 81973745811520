import { Button, Card, Checkbox, Group, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import React, { useState } from "react";

export const ChatMessageModerationOptionsModal = React.memo(function({id, context, innerProps}) {
    const { authContext, chatContext, messageId, authorPublicIdentifierHexString } = innerProps;

    const [spoilerHideChecked, setSpoilerHideChecked] = useState(false);

    const [reportReason, setReportReason] = useState("");

    const [banReason, setBanReason] = useState("");
    const [banUntil, setBanUntil] = useState(null);

    const [timeoutReason, setTimeoutReason] = useState("");
    const [timeoutUntil, setTimeoutUntil] = useState(null);

    const [purgeContent, setPurgeContent] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    return (
        <>
        {
            !authContext.Session.loggedIn &&
            <div>
                <span>You are not signed in, please sign in again. If you experience any issues performing actions on the platform, please refresh your browser window.</span>
            </div>
        }
        {
            authContext.Session.loggedIn &&
            <div>
                {
                    authContext.Session.isAdmin &&
                    <div style={{minHeight: "50vh"}}>
                        {
                            // ban
                            <div>
                                 <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div style={{width: "80%", maxWidth:"70%"}}>
                                            <TextInput
                                                placeholder="Reason*"
                                                rightSection={
                                                    <span style={{fontSize: "0.6em"}}>{`${banReason.length}/1000`}</span>
                                                }
                                                value={banReason}
                                                onChange={(e) => setBanReason(e.currentTarget.value)}
                                                error={(banReason.length > 1000 ? "Max 1000 chars allowed" : null)}
                                            />
                                            <DatePickerInput
                                                rightSection={<IconCalendar size="1.1rem" stroke={1.5}/>}
                                                placeholder="Until*"
                                                value={banUntil}
                                                onChange={setBanUntil}
                                                dropdownType="popover"
                                                styles={{
                                                    calendar: {
                                                        position: "fixed",
                                                        backgroundColor: "#131313",
                                                        padding: "1em",
                                                        borderRadius: "1em"
                                                    }
                                                }}

                                                className="mt-1"
                                            />
                                        </div>
                                        <div style={{width: "20%", maxWidth:"25%", float:"right"}}>
                                            <Button
                                                disabled={banReason.length > 1000 || banUntil == null || banReason.length < 1}
                                                style={{
                                                    float: "right"
                                                }}
                                                color="red"
                                                w={100}

                                                onClick={() => {
                                                    chatContext.fn.admin.banMember(authorPublicIdentifierHexString, banReason, banUntil.toISOString());
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Ban
                                            </Button>
                                        </div>
                                    </Group>

                                </Card>
                            </div>
                        }
                        {
                            // timeout
                            <div>
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    className="mt-2"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div style={{width: "80%", maxWidth:"70%"}}>
                                            <TextInput
                                                placeholder="Reason*"
                                                rightSection={
                                                    <span style={{fontSize: "0.6em"}}>{`${timeoutReason.length}/1000`}</span>
                                                }
                                                value={timeoutReason}
                                                onChange={(e) => setTimeoutReason(e.currentTarget.value)}
                                                error={(timeoutReason.length > 1000 ? "Max 1000 chars allowed" : null)}
                                            />
                                            <DatePickerInput
                                                rightSection={<IconCalendar size="1.1rem" stroke={1.5}/>}
                                                placeholder="Until*"
                                                value={timeoutUntil}
                                                onChange={setTimeoutUntil}
                                                dropdownType="popover"
                                                styles={{
                                                    calendar: {
                                                        position: "fixed",
                                                        backgroundColor: "#131313",
                                                        padding: "1em",
                                                        borderRadius: "1em"
                                                    }
                                                }}

                                                className="mt-1"
                                            />
                                        </div>
                                        <div style={{width: "20%", maxWidth:"25%", float:"right"}}>
                                            <Button
                                                disabled={timeoutReason.length > 1000 || timeoutUntil == null || timeoutReason.length < 1}
                                                style={{
                                                    float: "right"
                                                }}
                                                color="yellow"
                                                w={100}

                                                onClick={() => {
                                                    chatContext.fn.admin.timeoutMember(authorPublicIdentifierHexString, timeoutReason, timeoutUntil.toISOString());
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Time-out
                                            </Button>
                                        </div>
                                    </Group>

                                </Card>
                            </div>
                        }
                        {
                            // delete
                            <div>
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    className="mt-2"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div style={{width: "80%", maxWidth:"70%"}}>
                                            <TextInput
                                                placeholder="Reason*"
                                                rightSection={
                                                    <span style={{fontSize: "0.6em"}}>{`${deleteReason.length}/1000`}</span>
                                                }
                                                value={deleteReason}
                                                onChange={(e) => setDeleteReason(e.currentTarget.value)}
                                                error={(deleteReason.length > 1000 ? "Max 1000 chars allowed" : null)}
                                                className="mb-2"
                                            />
                                            <Checkbox
                                                label="This content is harmful to store, permanently delete it"
                                                className="mt-1 mb-1"
                                                checked={purgeContent}
                                                onChange={(e) => setPurgeContent(e.currentTarget.checked)}
                                            />
                                        </div>
                                        <div style={{width: "20%", maxWidth:"25%", float:"right"}}>
                                            <Button
                                                disabled={deleteReason.length > 1000 || deleteReason.length < 1}
                                                style={{
                                                    float: "right"
                                                }}
                                                color="red"
                                                w={100}
                                                onClick={() => {
                                                    chatContext.fn.admin.deleteMessage(messageId, deleteReason, purgeContent);
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    </Group>

                                </Card>
                            </div>
                        }
                        {
                            // spoiler hide
                            <div>
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                    className="mt-2"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div style={{width: "80%", maxWidth:"70%"}}>
                                            <span>Spoiler hide</span><br className="mb-1"/>
                                            <Checkbox
                                                label="I confirm this can not be undone"
                                                checked={spoilerHideChecked}
                                                onChange={(e) => setSpoilerHideChecked(e.currentTarget.checked)}
                                            />
                                        </div>
                                        <div style={{width: "20%", maxWidth:"25%", float:"right"}}>
                                            <Button
                                                disabled={!spoilerHideChecked}
                                                style={{
                                                    float: "right"
                                                }}
                                                w={100}
                                                onClick={() => {
                                                    chatContext.fn.spoilerHideMessage(messageId, null);
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Spoiler hide
                                            </Button>
                                        </div>
                                    </Group>
                                </Card>
                            </div> 
                        }
                    </div>
                }
                {
                    !authContext.Session.isAdmin &&
                    <div>
                        {
                            // spoiler hide
                            (authContext.Session.publicIdentifierHexString == authorPublicIdentifierHexString) && 
                            <div>
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div>
                                            <span>Spoiler hide</span><br className="mb-1"/>
                                            <Checkbox
                                                label="I confirm this can not be undone"
                                                checked={spoilerHideChecked}
                                                onChange={(e) => setSpoilerHideChecked(e.currentTarget.checked)}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                disabled={!spoilerHideChecked}
                                                style={{
                                                    float: "right"
                                                }}
                                                onClick={() => {
                                                    chatContext.fn.spoilerHideMessage(messageId, null);
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Spoiler hide
                                            </Button>
                                        </div>
                                    </Group>
                                </Card>
                            </div> 
                        }
                        {
                            // report
                            (authContext.Session.publicIdentifierHexString != authorPublicIdentifierHexString) &&
                            <div>
                                <Card
                                    shadow="sm"
                                    padding="lg"
                                    radius="md"
                                >
                                    <Group
                                        grow={true}
                                    >
                                        <div style={{width: "80%", maxWidth:"70%"}}>
                                            <span>Report</span><br className="mb-1"/>
                                            <TextInput
                                                placeholder="Reason"
                                                rightSection={
                                                    <span style={{fontSize: "0.6em"}}>{`${reportReason.length}/1000`}</span>
                                                }
                                                value={reportReason}
                                                onChange={(e) => setReportReason(e.currentTarget.value)}
                                                error={(reportReason.length > 1000 ? "Max 1000 chars allowed" : null)}
                                            />
                                        </div>
                                        <div style={{width: "20%", maxWidth:"25%", float:"right"}}>
                                            <Button
                                                disabled={reportReason.length > 1000}
                                                style={{
                                                    float: "right"
                                                }}
                                                onClick={() => {
                                                    chatContext.fn.reportMessage(messageId, reportReason);
                                                    context.closeModal(id);
                                                }}
                                            >
                                                Report
                                            </Button>
                                        </div>
                                    </Group>

                                </Card>
                            </div>
                        }
                    </div>
                }
            </div>
        }
        </>
    );
})



