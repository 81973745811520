import { Card, Grid, ScrollArea, Tabs, createStyles } from "@mantine/core";
import { Content, DynamicHeightSegment, Segment } from "../Common/Content";
import { IconMessageCircle, IconPhoto, IconSettings } from "@tabler/icons-react";
import { ArrowBearLeft2, ArrowBounce, StatusChange } from "tabler-icons-react";
import { useContext, useEffect } from "react";
import { AppContext } from "../Common/Wrappers/AppWrapper";


const useMeshSegmentStyles = createStyles((theme) => ({
    cardTopLevel: {
        height: "100%",
        maxHeight: "100%",
        paddingRight: "0px!important",
        paddingLeft: "0px!important",
        paddingTop: "0rem!important", // > 1rem default
        paddingBottom: "0rem!important", // > "
        // borderTop: "0.1px solid white",
        // borderLeft: "0.1px"
        // border: "0.1px solid white",
        WebkitBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        MozBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        boxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);"
    },

    affixTopLevel: {
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        paddingTop: "0px!important",
        paddingBottom: "0px!important",
        paddingLeft: "calc(1rem/4)",
        paddingRight: "calc(1rem/4)",
    },

    gridTopLevel: {
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0
    },

    column: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    iframe: {
        width: "100%",
        height: "100%",
    },

    tabsroot: {
        width: "100%",
        height: "100%",
    },
        tabslist: {
            width: "100%",
            // height: "100%",
        },
        tabspanel: {
            width: "100%",
            height: "100%",
            paddingTop: "0px!important",
        },

}));


function TabContent({subdomain, index}){
    useEffect(() => {
        const intervalId = setInterval(() => {
            fetch(`https://proxy.nyzo.org/${subdomain}.html?ts=`+Date.now()).then(response => {return response.text()}).then((response)=>{
                if(response.includes("Frozen edge")){
                    response = response.replace("<script>", `<script id="iframeContentDivScript${index}">`);

                    let cycleLength = response.split("&rarr;").length;

                    response = response.replace("<h3>(", `<h5>Cycle length: ${cycleLength}</h5><i>A: upgraded before soft-fork<br/>B: upgraded after soft-fork</i><br/><br/><h3>(`)

                    let target = document.getElementById("iframeContentDiv"+index);
                    target.innerHTML = response;

                    // this should happen before the .innerHTML, yet the possible delay is no problem in this case
                    document.getElementById("iframeContentDivScript"+index)?.remove();
                }    
            }).catch((e)=>{console.log(e)})
        }, 1000);

        /*~*/ return () => clearInterval(intervalId);
    }, []);

    return (
        <ScrollArea.Autosize
            type="auto"
            scrollbarSize={4}
            style={{
                maxHeight: "100%"
            }}
        >
            <div>
                <div id={"iframeContentDiv"+index} style={{
                    textAlign: "center",
                    padding: "1em",
                    paddingBottom: "4em"
                }}>
                    Loading..
                </div>
            </div>
        </ScrollArea.Autosize>
    );
}

function MeshSegment(){
    const { classes } = useMeshSegmentStyles();
    const { isMobile } = useContext(AppContext);

    return (
        <Segment
            id="mesh-segment-wrapper"
            classes={isMobile ? " " : null}
        >
            <DynamicHeightSegment
                preludeElements={["navbar"]}
                id="mesh-segment"
                classes={isMobile ? " " : "mt-3 mb-3"}
                style={{
                    maxWidth: "100%",
                    width: "100%"
                }}
            >
                <Card
                    id="mesh-segment-main-wrapper"
                    className={classes.cardTopLevel}
                >
                    <Grid
                        columns={12}
                        id="mesh-segment-main"
                        grow={true} // < width maximizer
                        className={`${classes.affixTopLevel} ${classes.gridTopLevel}`} // < height maximizer
                    >
                        <Grid.Col
                            id="mesh-segment-main-0"
                            className={`${classes.affixTopLevel} ${classes.column}`}
                            span={12}
                        >
                            <Tabs 
                                defaultValue="v643_2_0"
                                className={classes.tabsroot}
                            >
                                <Tabs.List
                                    className={classes.tabslist}
                                >
                                    <Tabs.Tab 
                                        value="v643_2_0" 
                                        icon={<ArrowBounce size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v643.2
                                    </Tabs.Tab>
                                    <Tabs.Tab 
                                        value="v644_x_0" 
                                        icon={<StatusChange size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v644 (A)
                                    </Tabs.Tab>
                                    <Tabs.Tab 
                                        value="v644_x_1" 
                                        icon={<StatusChange size="0.8rem" />}
                                        style={{
                                            width:"33.33%"
                                        }}
                                    >
                                        v644 (B)
                                    </Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel 
                                    id="v643_2_0-iframe-wrapper"
                                    value="v643_2_0" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier2"}
                                        index={0}
                                    />
                                </Tabs.Panel>

                                <Tabs.Panel 
                                    id="v644_x_0-iframe-wrapper"
                                    value="v644_x_0" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier0"}
                                        index={1}
                                    />
                                    
                                </Tabs.Panel>

                                <Tabs.Panel 
                                    id="v644_x_1-iframe-wrapper"
                                    value="v644_x_1" 
                                    pt="xs"
                                    className={classes.tabspanel}
                                >
                                    <TabContent
                                        subdomain={"verifier1"}
                                        index={2}
                                    />
                                </Tabs.Panel>
                            </Tabs>
                        </Grid.Col>
                    </Grid>
                </Card>
            </DynamicHeightSegment>
        </Segment>
    )
}

function MeshBeta(){
    const { isMobile } = useContext(AppContext);

    return (
        <Content
            id="mesh-page" 
            style={{
                maxWidth: "96.5rem", 
                width: "100%",
                paddingLeft: isMobile ? 0 : undefined,
                paddingRight: isMobile ? 0 : undefined
            }}
        >
            <MeshSegment/>
        </Content>
    )
}

export default MeshBeta;



