import { Center, Table, Text } from "@mantine/core";
import React from "react";

export const DiscussSessionDetailsModal = React.memo(function({context,id,innerProps}) {
    const {publicIdentifierHexString, publicIdentifierNyzoString, session} = innerProps;

    return (
        // <Modal
        //     opened={viewSessionDetailsModalOpened}
        //     onClose={sessionDetailsModalControl.close}
        //     centered={true}
        //     size={"xl"}
        //     scrollAreaComponent={ScrollArea.Autosize}

        //     title={"Session details"}
        // >
        <>
            <Text weight={300}>
                Below you will find the session used to send this message. <br/>
                You can independently verify the validity and authenticity of the <span style={{color: "#ffd43b"}}>Session message</span> by copying<br/>the <u>Public identifier hex string</u> and <u>Signed message hex string</u> values to <a style={{color: "white"}} href="https://construct0.github.io/nyzo-org/sessionMessages.html" target="_blank">the validator</a>.<br/><br/><u>Valid and active</u> applies solely to the <span style={{color: "#ffd43b"}}>Session message</span> and is overriden by <u>Ended by user</u> before<br/>the message itself expires.<br/><br/>The <u>Public identifier hex string</u> listed in <span style={{color: "#ffd43b"}}>Verifier</span> must match the <u>Public identifier hex string</u> contained<br/>within the <span style={{color: "#ffd43b"}}>Session message</span>.<br/><br/>
                A session may be ended by the user before it expires & no new signed message is required in order to do so.
            </Text>

            <hr/>

            <Center>
                <Text weight={400} color={"#ffd43b"}>
                    Verifier
                </Text>
            </Center>

            <Table
                miw={"100%"}
                horizontalSpacing={"xs"}
                verticalSpacing={"xs"}
            >
                <thead><tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr></thead>

                <tbody>
                    <tr key="session-verifier-0">
                        <td style={{width:"30%"}}><u>Public identifier hex string</u></td>
                        <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{publicIdentifierHexString}</td>
                    </tr>
                    <tr key="session-verifier-1">
                        <td>Public identifier nyzo string</td>
                        <td>{publicIdentifierNyzoString}</td>
                    </tr>
                </tbody>
            </Table>

            <hr/>

            <Center>
                <Text weight={400} color={"#ffd43b"}>
                    Session
                </Text>
            </Center>

            <Table
                miw={"100%"}
                horizontalSpacing={"xs"}
                verticalSpacing={"xs"}
            >
                <thead><tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr></thead>

                <tbody>
                    <tr key="session-session-0">
                        <td style={{width: "30%"}}><u>Signed message hex string</u></td>
                        <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{session.signedMessageHexString}</td>
                    </tr>
                    <tr key="session-session-1">
                        <td>Ended by user</td>
                        <td>{session.endedByUser.toString()}</td>
                    </tr>
                    <tr key="session-session-2">
                        <td>Ended by user on</td>
                        <td>{session.endedByUserOn ?? "N/A"}</td>
                    </tr>
                    <tr key="session-session-3">
                        <td>Expires by</td>
                        <td>{session.expiresBy}</td>
                    </tr>
                </tbody>
            </Table>

            <hr/>

            <Center>
                <Text weight={400} color={"#ffd43b"}>
                    Session status
                </Text>
            </Center>

            <Table
                miw={"100%"}
                horizontalSpacing={"xs"}
                verticalSpacing={"xs"}
            >
                <thead><tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr></thead>

                <tbody>
                    <tr key="session-status-0">
                        <td style={{width: "30%"}}>Earliest validity</td>
                        <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{session.status.earliestValidity}</td>
                    </tr>
                    <tr key="session-status-0-0">
                        <td style={{width: "30%"}}>Latest validity</td>
                        <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{session.status.latestValidity}</td>
                    </tr>
                    <tr key="session-status-1">
                        <td>T0</td>
                        <td>{session.status.t0}</td>
                    </tr>
                    <tr key="session-status-2">
                        <td>T1</td>
                        <td>{session.status.t1}</td>
                    </tr>
                    <tr key="session-status-3">
                        <td>Valid and active</td>
                        <td>{session.status.validAndActive.toString()}</td>
                    </tr>
                </tbody>
            </Table>

            <hr/>

            <Center>
                <Text weight={400} color={"#ffd43b"}>
                    Session message
                </Text>
            </Center>
            <Table
                miw={"100%"}
                horizontalSpacing={"xs"}
                verticalSpacing={"xs"}
            >
                <thead><tr>
                    <th>Name</th>
                    <th>Value</th>
                </tr></thead>

                <tbody>
                    <tr key="session-message-0">
                        <td style={{width:"30%"}}>Content</td>
                        <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{session.status.message.content}</td>
                    </tr>

                    <tr key="session-message-1">
                        <td>Domain</td>
                        <td>{session.status.message.domain}</td>
                    </tr>
                    <tr key="session-message-2">
                        <td>Public identifier hex string</td>
                        <td>{session.status.message.publicIdentifierHexString}</td>
                    </tr>
                    <tr key="session-message-3">
                        <td>From timestamp seconds</td>
                        <td>{session.status.message.fromTimestampSeconds}</td>
                    </tr>
                    <tr key="session-message-4">
                        <td>Until timestamp seconds</td>
                        <td>{session.status.message.untilTimestampSeconds}</td>
                    </tr>
                    <tr key="session-message-5">
                        <td>Current timestamp seconds</td>
                        <td>{session.status.message.currentTimestampSeconds}</td>
                    </tr>
                    <tr key="session-message-6">
                        <td>Is expired</td>
                        <td>{session.status.message.isExpired.toString()}</td>
                    </tr>
                </tbody>
            </Table>
        {/* </Modal> */}
        </>
    );
})

