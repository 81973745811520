import { Badge, Group, Stack, Text, Tooltip } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { AlertTriangle, Ban, Clock, Network, NetworkOff, Planet, Spiral, SpiralOff } from "tabler-icons-react";

export const DiscussMemberEntryModal = React.memo(function({context, id, innerProps})  {
    const { member } = innerProps;
    const showBadges = useMediaQuery("(min-width: 527px)");

    return (
        <>
        <Stack spacing={"xs"} style={{margin: "0.5em"}}>
            <Text size="md" align="center">Nickname</Text>
            <Group
                position="center"
                grow={false}
            >
                <Badge style={{textTransform: "initial"}} size="lg" color="lime">{member.nickname}</Badge>
            </Group>
            
            <br/>
            <Text size="md" align="center">Legacy nyzo public identifier</Text>
            {
                showBadges
                ?
                <Badge style={{textTransform: "initial"}} size="lg" color="lime">{member.publicIdentifierHexString}</Badge>
                :
                <span style={{wordWrap: "anywhere", color: "#88c253"}}>{member.publicIdentifierHexString}</span>
            }
            
            <br/>
            <Text size="md" align="center">Nyzo string formatted public identifier</Text>
            {
                showBadges
                ?
                <Badge style={{textTransform: "initial"}} size="lg" color="lime">{member.publicIdentifierNyzoString}</Badge>
                :
                <span style={{wordWrap: "anywhere", color: "#88c253"}}>{member.publicIdentifierNyzoString}</span>
            }
            

            <Group
                position="center"
                spacing={"xs"}
                grow={false}
                style={{
                    height: "inherit",
                    marginLeft: "0em"
                }}
            >
                {
                    member.isChatAdmin && 
                    <Text size={"sm"} align="right">
                        <Tooltip label={"Chat moderator"}>
                            <div>
                            <Planet size="1.1em" color="white"/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    !member.nicknameIsUnique && 
                    <Text size={"sm"} align="right">
                        <Tooltip label={"Other verifier(s) use the same nickname"}>
                            <div>
                            <AlertTriangle size="1.1em" color={"#ff2825"}/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    member.hasMultipleNicknames && 
                    <Text size={"sm"} align="right">
                        <Tooltip label={"Verifier has multiple nicknames"}>
                            <div>
                            <AlertTriangle size="1.1em" color={"#ffec00"}/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    member.isTimedOutFromChat && 
                    <Text size={"sm"} align="right">
                        <Tooltip label={"Verifier is timed out from chat"}>
                            <div>
                            <Clock size="1.1em" color={"#ffec00"}/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    member.isBannedFromChat &&
                    <Text size={"sm"} align="right">
                        <Tooltip label="Verifier is banned from chat">
                            <div>
                            <Ban size="1.1em" color={"#ff2825"}/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    member.isInCycle && 
                    <Text size={"sm"} align="right">
                        <Tooltip label="Verifier is in cycle">
                            <div>
                            <Spiral size="1.1em" color="#7bc62d"/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    !member.isInCycle &&
                    <Text size={"sm"} align="right">
                        <Tooltip label="Verifier is out of cycle">
                            <div>
                            <SpiralOff size="1.1em"/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    member.hasOneOrMoreNodes &&
                    <Text size={"sm"} align="right">
                        <Tooltip label="Verifier has atleast one active node">
                            <div>
                            <Network size="1.1em" color="#7bc62d"/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                {
                    !member.hasOneOrMoreNodes && 
                    <Text size={"sm"} align="right">
                        <Tooltip label="Verifier has no active node(s)">
                            <div>
                            <NetworkOff size="1.1em"/>
                            </div>
                        </Tooltip>
                    </Text>
                }
                
                
            </Group>
        </Stack>
        </>
    );
})