import { useMediaQuery } from "@mantine/hooks";
import { createContext, useState } from "react";

export const AppContext = createContext();
 
// Specific purpose: common hook collective 
export const AppWrapper = ({children}) => {
    const isMobile = useMediaQuery("(max-width: 575px)");
    const isTablet = useMediaQuery("(max-width: 767px)");
    const mainMenuIsHidden = useMediaQuery("(max-width: 898px)");
    const logo = require("../../Assets/logo.png");

    return (
        <AppContext.Provider value={{
            isMobile: isMobile,
            isTablet: isTablet,
            mainMenuIsHidden: mainMenuIsHidden,
            logo: logo
        }}>
            {children}
        </AppContext.Provider>
    )
}