import { Center, Table, Text } from "@mantine/core";
import React from "react";

export const DiscussUserDetailsModal = React.memo(function({context, id, innerProps}) {

    const { 
        publicIdentifierHexString,
        publicIdentifierShortHexString,
        publicIdentifierNyzoString,

        nickname,
        nicknameIsUnique,
        hasMultipleNicknames,
        chatCooldownFrom,
        chatCooldownUntil,
        isTimedoutFromChat,
        isBannedFromChat,
        isInCycle,
        hasOneOrMoreNodes,
        isChatAdmin,
    } = innerProps;

    return (
        <>
            {/* <Modal
                opened={viewUserDetailsOpened}
                onClose={userDetailsModalControl.close}
                centered={true}
                size={"xl"}
                scrollAreaComponent={ScrollArea.Autosize}

                title={"Verifier user details"}
            > */}
                <Text weight={300}>
                    Below you will find the verifier (user) details of the message author.<br/><br/>These values are used to display the icons and identifiables attached to this message, they are not a representation of the verifier at the time of the message itself but a current representation.
                </Text>

                <hr/>

                <Center>
                    <Text weight={400} color={"#ffd43b"}>
                        Verifier
                    </Text>
                </Center>

                <Table
                    miw={"100%"}
                    horizontalSpacing={"xs"}
                    verticalSpacing={"xs"}
                >
                    <thead><tr>
                        <th>Name</th>
                        <th>Value</th>
                    </tr></thead>

                    <tbody>
                        <tr key="verifier-verifier-0">
                            <td style={{width:"30%"}}>Public identifier hex string</td>
                            <td style={{wordBreak:"break-all", maxWidth:"70%"}}>{publicIdentifierHexString}</td>
                        </tr>
                        <tr key="verifier-verifier-1">
                            <td>Public identifier nyzo string</td>
                            <td>{publicIdentifierNyzoString}</td>
                        </tr>
                        <tr key="verifier-verifier-2">
                            <td>Nickname</td>
                            <td>
                                <pre 
                                    style={{
                                        fontFamily:"retropix",
                                        fontSize:"1em",
                                        fontWeight:"400",
                                        marginBottom: 0,
                                        textAlign: "start",
                                    }}
                                >
                                    {nickname ?? publicIdentifierShortHexString}
                                </pre>
                            </td>
                        </tr>
                        <tr key="verifier-verifier-3">
                            <td>Nickname is unique</td>
                            <td>{nicknameIsUnique.toString()}</td>
                        </tr>
                        <tr key="verifier-verifier-4">
                            <td>Has multiple nicknames</td>
                            <td>{hasMultipleNicknames.toString()}</td>
                        </tr>
                        <tr key="verifier-verifier-5">
                            <td>Chat cooldown from</td>
                            <td>{chatCooldownFrom}</td>
                        </tr>
                        <tr key="verifier-verifier-6">
                            <td>Chat cooldown until</td>
                            <td>{chatCooldownUntil}</td>
                        </tr>
                        <tr key="verifier-verifier-7">
                            <td>Is timed out from chat</td>
                            <td>{isTimedoutFromChat.toString()}</td>
                        </tr>
                        <tr key="verifier-verifier-8">
                            <td>Is banned from chat</td>
                            <td>{isBannedFromChat.toString()}</td>
                        </tr>
                        <tr key="verifier-verifier-9">
                            <td>Is in cycle</td>
                            <td>{isInCycle.toString()}</td>
                        </tr>
                        <tr key="verifier-verifier-10">
                            <td>Has one or more active nodes</td>
                            <td>{hasOneOrMoreNodes.toString()}</td>
                        </tr>
                    </tbody>
                </Table>
            {/* </Modal> */}
        </>
    );
})