// import React, { useContext, useEffect, useState } from "react";

// const { Modal, Center, Text, Card, Grid, Group, Tooltip, Table } = require("@mantine/core");
// const { useDisclosure } = require("@mantine/hooks");
// const { modals } = require("@mantine/modals");
// const { RichTextEditor } = require("@mantine/tiptap");
// const { IconUserScan } = require("@tabler/icons-react");
// const { useEditor } = require("@tiptap/react/dist/index.cjs");
// const { Tornado, AlertTriangle, Clock, Ban, Spiral, SpiralOff, Network, NetworkOff, Badge } = require("tabler-icons-react");


import React, { useContext, useEffect, useState } from "react";
import { ActionIcon, Alert, Badge, Box, Card, Center, Divider, Grid, Group, Indicator, LoadingOverlay, Modal, NavLink, Popover, ScrollArea, Stack, Table, Tabs, Text, Tooltip, createStyles } from "@mantine/core";
import { AlertTriangle, ArrowBackUp, ArrowBearLeft2, ArrowBearRight2, ArrowBounce, ArrowForwardUp, Ban, ChevronsLeft, ChevronsRight, Clock, DotsVertical, Lock, LockAccess, Network, NetworkOff, Planet, RotateClockwise2, Settings, Spiral, SpiralOff, StatusChange, TopologyRing2, Tornado, User } from "tabler-icons-react";
import { IconAlertCircle, IconLockCheck, IconUserCode, IconUserScan } from "@tabler/icons-react";
import { Logger } from "../Common/Shared/Logger";
import { useEditor } from "@tiptap/react";
import { RichTextEditor } from "@mantine/tiptap";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { AuthContext } from "../Common/Wrappers/AuthWrapper";
import { AppContext } from "../Common/Wrappers/AppWrapper";

export const ChatMessage = React.memo(function ChatMessage({message, editorExtensions, isTopMostMessage, addTopMargin, addBottomMargin, isFirstMessage, isFirstItemInFeed, isLastItemInFeed, commonClasses, className, chatManager, authContext}){
    const { 
        id,
        createdOn,
        lastUpdatedOn,

        publicIdentifierHexString,
        publicIdentifierShortHexString,
        publicIdentifierNyzoString,

        parentMessageId,
        isReply,

        content, // encoded

        isSpoilerHidden,
        isSoftDeleted,
        contentAvailabilityChangeReason,

        currentVerifierState,

        session, // < not deconstructed
    } = message;

    // const isSpoilerHidden = true;

    const { 
        nickname,
        nicknameIsUnique,
        hasMultipleNicknames,
        chatCooldownFrom,
        chatCooldownUntil,
        isTimedOutFromChat,
        isBannedFromChat,
        isInCycle,
        hasOneOrMoreNodes,
        isChatAdmin,
    } = currentVerifierState;

    const openSessionDetailsModal = () => {
        modals.openContextModal({
            modal: "DiscussSessionDetailsModal",
            centered: true,
            size: "xl",
            scrollAreaComponent: ScrollArea.Autosize,
            closeOnClickOutside: true,
            closeOnEscape: true,
            trapFocus: false,

            title: "Session details",
            innerProps: {
                publicIdentifierHexString: publicIdentifierHexString,
                publicIdentifierNyzoString: publicIdentifierNyzoString,
                session: session
            }
        });
    }

    const openUserDetailsModal = () => {
        modals.openContextModal({
            modal: "DiscussUserDetailsModal",
            centered: true,
            size: "xl",
            scrollAreaComponent: ScrollArea.Autosize,
            closeOnClickOutside: true,
            closeOnEscape: true,
            trapFocus: false,

            title: "Verifier user details",
            innerProps: {
                publicIdentifierHexString: publicIdentifierHexString,
                publicIdentifierNyzoString: publicIdentifierNyzoString,
                publicIdentifierShortHexString: publicIdentifierShortHexString,
                ...currentVerifierState
            }
        })
    }

    const openModerationOptionsModal = () => {
        modals.openContextModal({
            modal: "ChatMessageModerationOptionsModal",
            centered: true,
            size: "lg",
            scrollAreaComponent: ScrollArea.Autosize,
            closeOnClickOutside: true,
            closeOnEscape: true,
            trapFocus: false,

            title: `Options for message #${id}`,
            innerProps: {
                authContext: authContext,
                chatContext: chatManager,
                messageId: id,
                authorPublicIdentifierHexString: publicIdentifierHexString
            }
        })
    }

    // the message content display "editor"
    const editorView = useEditor({
        extensions: editorExtensions,
        content: "",
        editable: true, // todo
        autofocus: false,
        injectCSS: false
    });

    useEffect(async () => {
        console.log("chatmsg")
        let viewMessageContent;

        while(true){
            viewMessageContent = document.querySelector(`#rte-content-${id} > * .tiptap`);

            if(viewMessageContent){
                break;
            }

            // this shouldn't be reachable
            await new Promise(r => setTimeout(r, 50));
        }

        // userInputContent.style.left = 0;
        viewMessageContent.style.width = "100%";
        viewMessageContent.style.paddingBottom = "0px";
        viewMessageContent.setAttribute("spellcheck", "false");

        // this is changed manually, and editable:true is set when the useEditor hook is called
        // this due to the rendering differences when editable:false
        viewMessageContent.setAttribute("contenteditable", "false");

        if(content?.length > 0){
            viewMessageContent.innerHTML = decodeURIComponent(content);
        } else {
            viewMessageContent.innerHTML = "<p><strong>Failed to load message content.</strong></p>";
        }
    }, [content, editorView]); 
    // --

    const { isMobile } = useContext(AppContext);

    const [spoilerOpen, setSpoilerOpen] = useState(false);
    const hasContent = content?.length > 0;
    const displayContent = (!isSpoilerHidden && !isSoftDeleted) || (isSpoilerHidden && spoilerOpen) || (hasContent && spoilerOpen);

    const createdOnDate = new Date(createdOn);
    const createdOnString = `${createdOnDate.toLocaleDateString(undefined, {day: "numeric", month:"2-digit"}).split("/").reverse().join("/")} ${createdOnDate.getHours().toString().padStart(2, "0")}:${createdOnDate.getMinutes().toString().padStart(2, "0")}:${createdOnDate.getSeconds().toString().padStart(2, "0")}`;

    const lastUpdatedOnDate = new Date(lastUpdatedOn);
    const lastUpdatedOnString = `${lastUpdatedOnDate.toLocaleDateString(undefined, {day: "numeric", month:"2-digit"}).split("/").reverse().join("/")} ${lastUpdatedOnDate.getHours().toString().padStart(2, "0")}:${lastUpdatedOnDate.getMinutes().toString().padStart(2, "0")}:${lastUpdatedOnDate.getSeconds().toString().padStart(2, "0")}`;

    return (
        <>
        <div className={`${addBottomMargin ? "mb-3" : "mb-2"} ${addTopMargin ? "mt-3" : "mb-1"}`}>
            {
                isFirstItemInFeed &&
                <div className="pb-3"></div>
            }
            <Card
                withBorder={false}
                style={{
                    paddingRight: "0px!important",
                    paddingLeft: "0px!important",
                    paddingTop: "0rem!important",
                    paddingBottom: "0rem!important",
                    // borderRadius: 0, // < for full width
                    borderRadius: "1em",

                    opacity: displayContent ? 1 : 0.9

                    // minHeight: "10em", // todo remove
                }}
            >
                <div
                    className={`discussChatMessageUserInfoWrapper`} // todo
                    style={{
                        paddingBottom: "0.5em",
                    }}
                >
                    <Grid
                        columns={24}
                        className={`discussChatMessageUserInfo ${commonClasses.affixTopLevel} ${commonClasses.gridTopLevel}`} // todo
                        grow={true} // width max
                    >
                        {/* left section , ref MessagesDiv.drawio */}
                        <Grid.Col
                            className={`discussChatMessageUserInfoLeft ${commonClasses.affixTopLevel} ${commonClasses.column}`} // todo
                            span={isMobile ? 12 : 12}
                            style={{
                                alignContent: "start",
                            }}
                        >
                            <Group
                                position={"left"}
                                mt={0}
                                mb={0}
                            >
                                {/* message transient icos */}
                                    {
                                        isChatAdmin &&
                                            // <Tooltip
                                            //     label={"Chat moderator"}
                                            // >
                                            <Badge
                                                title={"Chat moderator"}
                                                size={"md"}
                                                // color={"green"}
                                                // color={"#fffff"}
                                                style={{
                                                    fontWeight: 400,
                                                    textTransform: "unset",
                                                    fontSize: "0.8em",
                                                    backgroundColor: "white",
                                                    color: "black"
                                                }}
                                                styles={(theme) => ({
                                                    inner: {
                                                        marginLeft: "0.5em",
                                                        marginTop: "0.3em",
                                                        position: "unset"
                                                    }
                                                })}
                                                leftSection={
                                                    <div>
                                                        <Planet
                                                            size={"1.2em"}
                                                            style={{
                                                                marginTop: "0.05em"
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            >
                                                {
                                                    "Staff"
                                                }
                                            </Badge>
                                            // </Tooltip>
                                    }

                                    {/* duplicate nickname (other verifs) */}
                                    {
                                        !nicknameIsUnique && 
                                            // <Tooltip
                                                // label={"Other verifier(s) use the same nickname"}
                                            // >   
                                                <div title={"Other verifier(s) use the same nickname"}>
                                                    <AlertTriangle 
                                                        size={"1.2em"}
                                                        color={"#ff2825"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                    }
                                    

                                    {/* multiple nicknames (this verif) */}
                                    {
                                        hasMultipleNicknames &&
                                            // <Tooltip
                                            //     label={"Verifier has multiple nicknames"}
                                            // >
                                                <div title={"Verifier has multiple nicknames"}>
                                                    <AlertTriangle
                                                        size={"1.2em"}
                                                        color={"#ffec00"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                    }

                                    {/* timed out */}
                                    {
                                        isTimedOutFromChat &&
                                            // <Tooltip
                                            //     label={"Verifier is timed out from chat"}
                                            // >
                                                <div title={"Verifier is timed out from chat"}>
                                                    <Clock
                                                        size={"1.2em"}
                                                        color={"#ffec00"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                    }

                                    {/* banned */}
                                    {
                                        isBannedFromChat &&
                                            // <Tooltip
                                            //     label={"Verifier is banned from chat"}
                                            // >
                                                <div title={"Verifier is banned from chat"}>
                                                    <Ban
                                                        size={"1.2em"}
                                                        color={"#ff2825"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                    }
                                    

                                {/* message permanent icos */}
                                    {/* incycle/out of cycle */}
                                    {
                                        isInCycle ?
                                            // <Tooltip
                                            //     label={"Verifier is incycle"}
                                            // >
                                                <div title={"Verifier is incycle"}>
                                                    <Spiral
                                                        size={"1.2em"}
                                                        color={"#7bc62d"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                        :
                                            // <Tooltip
                                            //     label={"Verifier is out of cycle"}
                                            // >
                                                <div title={"Verifier is out of cycle"}>
                                                    <SpiralOff
                                                        size={"1.2em"}
                                                        // color={"#9e9e9e"}
                                                    /> 
                                                </div>
                                            // </Tooltip>
                                    }

                                    {/* previously incycle - todo */}

                                    {/* has >= 1 node */}
                                    {
                                        hasOneOrMoreNodes ?
                                            // <Tooltip
                                            //     label={"Verifier has atleast one active node"}
                                            // >
                                                <div title={"Verifier has atleast one active node"}>
                                                    <Network
                                                        size={"1.2em"}
                                                        color={"#7bc62d"}
                                                    />
                                                </div>
                                            // </Tooltip>
                                        :
                                            // <Tooltip
                                            //     label={"Verifier has no active node(s)"}
                                            // >
                                                <div title={"Verifier has no active node(s)"}>
                                                    <NetworkOff
                                                        size={"1.2em"}
                                                        // color={"#9e9e9e"}
                                                    /> 
                                                </div>
                                            // </Tooltip>
                                    }
                                    
                                {/* message identifying data */}
                                    {/* short identifier */}
                                    {/* <Tooltip
                                        label={"Verifier short identifier"}
                                    > */}
                                        <Badge
                                            title={"Verifier short identifier"}
                                            size={"md"}
                                            // color={"green"}
                                            color={"#ffd43b"}
                                            style={{
                                                fontWeight: 400,
                                                textTransform: "lowercase",
                                                fontSize: "0.8em"
                                            }}
                                            styles={(theme) => ({
                                                inner: {
                                                    marginTop: "0.15em",
                                                    position: "unset"
                                                }
                                            })}
                                        >
                                            {
                                                publicIdentifierShortHexString
                                            }
                                        </Badge>
                                    {/* </Tooltip> */}


                                    {/* nickname, or short identifier again */}
                                    {/* <Tooltip
                                        label={"Verifier nickname"}
                                    > */}
                                        <pre
                                            title="Verifier nickname"
                                            style={{
                                                fontFamily:"retropix",
                                                fontSize:"1em",
                                                fontWeight:"400",
                                                marginBottom: 0,
                                                textAlign: "start",
                                            }}
                                        >
                                            {
                                                nickname?.length ? nickname : publicIdentifierShortHexString
                                            }
                                        </pre>
                                    {/* </Tooltip> */}
                                    
                            </Group>
                        </Grid.Col>

                        {/* right section */}
                        <Grid.Col
                            className={`discussChatMessageUserInfoRight ${commonClasses.affixTopLevel} ${commonClasses.column}`} // todo
                            span={12}
                            style={{
                                alignContent: "end",
                            }}
                        >
                            <Group
                                position={"right"}
                                mt={0}
                                mb={0}
                            >
                                {/* message time h:m */}
                                    {/* Date.getHours + : + getMinutes */}
                                    {!isMobile &&
                                    // <Tooltip 
                                    //     label={new Date(createdOn).toLocaleDateString(undefined, {
                                    //         weekday: "long",
                                    //         day: "numeric",
                                    //         month: "long",
                                    //         year: "numeric",
                                    //     }).replace(",", "").replace(",", "")}
                                    // >
                                        <Text weight={400} title={new Date(createdOn).toLocaleDateString(undefined, {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                        }).replace(",", "").replace(",", "")}>
                                            {`#${id} -`}
                                            {createdOnString}
                                        </Text>
                                    // </Tooltip>
                                    }
                                {/* interactables */}
                                    {/* session verif, user-scan */}
                                    {/* <Tooltip
                                        label={"View session details"}
                                    > */}
                                        <div
                                            title="View session details"
                                            // onClick={sessionDetailsModalControl.open}
                                            onClick={openSessionDetailsModal}
                                        >
                                            {/* iconuserscan */}
                                            <Lock
                                                size={"1.2em"}
                                                // color={"#9e9e9e"}
                                            />
                                        </div>
                                    {/* </Tooltip> */}
                                    

                                    {/* user details open, database-search */}
                                    {/* <Tooltip
                                        label={"View user details"}
                                    > */}
                                        <div
                                            title="View user details"
                                            // onClick={userDetailsModalControl.open}
                                            onClick={openUserDetailsModal}
                                        >
                                            {/* clipboard-text, list-search, vocabulary, tornado, icondatabasesearch (initial),  */}
                                            <User
                                                size={"1.2em"}
                                                // color={"#9e9e9e"}
                                            />
                                        </div>
                                    {/* </Tooltip> */}

                                    {/* self moderation / admin moderation */}
                                    {
                                        authContext.Session.loggedIn &&
                                        // <Tooltip
                                        //     label={"Options"}
                                        // >
                                        <div
                                                title="Options"
                                                onClick={openModerationOptionsModal}
                                        >
                                                <DotsVertical
                                                    size={"1.2em"}
                                                />
                                        </div>
                                        // </Tooltip>
                                    }
                                    
                                    {/* <br/> */}

                                    {/* message time h:m */}
                                    {/* Date.getHours + : + getMinutes */}
                                    {
                                        isMobile &&
                                    // <Tooltip 
                                    //     label={new Date(createdOn).toLocaleDateString(undefined, {
                                    //         weekday: "long",
                                    //         day: "numeric",
                                    //         month: "long",
                                    //         year: "numeric",
                                    //     }).replace(",", "").replace(",", "")}
                                    // >
                                        <Text weight={400} title={new Date(createdOn).toLocaleDateString(undefined, {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "long",
                                            year: "numeric",
                                        }).replace(",", "").replace(",", "")}>
                                            {`#${id} -`}
                                            {createdOnString}
                                        </Text>
                                    // </Tooltip>
                                    }
                            </Group>
                        </Grid.Col>
                    </Grid>
                    <hr
                        style={{
                            position: "absolute",
                            width: "300%",
                            marginLeft: "-250px",
                            // paddingTop: "0.5em",
                            marginTop: "0.6em",
                            // paddingBottom: "0.8em",
                            marginBottom: 0,
                            opacity: 0.1
                        }}
                    />
                </div>
                <div
                    className={`discussChatMessageContentViewWrapper`}
                >
                    {/* message content displayed using rte & uses separate editor */}
                    <Grid
                        columns={24}
                        className={`discussChatMessageContentView ${commonClasses.affixTopLevel} ${commonClasses.gridTopLevel}`} // todo
                        grow={true} // width max
                    >
                        {/* left section , ref MessagesDiv.drawio */}
                        <Grid.Col
                            className={`discussChatMessageContentViewMain ${commonClasses.affixTopLevel} ${commonClasses.column}`} // todo
                            span={24}
                            style={{
                                // alignContent: "start",
                                paddingLeft: 0,
                                paddingRight: 0,
                            }}
                        >
                            <RichTextEditor 
                                // id=""
                                editor={editorView}
                                // className={classes.rte}
                                style={{
                                    border: 0,
                                }}
                            >
                                <div id={"rte-content-" + id}>
                                    {
                                        !displayContent &&
                                        <div>
                                            <Alert 
                                                color={hasContent ? "yellow" : "red"}
                                                radius="lg"
                                                variant="outline"

                                                style={{
                                                    width: "100%",
                                                    borderColor: hasContent ? "#fcc419a6" : "#ff6b6bc2"
                                                }}
                                                className="mt-3"
                                                title={(hasContent ? "Content hidden" : "Content deleted")}
                                            >
                                                {
                                                    hasContent && 
                                                    <div>
                                                        The content of this message has been hidden.<br/><b>Reason:</b> {
                                                            contentAvailabilityChangeReason ?? "(none)"
                                                        }<br/><b>Last updated on:</b> {
                                                            lastUpdatedOnString
                                                        }
                                                        <Divider
                                                            label="View message content"
                                                            labelPosition="center"
                                                            my="xs"

                                                            style={{
                                                                marginBottom: 0
                                                            }}

                                                            onClick={() => setSpoilerOpen(!spoilerOpen)}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    !hasContent &&
                                                    <div>
                                                        The content of this message has been deleted.<br/><b>Reason:</b> {
                                                            contentAvailabilityChangeReason ?? "(none)"
                                                        }<br/><b>Last updated on:</b> {
                                                            lastUpdatedOnString
                                                        }
                                                    </div>
                                                }
                                            </Alert>
                                        </div>
                                    }
                                    {
                                        ((isSpoilerHidden || isSoftDeleted) && displayContent) &&
                                        <div>
                                            <Divider
                                                label="Hide message content"
                                                labelPosition="center"
                                                my="xs"

                                                style={{
                                                    marginBottom: 0
                                                }}

                                                color="yellow"
                                                onClick={() => setSpoilerOpen(!spoilerOpen)}
                                            />
                                        </div>
                                    }
                                    {/* <div contentEditable="false" translate="no" class="tiptap ProseMirror" style={{width: "100%", paddingBottom: 0, display: "block"}} spellCheck="false"><p></p></div> */}
                                    <RichTextEditor.Content
                                        // id={inputRteId}
                                        style={{
                                            backgroundColor: "transparent",
                                            // backgroundColor: "#373A40",
                                            // borderRadius: "2em",
                                            // minHeight: "5%",
                                            // maxHeight: "50%",
                                            width: "100%",
                                            // bottom: 0,
                                            display: displayContent ? "block" : "none"
                                        }}
                                    />
                                </div>
                            </RichTextEditor>
                        </Grid.Col>
                    </Grid>
                </div>
            </Card>
            {
                isLastItemInFeed &&
                <div className="pb-3"></div>
            }
        </div>
        </>
    );
})

