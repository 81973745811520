import React, { useEffect, useState } from 'react';
import { ColorSchemeProvider, MantineProvider, Paper } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import LoadExtensionMethods from './Common/Extensions';
import { Notifications, Notifications as NotificationsOverlay } from '@mantine/notifications';
import { ModalsProvider as ModalsOverlay, ModalsProvider } from '@mantine/modals';
import { AuthWrapper } from './Common/Wrappers/AuthWrapper';
import { AppWrapper } from './Common/Wrappers/AppWrapper';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';

import { useLocalStorage } from '@mantine/hooks';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import { SpotlightProvider } from '@mantine/spotlight';

import "./index.css";
import { DiscussMemberEntryModal } from './Modals/DiscussMemberEntryModal';
import { DiscussSessionDetailsModal } from './Modals/DiscussSessionDetailsModal';
import { DiscussUserDetailsModal } from './Modals/DiscussUserDetailsModal';
import { NavbarSignInModal } from './Modals/NavbarSignInModal';
import { NavbarSignOutModal } from './Modals/NavbarSignOutModal';
import { ChatMessageModerationOptionsModal } from './Modals/ChatMessageModerationOptionsModal';

// import "observable-slim/proxy.js";

function Index() {
    const [extensionMethodsAssigned, setExtensionMethodsAssigned] = useState(false);
    const [colorScheme, setColorScheme] = useLocalStorage({ key: "color-scheme", defaultValue: "dark" });
    const [activeColorScheme, setActiveColorScheme] = useState(colorScheme);

	const toggleColorScheme = () => {
		setColorScheme((current) => {
            let value = current === "dark" ? "light" : "dark"
            setActiveColorScheme(value);
            return value;
        });
	};

    useEffect(() => {
        // An occurrence of function not existing, pretty sure the useEffect runs in parallel with other onLoad related processes, this assures the desired content is loaded after the extension methods have been added
        // Kind of a consequence of introducing the extension methods within a React environment, intellisense not identifying these functions is normal
        setExtensionMethodsAssigned(LoadExtensionMethods());
    }, []);

    return (
        <React.StrictMode>
            <ColorSchemeProvider colorScheme={activeColorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider
                    withGlobalStyles
                    withNormalizeCSS
                    toggleColorScheme={toggleColorScheme}
                    theme={{
                        colorScheme: activeColorScheme,

                        // Text direction
                        dir: 'ltr',

                        /** Controls focus ring styles. Supports the following options:
                        *  - `auto` – focus ring is displayed only when the user navigates with keyboard (default value)
                        *  - `always` – focus ring is displayed when the user navigates with keyboard and mouse
                        *  - `never` – focus ring is always hidden (not recommended)
                        */
                        focusRing: "auto",

                        // Default border-radius used for most elements
                        // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string | number
                        defaultRadius: "md",

                        // Oval, bars, dots => depicts the loading animation's style
                        loader: "oval",

                        // Override white/black values
                        // white: string,
                        // black: string,

                        // Primary theme color
                        // primaryColor: "#fdfda4",
                        primaryColor: "yellow",

                        // Default theme colors, MantineThemeColors
                        // colors: ,

                        /** font-family used in all components, system fonts by default */
                        fontFamily: "retropix",

                        /** Monospace font-family, used in code and other similar components, system fonts by default */
                        fontFamilyMonospace: "retropix",

                        // export type MantineSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
                        /** Object of values that are used to control `line-height` property in `Text` component */
                        // lineHeights: {
                        //     xs: "",
                        //     sm: "",
                        //     md: "",
                        //     lg: "",
                        //     xl: "",
                        // },

                        // Omitted
                        // transitionTimingFunction: CSSProperties['transitionTimingFunction'],
                        
                        /** Determines whether user OS settings to reduce motion should be respected, `false` by default */
                        respectReducedMotion: true,

                        cursorType: 'default' | 'pointer',

                        // Gradient
                        // { from: , to: , deg:  }
                        // defaultGradient: null,
                    
                        // export type MantineSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
                        /** Object of values that are used to control `font-size` property in all components */
                        // fontSizes: {
                        //     xs: "",
                        //     sm: "",
                        //     md: "",
                        //     lg: "",
                        //     xl: "",
                        // },

                        // export type MantineSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
                        /** Object of values that are used to set various CSS properties that control spacing between elements */
                        // spacing: {
                        //     xs: "",
                        //     sm: "",
                        //     md: "",
                        //     lg: "",
                        //     xl: "",
                        // },

                        // export type MantineSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
                        /** Object of values that are used to control breakpoints in all components, values are expected to be defined in em */
                        // breakpoints: {
                        //     xs: "",
                        //     sm: "",
                        //     md: "",
                        //     lg: "",
                        //     xl: "",
                        // },

                        // export type MantineSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
                        /** Object of values that are used to add `box-shadow` styles to components that support `shadow` prop */
                        // shadows: {
                        //     xs: "",
                        //     sm: "",
                        //     md: "",
                        //     lg: "",
                        //     xl: "",
                        // },
                    
                        // todo cont:
                        // fn: MantineThemeFunctions;
                        // other: MantineThemeOther;
                        // activeStyles: CSSObject;
                        // datesLocale: string;
                        // components: MantineThemeComponents;
                        // globalStyles: (theme: MantineTheme) => CSSObject;
                        // focusRingStyles: MantineFocusRingStyles;
                        

                        /** rem units scale, change if you customize font-size of `<html />` element default value is `1` (for `100%`/`16px` font-size on `<html />`) */
                        scale : 1,

                        /** Determines whether `font-smoothing` property should be set on the body, `true` by default, since the font is blocky (retro style), this is turned off */
                        fontSmoothing: false,

                        /** Index of theme.colors[color].
                         *  Primary shade is used in all components to determine which color from theme.colors[color] should be used.
                         *  Can be either a number (0–9) or an object to specify different color shades for light and dark color schemes.
                         *  Default value `{ light: 6, dark: 8 }`
                         *
                         *  For example,
                         *  { primaryShade: 6 } // shade 6 is used both for dark and light color schemes
                         *  { primaryShade: { light: 6, dark: 7 } } // different shades for dark and light color schemes
                         **/
                        primaryShade: {
                            light: 6,
                            dark: 7
                        },

                        /** Key of `theme.colors`, hex/rgb/hsl values are not supported.
                        *  Determines which color will be used in all components by default.
                        *  Default value – `blue`.
                        **/
                        // primaryColor: "yellow",

                        /** Object of values that are used to control breakpoints in all components, values expected to be defined in em * */
                        breakpoints: {
                            xs: '30em',
                            sm: '48em',
                            md: '64em',
                            lg: '74em',
                            xl: '90em',
                        },

                        activeStyles: {
                            transform: "scale(0.80)",
                        },

                        // headings: {
                        //     fontFamily: "retropix",
                        //     fontWeight: 500,
                        //     sizes: {
                        //         h1: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //         h2: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //         h3: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //         h4: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //         h5: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //         h6: {
                        //             fontSize: "",
                        //             fontWeight: "",
                        //             lineHeight: ""
                        //         },
                        //     }
                        // },
                        other: {

                        }
                    }}
                >
                    <Notifications
                        position="bottom-left"
                        limit={5}
                        zIndex={10000}
                    />
                    <SpotlightProvider actions={[]}>
                        <ModalsProvider modals={{
                            DiscussMemberEntryModal: DiscussMemberEntryModal,
                            DiscussSessionDetailsModal: DiscussSessionDetailsModal,
                            DiscussUserDetailsModal: DiscussUserDetailsModal,
                            NavbarSignInModal: NavbarSignInModal,
                            NavbarSignOutModal: NavbarSignOutModal,
                            ChatMessageModerationOptionsModal: ChatMessageModerationOptionsModal
                        }}>
                            <AuthWrapper>
                                <AppWrapper>
                                    <Paper style={{ minHeight: "100vh" }}>
                                        {
                                            extensionMethodsAssigned ? <App/> : <></>
                                        }
                                    </Paper>
                                </AppWrapper>
                            </AuthWrapper>
                        </ModalsProvider>
                    </SpotlightProvider>
                </MantineProvider>
            </ColorSchemeProvider>
        </React.StrictMode>
    );
}

ReactDOM.render(
	<BrowserRouter>
        <Index />
    </BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
