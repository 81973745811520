import { createContext, useContext, useEffect, useState } from "react";
import { Logger } from "../Common/Shared/Logger";
import { IconCheck, IconHash, IconSpeakerphone, IconX } from "@tabler/icons-react";
import { RouterPaths } from "../Static/RouterPaths";
import { notifications } from "@mantine/notifications";
import { AuthContext } from "../Common/Wrappers/AuthWrapper";
import { Clock, User } from "tabler-icons-react";
import { useLocalStorage } from "@mantine/hooks";

const cloneDeep = require("lodash.clonedeep");

export const ChatContext = createContext();

export function ChatWrapper({children}){
    const chatManager = useChatManager({
        eventPollingFrequencyMilliSeconds: 1000
    });

    return (
        <ChatContext.Provider
            value={{
                initialStateReady: chatManager.initialStateReady,
                chatContextReady: chatManager.chatContextReady,
                longPollingActive: chatManager.longPollingActive,
                sessionIsStale: chatManager.sessionIsStale,
                lastMessageSentSuccessfully: chatManager.lastMessageSentSuccessfully,
                inputOverlayShown: chatManager.inputOverlayShown,
                fetchingLatestEnabled: chatManager.fetchingLatestEnabled,
                eventPollingFrequencyMilliSeconds: chatManager.eventPollingFrequencyMilliSeconds,

                suspendFetchingLatestMessages: chatManager.suspendFetchingLatestMessages,
                setSuspendFetchingLatestMessages: chatManager.setSuspendFetchingLatestMessages,

                members: chatManager.members,
                chatFeed : chatManager.chatFeed,

                // messages: _messages ?? [],
                // events: _events ?? [],

                setChatContext: chatManager.setChatContext,

                fn: {
                    admin: {
                        banMember: chatManager.banMember,
                        timeoutMember: chatManager.timeoutMember,
                        deleteMessage: chatManager.deleteMessage
                    },

                    addMessage: chatManager.addMessage,
                    reportMessage: chatManager.reportMessage,
                    spoilerHideMessage: chatManager.spoilerHideMessage
                }
            }}
        >
            {children}
        </ChatContext.Provider>
    )
}

// Not fully DRY-principled but flexible 
export function useChatManager({eventPollingFrequencyMilliSeconds=1000}) {
    // const [_channels, _setChannels] = useState([]);
    // const [_activeChannel, _setActiveChannel] = useState(null);
    const [_members, _setMembers] = useState([]);
    const [_messages, _setMessages] = useState([]);
    const [_events, _setEvents] = useState([]);
    const [_feedEvents, _setFeedEvents] = useState([]);
    // =
    const [_chatFeed, _setChatFeed] = useState(null);
    const __setChatFeed = () => {
        _setChatFeed((_messages ?? []).concat(_feedEvents?.filter(e => e.persistent) ?? [])?.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn)) ?? []);
    }

    // no cap for events, the "timeline" should still be accurate, adjust the max messages if necessary
    const _maxMessagesInMemory = 35; 
    const _maxFeedItems = 2500;

    const [_initialStateReady, _setInitialStateReady] = useState(false);
    const [_chatContextReady, _setChatContextReady] = useState(false);
    const [_longPollingActive, _setLongPollingActive] = useState(false);
    const [_lastLongPollRoundtripCompleted, _setlastLongPollRoundtripCompleted] = useState(new Date(0));

    const [_lastMessageSentSuccessfully, _setLastMessageSentSuccessfully] = useState(new Date(0));

    const [_inputOverlayShown, _setInputOverlayShown] = useState(false);
    const [_fetchingLatestEnabled, _setFetchingLatestEnabled] = useState(false);
    const [_suspendFetchingLatestMessages, _setSuspendFetchingLatestMessages] = useState(false);

    const [_cooldownUntil, _setCooldownUntil] = useLocalStorage({
        key: "chat-cooldown-until", 
        defaultValue: new Date(0),
        serialize: (value) => {
            return value.toISOString();
        },
        deserialize: (lsvalue) => {
            return new Date(lsvalue);
        }
    });

    const authContext = useContext(AuthContext);

    const [_chatContext, _setChatContext] = useState({
        viewport: null,
        scrollToBottom: null,
        scrollToTop: null,
        scrollToHalfway: null,
        scrollPosition: null,
    });

    const [_lastEventFetchState, _setLastEventFetchState] = useState({
        before: null,
        after: null,
        maxAmount: null,
        first: null,
        last: null,
        totalRecords: null,
        earliest: null,
        latest: null,
        reachedEnd: null,
        createdOn: null,
    });

    const [_initialCreatedOnUsers, _setInitialCreatedOnUsers] = useState(null);

    const [_lastTransientEventFetchState, _setLastTransientEventFetchState] = useState({
        before: null,
        after: null,
        maxAmount: null,
        first: null,
        last: null,
        totalRecords: null,
        earliest: null,
        latest: null,
        reachedEnd: null,
        createdOn: null,
    });

    const [_lastMessageFetchState, _setLastMessageFetchState] = useState({
        before: null,
        after: null,
        maxAmount: null,
        first: null,
        last: null,
        totalRecords: null,
        earliest: null,
        latest: null,
        reachedEnd: null,
        paginationMethod: null,
        success: null,
        createdOn: null,
        messages: null, // < todo notifs
    });

    const API = RouterPaths.API.DISCUSS;

    const _api = (path) => {
        return `${API}${path}`;
    }

    // const _passableStatusCode = (value) => {
    //     return value.toString().startsWith("2") || value.toString().startsWith("400");
    // }

    const _isObject = (value) => {
        return typeof value == "object" && !Array.isArray(value) && value != null && value != undefined
    }

    // admin
    const _banMember = async (publicIdentifierHexString, reason, untilISOString) => {
        let request = {
            publicIdentifierHexString: publicIdentifierHexString,
            reason: reason,
            until: untilISOString
        }

        notifications.show({
            id: "ban-notif",
            loading: true,
            title: "Banning user",
            autoClose: false,
            withCloseButton: false
        });

        return await fetch(new Request(_api("/user/ban"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                notifications.update({
                    id: "ban-notif",
                    color: "teal",
                    title: "Banning successful",
                    message: `User ${publicIdentifierHexString} has been banned until ${untilISOString}.`,
                    icon: <IconCheck size="1rem"/>,
                    autoClose: 10000
                });
            } else {
                notifications.update({
                    id: "ban-notif",
                    color: "red",
                    title: "Banning failed",
                    message: `User ${publicIdentifierHexString} could not be banned.`,
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }

            return data;
        })
        .catch(e => {
            // log

            notifications.update({
                id: "ban-notif",
                color: "red",
                title: "Banning failed",
                message: `An unexpected error occurred.`,
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }

    // admin
    const _timeoutMember = async (publicIdentifierHexString, reason, untilISOString) => {
        let request = {
            publicIdentifierHexString: publicIdentifierHexString,
            reason: reason,
            until: untilISOString
        }

        notifications.show({
            id: "timeout-notif",
            loading: true,
            title: "Timing out user",
            autoClose: false,
            withCloseButton: false
        });

        return await fetch(new Request(_api("/user/timeout"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                notifications.update({
                    id: "timeout-notif",
                    color: "teal",
                    title: "Time-out successful",
                    message: `User ${publicIdentifierHexString} has been timed out until ${untilISOString}.`,
                    icon: <IconCheck size="1rem"/>,
                    autoClose: 10000
                });
            } else {
                notifications.update({
                    id: "timeout-notif",
                    color: "red",
                    title: "Time-out failed",
                    message: `User ${publicIdentifierHexString} could not be timed out.`,
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }

            return data;
        })
        .catch(e => {
            // log

            notifications.update({
                id: "timeout-notif",
                color: "red",
                title: "Time-out failed",
                message: `An unexpected error occurred.`,
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }

    // admin
    const _deleteMessage = async (id, reason, purgeContent) => {
        let request = {
            id: id,
            purgeContent: purgeContent ?? false,
            reason: reason
        }

        notifications.show({
            id: "msg-delete-notif",
            loading: true,
            title: "Deleting message",
            autoClose: false,
            withCloseButton: false
        });

        return await fetch(new Request(_api("/message/delete"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                notifications.update({
                    id: "msg-delete-notif",
                    color: "teal",
                    title: "Message deletion successful",
                    message: `Message #${id} has been ${(purgeContent ? "permanently deleted" : "softdeleted")}.`,
                    icon: <IconCheck size="1rem"/>,
                    autoClose: 10000
                });
            } else {
                notifications.update({
                    id: "msg-delete-notif",
                    color: "red",
                    title: "Message deletion failed",
                    message: `Message #${id} could not be deleted.`,
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }

            return data;
        })
        .catch(e => {
            // log

            notifications.update({
                id: "msg-delete-notif",
                color: "red",
                title: "Message deletion failed",
                message: `An unexpected error occurred.`,
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }

    const _addMessage = async (encodedContent) => {
        let request =  {
            content: encodedContent
        }

        _setInputOverlayShown(true);

        return await fetch(new Request(_api("/message/add"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            // if(data.success){
            if(data.content && Array.isArray(data.content) && data.content.length > 0){
                let responseItem = data.content[0];

                if(_isObject(responseItem)){
                    if(responseItem.accepted){
                        _setLastMessageSentSuccessfully(new Date(Date.now()));
                    }

                    if(responseItem.cooldownReason){
                        notifications.show({
                            id: "msg-add-notif",
                            color: responseItem.accepted ? "teal" : "red",
                            title: responseItem.accepted ? "Message accepted, new chat cooldown active" : "Message rejected, chat cooldown is active",
                            message: responseItem.cooldownReason,
                            icon: responseItem.accepted ? <Clock/> : <IconX/>,
                            autoclose: 20000,
                            withCloseButton: false
                        });
                    }

                    let now = new Date(Date.now());
                    let cooldownUntil = new Date(responseItem.cooldownUntil);

                    if(+now < +cooldownUntil){
                        _setCooldownUntil(cooldownUntil);
                    }
                }
            } else {
                notifications.show({
                    id: "msg-add-notif",
                    color: "red",
                    title: "Sending message failed",
                    message: `Please try again.`,
                    icon: <IconX/>,
                    autoClose: 20000,
                    withCloseButton: true
                });
            }

            _setInputOverlayShown(false);
            return data;
                
            // } else {
            //     notifications.show({
            //         id: "msg-add-notif",
            //         color: "red",
            //         title: "Sending message failed",
            //         message: `Please try again.`,
            //         icon: <IconX/>,
            //         autoClose: 10000,
            //         withCloseButton: true
            //     });
            // }
        })
        .catch(e => {
            // log
            _setInputOverlayShown(false);
            notifications.show({
                id: "msg-add-notif",
                color: "red",
                title: "Sending message failed",
                message: `An unexpected error occurred. Please try again.`,
                icon: <IconX/>,
                autoClose: 20000,
                withCloseButton: true
            });
        })


    }

    const _reportMessage = async (id, reason) => {
        let request = {
            id: id,
            reason: reason
        }

        notifications.show({
            id: "msg-report-notif",
            loading: true,
            title: "Reporting message",
            autoClose: false,
            withCloseButton: false
        });

        return await fetch(new Request(_api("/message/report"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                notifications.update({
                    id: "msg-report-notif",
                    color: "teal",
                    title: "Message reported successfully",
                    message: `Message #${id} has been reported.`,
                    icon: <IconCheck size="1rem"/>,
                    autoClose: 10000
                });
            } else {
                notifications.update({
                    id: "msg-report-notif",
                    color: "red",
                    title: "Reporting message failed",
                    message: `Message #${id} could not be reported. A message can only be reported once per user.`,
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }

            return data;
        })
        .catch(e => {
            // log

            notifications.update({
                id: "msg-report-notif",
                color: "red",
                title: "Reporting message failed",
                message: `An unexpected error occurred.`,
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }

    const _spoilerHideMessage = async (id, reason) => {
        let request = {
            id: id,
            reason: reason ? reason : null
        }

        notifications.show({
            id: "msg-spoiler-hide-notif",
            loading: true,
            title: "Spoiler hiding message",
            autoClose: false,
            withCloseButton: false
        });

        return await fetch(new Request(_api("/message/spoiler"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": authContext.Session.publicIdentifierHexString,
                "Token": authContext.Session.token
            },
            body: JSON.stringify(request)
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                notifications.update({
                    id: "msg-spoiler-hide-notif",
                    color: "teal",
                    title: "Message successfully spoiler hidden",
                    message: `Message #${id} has been spoiler hidden.`,
                    icon: <IconCheck size="1rem"/>,
                    autoClose: 10000
                });
            } else {
                notifications.update({
                    id: "msg-spoiler-hide-notif",
                    color: "red",
                    title: "Spoiler hiding message failed",
                    message: `Message #${id} could not be spoiler hidden. A message can only be spoiler hidden once per user.`,
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }

            return data;
        })
        .catch(e => {
            // log

            notifications.update({
                id: "msg-spoiler-hide-notif",
                color: "red",
                title: "Spoiler hiding message failed",
                message: `An unexpected error occurred.`,
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }



    // BE does not include a message for which the provided datetime in the query matches that of an entry in the db
    const _fetchMembers = async () => {
        return await fetch(_api("/user"))
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Failed to connect to endpoint", response);
                    }

                    return response.json();
                }).then(data => {
                    if(data.success){
                        if(data.content && Array.isArray(data.content) && data.content.length > 0){
                                let responseItem = data.content[0];

                                if(Array.isArray(responseItem.users)){
                                    _setInitialCreatedOnUsers(data.createdOn);

                                    _setMembers(responseItem.users);
                                    return;
                                } else {
                                    Logger.LogError(20, data, "inner array");
                                }
                        } else {
                            Logger.LogError(20, data);
                        }
                    } else {
                        Logger.LogError(18, data);
                    }
                }).catch(e => {
                    Logger.LogError(21, e);
                });
    }

    const _fetchMemberById = async (id) => {
        return await fetch(_api(`/user/${id}`))
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Failed to connect to endpoint", response);
                    }

                    return response.json();
                }).then(async (data) => {
                    if(data.success){
                        if(data.content && Array.isArray(data.content) && data.content.length > 0) {
                            let member = data.content[0];

                            if(_isObject(member)) {
                                let existingMember = _members.find(x => x.publicIdentifierHexString == member.publicIdentifierHexString);

                                if(existingMember){
                                    _setMembers(_members.filter(x => x !== existingMember).concat([member]));
                                } else {
                                    _setMembers(_members.concat([member]));
                                }

                                // while(!_members.includes(member)) { await new Promise(r => setTimeout(r, 20)); }

                                // __setChatFeed();
                                return member;
                            }
                        } else {
                            Logger.LogError(22, response);
                        }
                    }

                    return null;
                }).catch(e => {
                    Logger.LogError(21, e);
                    return null;
                });
    }

    const _fetchMemberByPublicIdentifier = async (identifier) => {
        return await fetch(_api(`/user/verifier/${identifier}`))
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Failed to connect to endpoint", response);
                    }

                    return response.json();
                }).then(async (data) => {
                    if(data.success){
                        if(data.content && Array.isArray(data.content) && data.content.length > 0) {
                            let member = data.content[0];

                            if(_isObject(member)) {
                                let existingMember = _members.find(x => x.publicIdentifierHexString == member.publicIdentifierHexString);

                                if(existingMember){
                                    let idx = _members.indexOf(existingMember);

                                    if(!idx){
                                        console.error(existingMember, idx);
                                    } else {
                                        let members = cloneDeep(_members);
                                        members[idx] = member;
                                        _setMembers(members);
                                    }
                                    // _setMembers(_members.filter(x => x !== existingMember).concat([member]));
                                } else {
                                    _setMembers(_members.concat([member]));
                                }

                                if(authContext.Session.publicIdentifierHexString == member.publicIdentifierHexString){
                                    authContext.fn.SetLocalStoragePrivilegeLimiters(member.chatCooldownUntil, member.timedOutUntil, member.bannedUntil);
                                }

                                // while(!_members.includes(member)) { await new Promise(r => setTimeout(r, 20)); }

                                // return member;
                            }
                        } else {
                            Logger.LogError(22, response);
                        }
                    }

                    return null;
                }).catch(e => {
                    Logger.LogError(21, e);
                    return null;
                });
    }

    const _fetchEvents = async (before=null, after=null, persistentOnly=null) => {
        let pathAppendix = "";

        if(before){
            pathAppendix = "?before=" + before;     
        }

        if(after) {
            pathAppendix = "?after=" + after;
        }

        if(!pathAppendix){
            console.error("path appendix missing");
            return;
            // let lastMessageCreatedOn = new Date(Math.max(..._messages.map(x => x.createdOn)));
            // let lastEventCreatedOn = new Date(Math.max(..._events.map(x => x.createdOn)));

            // console.log(_messages.map(x => x.createdOn), _events.map(x => x.createdOn), lastMessageCreatedOn, lastEventCreatedOn);

            // pathAppendix = "?after=" + new Date(Math.min(lastMessageCreatedOn, lastEventCreatedOn)).toISOString();
        }

        let persistentAppendix = persistentOnly ? "&ignorePersistence=false" : "&ignorePersistence=true"; 

        return await fetch(_api("/events" + pathAppendix + persistentAppendix))
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Failed to connect to endpoint", response); 
                    }

                    return response.json();
                }).then(async (data) => {
                    if(data.success){
                        if(data.content && Array.isArray(data.content) && data.content.length > 0){
                            let responseItem = data.content[0];

                            if(_isObject(responseItem.eventSet)){
                                if(Array.isArray(responseItem.eventSet.events)){
                                    let eventStoreSet = cloneDeep(responseItem.eventSet);
                                    eventStoreSet.events = undefined;
                                    eventStoreSet.createdOn = data.createdOn;
                                    eventStoreSet.first = eventStoreSet.first ?? _lastTransientEventFetchState.first;
                                    eventStoreSet.last = eventStoreSet.last ?? _lastTransientEventFetchState.last;
                                    _setLastTransientEventFetchState(eventStoreSet);

                                    // for(let i=0; i<responseItem.events.events.length; i++){
                                    //     let event = responseItem.events.events[i];
                                    //     let createdOn = cloneDeep(event.CreatedOn);
                                    //     event.CreatedOn = undefined;
                                    //     event.createdOn = createdOn;
                                    // }

                                    let fetchMemberPublIds = [];

                                    for(let i=0; i<responseItem.eventSet.events.length; i++){
                                        let event = responseItem.eventSet.events[i];

                                        switch(event.type){
                                            case "SystemEvent":
                                                break;
                                            case "CycleBlockProducedEvent":
                                                break;
                                            case "CycleFrozenEdgeFallbackEvent":
                                                break;
                                            case "CycleVerifierDroppedEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "CycleVerifierJoinedEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "VerifierChangedNicknameEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "VerifierLostNodeEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "VerifierNewNodeEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "UserBannedEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "UserSignedInEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);

                                                authContext.fn.NotifyThirdPartyLoggedIn(event.nickname, event.publicIdentifierHexString);
                                                
                                                break;
                                            case "UserStatusEvent":
                                                break;
                                            case "UserTimedOutEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "MessageAddedEvent":
                                                fetchMemberPublIds.push(event.publicIdentifierHexString);
                                                break;
                                            case "MessageDeletedEvent":
                                                await _fetchMessage(event.messageId, true);
                                                break;
                                            case "MessageSpoilerHiddenEvent":
                                                await _fetchMessage(event.messageId, true);
                                                break;
                                            default:
                                                console.debug("[NOT-IMPLEMENTED]: "+event.type);
                                                break;
                                            }

                                        }

                                    let fetchMemberPublIdsDeduped = [...new Set(fetchMemberPublIds)];

                                    for(const publ of fetchMemberPublIdsDeduped){
                                        await _fetchMemberByPublicIdentifier(publ);
                                    }
                                }
                            }
                        }
                    }

                    return data;
                });
    } 

    // todo move to proper place
    const _handleRequestMessage = async(message) => {
        // messageType int, type? string (=title), body? string (=content)

    }

    // Fetches messages and persistent-only events
    // Appropriate on-load/init or if the session has been stale for some time
    const _fetchMessagesAndEvents = async (before=null, after=null) => {
        let pathAppendix = "";

        if(before){
            pathAppendix = "?before=" + before;     
        }

        if(after) {
            pathAppendix = "?after=" + after;
        }

        if(!pathAppendix){
            console.log("missing path ")
            pathAppendix = "?before=" + new Date(Date.now()).toISOString();
        }

        return await fetch(_api("/message" + pathAppendix))
                .then(response => {
                    if(!response.ok) {
                        throw new Error("Failed to connect to endpoint", response);
                    }

                    return response.json();
                }).then(data => {
                    if(data.success){
                        if(data.content && Array.isArray(data.content) && data.content.length > 0){
                            let fullSet = cloneDeep(data);
                            fullSet.content = undefined;
                            fullSet.first = fullSet.first ?? _lastMessageFetchState.first;
                            fullSet.last = fullSet.last ?? _lastMessageFetchState.last;
                            _setLastMessageFetchState(fullSet);

                            let responseItem = data.content[0];

                            if(Array.isArray(responseItem.messages)){
                                if(responseItem.messages.length){
                                    // These are to be processed
                                    _setMessages(_messages.concat(responseItem.messages.filter((m) => !(_messages.some((im) => m.id == im.id)))));
                                }
                            } else {
                                Logger.LogError(20, responseItem.messages, "messages");
                                throw 1;
                            }

                            if(responseItem.eventSet){
                                if(_isObject(responseItem.eventSet)){
                                    if(Array.isArray(responseItem.eventSet.events)){
                                        let eventStoreSet = cloneDeep(responseItem.eventSet);
                                        eventStoreSet.events = undefined;
                                        eventStoreSet.createdOn = data.createdOn;
                                        eventStoreSet.first = eventStoreSet.first ?? _lastEventFetchState.first;
                                        eventStoreSet.last = eventStoreSet.last ?? _lastEventFetchState.last;
                                        _setLastEventFetchState(eventStoreSet);
    
                                        if(responseItem.eventSet.events?.length > 0){
                                            // These are not to be processed, they are all Persistent=true
                                            _setFeedEvents(_feedEvents.concat(responseItem.eventSet.events.filter((e) => !(_feedEvents.some((ie) => e.id == ie.id)))));
                                        }
                                    } else {
                                        Logger.LogError(20, data, "events.events");
                                        throw 1;
                                    }
                                } else {
                                    Logger.LogError(20, data, "events");
                                    throw 1;
                                }
                            }
                        }
                    } else {
                        // success = false
                        // todo replicate in other fetch methods
                        // if(data.messages && Array.isArray(data.messages) && data.messages.length){

                        // }

                        Logger.LogError(18, data);
                        throw 1;
                    }

                    return data;
                }).catch(e => {
                    Logger.LogError(21, e);

                    notifications.show({
                        id: "init-fetch-fail",
                        color: "red",
                        title: "Fetching chat feed failed",
                        message: "Close this notification to retry",
                        withCloseButton: true,
                        autoclose: false,
                        icon: <IconX/>,
                        onClose: async () => {
                            await _fetchMessagesAndEvents();
                        }
                    });
                });
    }

    const _fetchMessage = async (id, updateOnly=true) => {
        if(_messages.find(x => x.id == id) == undefined){
            return;
        }

        return await fetch(_api(`/message/${id}`))
                        .then(response => {
                            if(!response.ok) {
                                throw new Error("Failed to connect to endpoint", response);
                            }
        
                            return response.json();
                        }).then(async (data) => {
                            if(data.success){
                                if(data.content && Array.isArray(data.content) && data.content.length > 0){
                                    let responseItem = data.content[0];
                                    if(responseItem?.exists){
                                        let message = responseItem.message;

                                        if(_isObject(message)){
                                            const scrolledToBottom = cloneDeep((_chatContext.viewport?.current?.scrollTop + _chatContext.viewport?.current?.clientHeight) >= _chatContext.viewport?.current?.scrollHeight);

                                            _setMessages(_messages.filter(x => x.id != message.id).concat([message]));
                                        
                                            if(scrolledToBottom && (message.isSpoilerHidden || message.isSoftDeleted)){
                                                await new Promise(r => setTimeout(r, (eventPollingFrequencyMilliSeconds / 3)));
                                                _chatContext.scrollToBottom();
                                            }
                                        }
                                    }
                                } else {
                                    Logger.LogError(22, data);
                                }
                            } else {
                                Logger.LogError(18, data);
                            }   

                            return data;
                        }).catch(e => {
                            Logger.LogError(21, e);
                            return null;
                        });
    }

    const _enforceMemoryLimit = async (pruneMostRecent) => {
        let messageExcessAmount = _messages.length - _maxMessagesInMemory;
        // let eventsExcessAmount = _events.length - (messageExcessAmount ? messageExcessAmount : 0) - _maxFeedItems;
        // let feedEventsExcessAmount = _feedEvents.length - (messageExcessAmount ? messageExcessAmount : 0) - _maxFeedItems;

        if(pruneMostRecent){
            if(messageExcessAmount){
                console.log(_messages.length, _maxMessagesInMemory, messageExcessAmount);
                _setMessages((curr) => {
                    let arr = [...curr];

                    let newarr =  
                           arr.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
                              .slice(messageExcessAmount)
                              ;
                    
                    let lastmsgdt = Math.max(...newarr.map((m) => new Date(m.createdOn)))

                    _setFeedEvents((c) => {
                        let arr = [...c];
    
                        return arr.filter(f => (+new Date(f.createdOn) - +lastmsgdt) < 0);
                    })

                    return newarr;
                })
            }
        } else {
            if(messageExcessAmount){
                _setMessages((curr) => {
                    let arr = [...curr];

                    let newarr =
                           arr.sort((a, b) => new Date(a.createdOn) - new Date(b.createdOn))
                              .slice(messageExcessAmount)
                              ;

                    let firstmsgdt = Math.min(...newarr.map((m) => new Date(m.createdOn)))

                    _setFeedEvents((c) => {
                        let arr = [...c];

                        return arr.filter(f => (+new Date(f.createdOn) - +firstmsgdt) > 0);
                    })

                    return newarr;
                })
            }

            // if(feedEventsExcessAmount){
            //     _setFeedEvents((curr) => {
            //         let arr = [...curr];

            //         return arr.sort((a,b) => new Date(a.createdOn) - new Date(b.createdOn))
            //                   .slice(feedEventsExcessAmount)
            //                   ;
            //     })
            // }
        }

        // if(pruneMostRecent){
        //     if(messageExcessAmount){
        //         let msgs = cloneDeep(_messages).sort((a,b) => a.id - b.id);

        //         _setMessages(msgs.splice(_maxMessagesInMemory - 1, messageExcessAmount));
        //     }

        //     if(feedEventsExcessAmount){
        //         // let events = cloneDeep(_events).sort((a,b) => a.id - b.id);

        //         // _setEvents(events.splice(_maxFeedItems - 1, eventsExcessAmount));

        //         let feedEvents = cloneDeep(_feedEvents).sort((a,b) => a.id - b.id);

        //         _setFeedEvents(feedEvents.splice(_maxFeedItems - 1, feedEventsExcessAmount));
        //     }
        // } else {
        //     if(messageExcessAmount){
        //         let msgs = cloneDeep(_messages).sort((a,b) => a.id - b.id);

        //         _setMessages(msgs.splice(0, msgs.length - messageExcessAmount - 1));
        //     }

        //     if(feedEventsExcessAmount){
        //         // let events = cloneDeep(_events).sort((a,b) => a.id - b.id);

        //         // _setEvents(events.splice(0, events.length - eventsExcessAmount - 1));

        //         let feedEvents = cloneDeep(_feedEvents).sort((a,b) => a.id - b.id);

        //         _setFeedEvents(feedEvents.splice(0, feedEvents.length - feedEventsExcessAmount - 1));
        //     }
        // }
    }

    const _performScrollBehavior = async () => {
        
    }

    const _performLongPoll = async () => {
        // return;
        // console.log("y")
        let start = new Date(Date.now()).getTime();

        if(_longPollingActive && _initialStateReady && _chatContextReady){
            // console.log("longpolling=active, initialstate=ready, chatcontext=ready");

            const scrolledToTop = _chatContext.viewport?.current?.scrollTop == 0;
            const scrolledToBottom = (_chatContext.viewport?.current?.scrollTop + _chatContext.viewport?.current?.clientHeight) >= _chatContext.viewport?.current?.scrollHeight - 10;

            const previousFetchWasBackwards = _lastEventFetchState.before !== null;
            const previousFetchWasForwards = +_lastLongPollRoundtripCompleted == 0 || _lastEventFetchState.after !== null;
            
            const thereAreNoPreviousMessages = _messages.find(x => x.id == 1) != undefined;
            const thereAreNoPreviousEvents = thereAreNoPreviousMessages || (_lastEventFetchState.first == _lastEventFetchState.earliest);

            // const thereAreNoPreviousEvents = _lastEventFetchState.first == _lastMessageFetchState.earliest;
            // const thereWereNoLaterMessages = _lastMessageFetchState.last == _lastMessageFetchState.latest; //
            const currentlyMaxMessagesInMemory = _messages.length >= _maxMessagesInMemory;

            let messageDateTimes = _messages.map((m) => new Date(m.createdOn)).sort((a,b) => a-b);
            let feedEventDateTimes = _feedEvents.map((e) => new Date(e.createdOn)).sort((a,b) => a-b);
            let chatFeedDateTimesBare = _messages.map((m) => m.createdOn).concat(_feedEvents.map((e) => e.createdOn));
            let dateTimes = messageDateTimes.concat(feedEventDateTimes);
            let minDateTime = new Date(Math.min(...dateTimes)).toISOString().replace("Z", "");
            let maxDateTime = new Date(Math.max(...dateTimes)).toISOString().replace("Z", "");
            let minDateTimeString = chatFeedDateTimesBare.find(x => x.startsWith(minDateTime)) ?? minDateTime + "Z";
            let maxDateTimeString = chatFeedDateTimesBare.find(x => x.startsWith(maxDateTime)) ?? maxDateTime + "Z";

            // let firstMessageId = Math.min(..._messages.map((m) => m.id));

            if(scrolledToBottom){
                if(!_suspendFetchingLatestMessages){
                    if(!_fetchingLatestEnabled){
                        _setFetchingLatestEnabled(true);
                    }

                    console.log("stb")
                    await _fetchMessagesAndEvents(null, maxDateTimeString).then(async (data) => {
                        do {
                            try { _chatContext?.scrollToBottom(); } catch {}
                            await new Promise(r => setTimeout(r, (eventPollingFrequencyMilliSeconds / 10)));
                        } while (
                            !(start + (eventPollingFrequencyMilliSeconds / 3) < (new Date(Date.now()).getTime())) &&
                            // !_suspendFetchingLatestMessages &&
                            (
                                !_chatContext.viewport 
                                || !((_chatContext.viewport?.current?.scrollTop + _chatContext.viewport?.current?.clientHeight) >= _chatContext.viewport?.current?.scrollHeight - 10)
                            )
                        );
                    }).then(() => {
                        _enforceMemoryLimit(false);
                    });
                    // _enforceMemoryLimit(false);
                }
            } else if(scrolledToTop){
                if(_fetchingLatestEnabled){
                    _setFetchingLatestEnabled(false);
                }

                console.log("stt")
                if(!thereAreNoPreviousMessages){
                    await _fetchMessagesAndEvents(minDateTimeString).then(async (data) => {

                    }).then(() => {
                        _enforceMemoryLimit(true);
                    });
                }
            } else {
                if(_fetchingLatestEnabled){
                    _setFetchingLatestEnabled(false);
                }
            }

            let afterDateTime = _lastTransientEventFetchState.latest;

            if(_lastTransientEventFetchState.last){
                afterDateTime = _lastTransientEventFetchState.last;
            }

            await _fetchEvents(null, afterDateTime);

            // console.log({
            //     viewport: _chatContext.viewport,
            //     scrolledToTop: scrolledToTop,
            //     scrolledToBottom: scrolledToBottom,
            //     isLurkingFeed: isLurkingFeed,
            //     previousFetchWasBackwards: previousFetchWasBackwards,
            //     previousFetchWasForwards: previousFetchWasForwards,
            //     thereAreNoPreviousMessages: thereAreNoPreviousMessages,
            //     thereAreNoPreviousEvents: thereAreNoPreviousEvents,
            //     thereWereNoLaterMessages: thereWereNoLaterMessages,
            //     currentlyMaxMessagesInMemory: currentlyMaxMessagesInMemory,
            //     lessThan50MessagesExist: lessThan50MessagesExist
            // });

            // if(isLurkingFeed || lessThan50MessagesExist){
            //     if(previousFetchWasBackwards){
            //         await _fetchEvents(null, _lastEventFetchState.last);

            //         if(isLurkingFeed){
            //             await _performScrollBehavior();
            //         }

            //         if(isLurkingFeed && currentlyMaxMessagesInMemory){
            //             await _enforceMemoryLimit(false);
            //         }
            //     } else if(previousFetchWasForwards){
            //         await _fetchEvents(null, _lastEventFetchState.last);
                    
            //         if(isLurkingFeed){
            //             await _performScrollBehavior();
            //         }

            //         if(isLurkingFeed && currentlyMaxMessagesInMemory){
            //             await _enforceMemoryLimit(false);
            //         }
            //     }
            // } else if (scrolledToTop){
            //     if(thereAreNoPreviousMessages && thereAreNoPreviousEvents){
            //         if(!currentlyMaxMessagesInMemory){
            //             await _fetchEvents(_lastEventFetchState.last);
            //             await _enforceMemoryLimit(true);
            //         }
            //     } else {
            //         await _fetchEvents(_lastEventFetchState.first);
            //         await _enforceMemoryLimit(true);
            //         // if(thereAreNoPreviousMessages && !thereAreNoPreviousEvents){
            //         //     await _fetchEvents(_lastEventFetchState.first);
            //         // }

            //         // if(!thereAreNoPreviousMessages && thereAreNoPreviousEvents){
            //         //     await _fetchEvents(_lastEventFetchState.first);
            //         // }
            //     }
            // }
        }

        let end = new Date(Date.now()).getTime();
        // console.log("ts",start, end, eventPollingFrequencyMilliSeconds);
    }

    const _fetchInitialState = async () => {
        return new Promise(async (resolve, reject) => {
            await _fetchMembers();
            await _fetchMessagesAndEvents();

            resolve();
        });
    }

    useEffect(async () => {
        if(!_initialStateReady){
            if(_members?.length && _lastMessageFetchState.createdOn != null && _lastEventFetchState.createdOn != null){

                let c = 0;

                do {
                    c++;
                    await new Promise(r => setTimeout(r, 250));
                } while(_initialCreatedOnUsers == null && c < 40);

                _setFetchingLatestEnabled(true);

                let initialTransientEventDateTime = +new Date(_lastEventFetchState.latest) > +new Date(_initialCreatedOnUsers) ? _initialCreatedOnUsers : _lastEventFetchState.latest;

                if(_lastTransientEventFetchState.createdOn == null){
                    await _fetchEvents(null, initialTransientEventDateTime);
                }

                __setChatFeed();
                _setLongPollingActive(true); // < todo

                c = 0;

                do {
                    if(_chatContext.viewport){
                        _chatContext?.scrollToBottom();
                    }
                    await new Promise(r => setTimeout(r, (eventPollingFrequencyMilliSeconds / 3)));
                    c++;
                } while (
                    c < 30
                    &&
                    (
                    !_chatContext.viewport 
                    || !((_chatContext.viewport?.current?.scrollTop + _chatContext.viewport?.current?.clientHeight) >= _chatContext.viewport?.current?.scrollHeight - 10)
                    )
                );

                _setInitialStateReady(true);
            }
        } else {
            __setChatFeed();
        }
    }, [_members, _feedEvents, _messages, _lastEventFetchState]);

    useEffect(async () => {
        await _performLongPoll()
        .then(async () => {
            await new Promise(r => setTimeout(r, eventPollingFrequencyMilliSeconds));
            _setlastLongPollRoundtripCompleted(new Date(Date.now()));
        }).catch(async (e) => {
            console.error(e);
            await new Promise(r => setTimeout(r, eventPollingFrequencyMilliSeconds));
            _setlastLongPollRoundtripCompleted(new Date(Date.now()));
        })
    }, [_lastLongPollRoundtripCompleted])

    useEffect(async () => {
        // let eventPollingSetIntervalId = null;

        await _fetchInitialState()
        .then(() => {
            // eventPollingSetIntervalId = setInterval(async () => { await _performLongPoll(); }, eventPollingFrequencyMilliSeconds);
        }).catch(async (e) => {
            Logger.LogEvent(13, e);
        })

        // if(eventPollingSetIntervalId != null){
        //     return () => clearInterval(eventPollingSetIntervalId);
        // }
    }, []);

    // useEffect(async () => {
    //     if(!_longPollingActive && _initialStateReady && _chatContextReady){
    //         _setLongPollingActive(true);
    //         // _startLongPolling();
    //     }
    // }, [_initialStateReady, _chatContext]);

    // const sessionStaleAfterSeconds = 10; // todo

    const state = {
        initialStateReady: _initialStateReady,
        chatContextReady: _chatContextReady,
        longPollingActive: _longPollingActive,
        sessionIsStale: false, // todo
        lastMessageSentSuccessfully: _lastMessageSentSuccessfully,
        inputOverlayShown: _inputOverlayShown,
        fetchingLatestEnabled: _fetchingLatestEnabled,
        eventPollingFrequencyMilliSeconds: eventPollingFrequencyMilliSeconds,

        suspendFetchingLatestMessages: _suspendFetchingLatestMessages,
        setSuspendFetchingLatestMessages: _setSuspendFetchingLatestMessages,

        members: _members ?? [],
        chatFeed : _chatFeed ?? [],

        // messages: _messages ?? [],
        // events: _events ?? [],

        setChatContext: (viewport, scrollToBottom, scrollToTop, scrollToHalfway, scrollPosition) => {
            _setChatContext({
                viewport: viewport,
                scrollToBottom: scrollToBottom,
                scrollToTop: scrollToTop,
                scrollToHalfway: scrollToHalfway,
                scrollPosition: scrollPosition,
            });

            _setChatContextReady(true);
        },

        banMember: _banMember,
        timeoutMember: _timeoutMember,
        deleteMessage: _deleteMessage,
        addMessage: _addMessage,
        reportMessage: _reportMessage,
        spoilerHideMessage: _spoilerHideMessage
    };

    return state;
}