const { useContext } = require("react");
const { Segment, DynamicHeightSegment, Content } = require("../Common/Content");
const { AppContext } = require("../Common/Wrappers/AppWrapper");
const { Card, Grid, createStyles, Center, Group, Code } = require("@mantine/core");

const useTechSegmentStyles = createStyles((theme) => ({
    cardTopLevel: {
        height: "100%",
        maxHeight: "100%",
        paddingRight: "0px!important",
        paddingLeft: "0px!important",
        paddingTop: "0rem!important", // > 1rem default
        paddingBottom: "0rem!important", // > "
        // borderTop: "0.1px solid white",
        // borderLeft: "0.1px"
        // border: "0.1px solid white",
        WebkitBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        MozBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        boxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);"
    },

    affixTopLevel: {
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        paddingTop: "0px!important",
        paddingBottom: "0px!important",
        paddingLeft: "calc(1rem/4)",
        paddingRight: "calc(1rem/4)",
    },

    gridTopLevel: {
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0
    },

    column: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

function TechSegment(){
    const { classes } = useTechSegmentStyles();
    const { isMobile } = useContext(AppContext);

    return (
        <Segment
            id="tech-segment-wrapper"
            classes={isMobile? " " : null}
        >
            <DynamicHeightSegment
                preludeElements={["navbar"]}
                id="tech-segment"
                classes={isMobile ? " " : "mt-3 mb-3"}
                style={{
                    maxWidth: "100%",
                    width: "100%"
                }}
            >
                <Card
                    id="tech-segment-main-wrapper"
                    className={classes.cardTopLevel}
                >
                    <Grid
                        id="tech-segment-main"
                        columns={24}

                        grow={true} // < width maximizer
                        className={`${classes.affixTopLevel} ${classes.gridTopLevel}`} // < height maximizer
                    >
                        <Grid.Col
                            id="tech-segment-main-menu"
                            span={5}

                            className={`${classes.affixTopLevel} ${classes.column}`}
                        >
                            <div
                                className={`${classes.affixTopLevel} ${classes.column}`}

                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    borderRight: "1px solid #8080803d"
                                }}
                            >
                                <div
                                    style={{
                                        height: "1.8em",
                                        borderBottom: "1px solid #8080803d"
                                    }}
                                >
                                    <Group position="apart">
                                        
                                    </Group>
                                </div>
                            </div>
                        </Grid.Col>
                        <Grid.Col
                            id="tech-segment-main-content"
                            span={19}

                        >

                        </Grid.Col>
                    </Grid>
                </Card>
            </DynamicHeightSegment>
        </Segment>
    );
}

function Tech(){
    const { isMobile } = useContext(AppContext);

    return (
        <Content
            id="tech-page"
            style={{
                maxWidth: "96.5rem", 
                width: "100%",
                paddingLeft: isMobile ? 0 : undefined,
                paddingRight: isMobile ? 0 : undefined
            }}
        >
            <TechSegment/>
        </Content>
    )
}

export default Tech;