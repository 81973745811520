// Before any of this code is made public to be used - the implications should be considered
// In any case, this FE code & BE code can be repurposed if the decision to launch is negative - todo

import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { Content, DynamicHeightSegment, Segment } from "../Common/Content";
import { AppContext } from "../Common/Wrappers/AppWrapper";
import { ActionIcon, Badge, Box, Button, Card, Center, Grid, Group, Indicator, LoadingOverlay, Modal, NavLink, ScrollArea, Stack, Tabs, Text, Tooltip, createStyles } from "@mantine/core";
import { AccessPoint, AlertTriangle, ArrowBackUp, ArrowBearLeft2, ArrowBearRight2, ArrowBounce, ArrowForwardUp, Ban, ChevronDown, ChevronsLeft, ChevronsRight, Clock, ClockHour3, HourglassHigh, Lock, Network, NetworkOff, Planet, RotateClockwise2, Spiral, SpiralOff, StatusChange, Tools, TopologyRing2 } from "tabler-icons-react";
import { IconAlertTriangleFilled, IconDatabaseSearch, IconExclamationCircle, IconHash, IconLogin2, IconSend, IconUserScan, IconX } from "@tabler/icons-react";
import { Logger } from "../Common/Shared/Logger";
import { ChatContext, ChatWrapper, useChatManager } from "../Hooks/useChatManager";
import { MusicPlayModule } from "../Modules/MusicPlayModule";
import { useEditor } from "@tiptap/react";
import { RichTextEditor, Link } from "@mantine/tiptap";
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";

import { common, createLowlight } from "lowlight";
import {ChatMessage} from "../Modules/ChatMessage";
import { useDebouncedState, useDebouncedValue, useDisclosure, useHotkeys, useLocalStorage } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { AuthContext } from "../Common/Wrappers/AuthWrapper";
import Placeholder from "@tiptap/extension-placeholder";
import { ChatEvent } from "../Modules/ChatEvent";
import { notifications } from "@mantine/notifications";
// import { lowlight } from "lowlight/lib/core";
const lowlight = createLowlight(common);
// import { lowlight } from "lowlight";
// lowlight.registerLanguage("js", highlight_js);

const DiscussContext = createContext();

// ---- Desktop
// ~ A lot of styling to get something simple, adopted styling from library & using styling props outside of the scope of createStyles() definition is not likeable
// ~- todo cleanup the !important use to the bare minimum
// - Common
const useDesktopCommonStyles = createStyles((theme) => ({
    affixTopLevel: {
        height: "100%",
        maxHeight: "100%",
        width: "100%",
        maxWidth: "100%",
        paddingTop: "0px!important",
        paddingBottom: "0px!important",
        paddingLeft: "calc(1rem/4)",
        paddingRight: "calc(1rem/4)",
    },
    cardTopLevel: {
        height: "100%",
        maxHeight: "100%",
        paddingRight: "0px!important",
        paddingLeft: "0px!important",
        paddingTop: "0rem!important", // > 1rem default
        paddingBottom: "0rem!important", // > "
        // borderTop: "0.1px solid white",
        // borderLeft: "0.1px"
        // border: "0.1px solid white",
        WebkitBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        MozBoxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);",
        boxShadow: "0px 0px 1px 1px rgba(255,255,255,0.49);"
    },
    gridTopLevel: {
        margin: 0,
        paddingRight: 0,
        paddingLeft: 0
    },

    column: {
        height: "100%",
        maxHeight: "100%",
    },
    columnHeader: {
        height: "1.6rem",
        width: "100%",
        backgroundColor: "transparent",
        // backgroundColor: theme.colors.dark[6], // < bg layer
        borderBottom: "0.2px solid grey",
        opacity: 0.9
    },
    columnHeaderOpaqueBackground: {
        width: "inherit",
        height: "1.6rem",
        backgroundColor: theme.colors.dark[9],
        position: "absolute",
        opacity: 0.4,
    },
    columnHeaderTitle: {
        // not included: position style prop -> added inline to not convolute the stylesheet
        fontSize: "0.875rem",
        lineHeight: 1.55,
        zIndex: 1000,
        position: "absolute",
        top: 3
    },
    columnContent: {
        height: "calc(100% - 1.6rem)",
        maxHeight: "100%",
        width: "100%",
        // for each descendant element in div to which this class was appointed to, id is reserved so it is class based
        // "*": {
        //     width: "100%",
        // },
        bottom: 0,
        position: "absolute",
    },
    // todo could refactor to one css class:
    columnContentHiddenLeft: {
        background: `repeating-linear-gradient( 135deg, #fff, #fff 10px, #1a1b1e 10px, #1a1b1e 20px )`,
        opacity: "0.01",

        // hides descendant elements
        "*": {
            height: "100%",
            // display: "none!important",
        },
    },
    columnContentHiddenRight: {
        background: `repeating-linear-gradient( 225deg, #fff, #fff 10px, #1a1b1e 10px, #1a1b1e 20px )`,
        opacity: "0.01",

        // hides descendant elements
        "*": {
            height: "100%",
            // display: "none!important",
        },
    },

    columnToggleWrapperLeft: {
        top: -1,
        right: -7,
        position: "absolute",
    },
    columnToggleWrapperRight: {
        top: -1,
        left: -7,
        position: "absolute",
    },
}));

// - Left sidebar
const useDesktopLeftSidebarStyles = createStyles((theme) => ({
    column: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    card : {
        backgroundColor: theme.colors.dark[6], // <> overriden inline
        borderRadius : 0,
        padding: "0px!important",
    },
}));

// - Right sidebar
const useDesktopRightSidebarStyles = createStyles((theme) => ({
    column: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    card : {
        backgroundColor: theme.colors.dark[6],
        borderRadius : 0,
        padding: "0px!important",
    }
}));

// - Middle console 
const useDesktopMiddleConsoleStyles = createStyles((theme) => ({
    column : {
        padding: "0px!important",
    },
    card : {
        // backgroundColor: theme.colors.gray[8],
        backgroundColor: theme.colors.dark[5],
        borderRadius : 0,
        padding: "0px!important",
    },

    verticalMirror : {
        WebkitTransform : "matrix(-1, 0, 0, 1, 0, 0)",
        MozTransform : "matrix(-1, 0, 0, 1, 0, 0)",
        OTransform : "matrix(-1, 0, 0, 1, 0, 0)",
        transform : "matrix(-1, 0, 0, 1, 0, 0)"
    },

    
    interactable: {
        // backgroundColor: theme.colors.dark[4],
        // borderRadius: "10em",
        // paddingRight: "0.5em",
        minHeight: "5%",
        
        // maxHeight: "50%",
        position: "relative",
        display: "contents", // < todo test

        // width: "95%",
        width: "100%",
        // marginLeft: "2.5%",
        // marginBottom: "0.5em",
        bottom: 0,
    },
    interactableButton : {
        position: "absolute",
        // right: "0.8em",
        right: "0.6em",
        // bottom: "0.7em",
        bottom: "0.4em",
        zIndex: 2000,
        "&:hover": {
            backgroundColor: "#5a5c60",
        }
    },
    interactableToggleButton : {
        position: "absolute",
        right: "3.2em",
        bottom: "0.4em",
        zIndex: 2000,
        "&:hover": {
            backgroundColor: "#5a5c60"
        }
    },
    notifyingMessageTooLong : {
        position: "absolute",
        right: "5.8em",
        bottom: "0.4em",
        zIndex: 2000,
        color: "#dd1b1be5"

    },

    // rte
    rte: {
        borderRadius: "2em",
        border: 0,
    },
        toolbar: {
            // minHeight: "6.56875em",
            // backgroundColor: theme.colors.dark[6],
            backgroundColor: "#2C2E33",
            // borderTop: "0.5px solid white",
            gap: "0.70em",
            justifyContent: "center",
            borderBottom: 0,
        },
            nativeControlsGroup: {

            },
            customControlsGroup: {

            },
        contentScrollArea: {
            borderRadius: "2em",
            // "*": {
            //     borderRadius: "2em",
            // }
        },
            content: {
                /** inline styling, classname not properly working */
            },
    messages: {
        // height: "-webkit-fill-available"
        height: "100%",
        display: "contents",
    },
        msgContentScrollArea: {
            
        }
}));


// todo mv to above music player, doesnt fit there and empty space there to put it 
// function DiscussMeshInfoV1() {
//     const tickEveryMilliSeconds = 1000;
//     const refetchEverySeconds = 7;
//     const [lastUpdatedSecondsAgo, setLastUpdatedSecondsAgo] = useState(0);
//     const updatedThisTick = lastUpdatedSecondsAgo === 0;

//     useEffect(() => {
//         const intervalId = setInterval(() => {
//             setLastUpdatedSecondsAgo((prev) => {
//                 if(prev >= refetchEverySeconds) {
//                     // todo fetch
//                     return 0;
//                 } else {
//                     return prev + 1;
//                 }
//             });
//         }, tickEveryMilliSeconds);

//         /*~*/ return () => clearInterval(intervalId);
//     }, []);

//     return (
//         <div>
//             <Tabs defaultValue="v644">
//                 <Tabs.List grow={true}>
//                     <Tabs.Tab value="_info" disabled={true}>Perpective</Tabs.Tab>
//                     <Tabs.Tab value="v643" icon={<ArrowBearLeft2 size="0.8rem" />}>Before v644</Tabs.Tab>
//                     <Tabs.Tab value="v644" icon={<ArrowBearRight2 size="0.8rem" />}>v644</Tabs.Tab>
//                 </Tabs.List>

//                 <Tabs.Panel value="v643" pt="xs" style={{paddingTop: 0}}>
//                     <div>
//                         <div
//                             style={{
//                                 zIndex: 2000,
//                                 height: "100%",
//                                 width: "100%",
//                                 backgroundColor: updatedThisTick ? "lightgreen" : "",
//                                 opacity: 0.02,
//                                 position: "absolute",
//                                 // paddingTop: "0.7em",
//                             }}
//                         />
//                         <div
//                             style={{
//                                 zIndex: 2001,
//                                 overflow: "hidden",
//                                 bottom: 0,
//                                 position: "relative",
//                                 // marginTop: "0.7em",
//                             }}
//                         >
//                             {/* <hr style={{marginTop: 0, marginBottom: "0.5em"}} /> */}
//                             <NavLink
//                                 label={"0.00% incycle verifiers before v644"}
//                                 icon={<ArrowBounce size={"1.2em"}/>}
//                                 style={{cursor:"auto", marginTop: "0.7em"}}
//                             />
//                             <NavLink
//                                 label={"0.00% block votes for height 00000000"}
//                                 icon={<TopologyRing2 size={"1.2em"}/>}
//                                 style={{cursor:"auto", zIndex: 2002}}
//                             />
//                             <NavLink
//                                 label={"Block hash o3745689v3567...v3789pnv34"}
//                                 icon={<div style={{width: "1.2em"}}/>}
//                                 style={{cursor:"auto", zIndex: 2002, opacity: 0.6}}
//                             />
//                             <NavLink
//                                 label={"0 days 0 hours 0 minutes since last block"}
//                                 icon={<RotateClockwise2 size={"1.2em"}/>}
//                                 style={{cursor:"auto"}}
//                             />
//                             <NavLink
//                                 label={"Block height 00000000"}
//                                 icon={<div style={{width: "1.2em"}}/>}
//                                 style={{cursor:"auto", zIndex: 2002, opacity: 0.6}}
//                             />
//                             <NavLink
//                                 label={`Last updated ${lastUpdatedSecondsAgo} ${lastUpdatedSecondsAgo == 1 ? "second" : "seconds"} ago`}
//                                 icon={<AccessPoint size={"1.2em"}/>}
//                                 style={{cursor:"auto", color: updatedThisTick ? "lightgreen" : ""}}
//                             />
//                         </div>
//                     </div>
//                 </Tabs.Panel>

//                 <Tabs.Panel value="v644" pt="xs" style={{paddingTop: 0}}>
//                     <div>
//                         <div
//                             style={{
//                                 zIndex: 2000,
//                                 height: "100%",
//                                 width: "100%",
//                                 backgroundColor: updatedThisTick ? "lightgreen" : "",
//                                 opacity: 0.02,
//                                 position: "absolute",
//                                 // paddingTop: "0.7em",
//                             }}
//                         />
//                         <div
//                             style={{
//                                 // marginTop: "0.7em",
//                                 zIndex: 2001,
//                                 overflow: "hidden",
//                                 bottom: 0,
//                                 position: "relative",
//                             }}
//                         >
//                             {/* <hr style={{marginTop: 0, marginBottom: "0.5em"}} /> */}
//                             <NavLink
//                                 label={"0.00% v644 incycle verifiers"}
//                                 icon={<StatusChange size={"1.2em"}/>}
//                                 style={{cursor:"auto", marginTop: "0.7em"}}
//                             />
//                             <NavLink
//                                 label={"0.00% block votes for height 00000000"}
//                                 icon={<TopologyRing2 size={"1.2em"}/>}
//                                 style={{cursor:"auto", zIndex: 2002}}
//                             />
//                             <NavLink
//                                 label={"Block hash o3745689v3567...v3789pnv34"}
//                                 icon={<div style={{width: "1.2em"}}/>}
//                                 style={{cursor:"auto", zIndex: 2002, opacity: 0.6}}
//                             />
//                             <NavLink
//                                 label={"0 days 0 hours 0 minutes since last block"}
//                                 icon={<RotateClockwise2 size={"1.2em"}/>}
//                                 style={{cursor:"auto"}}
//                             />
//                             <NavLink
//                                 label={"Block height 00000000"}
//                                 icon={<div style={{width: "1.2em"}}/>}
//                                 style={{cursor:"auto", zIndex: 2002, opacity: 0.6}}
//                             />
//                             <NavLink
//                                 label={`Last updated ${lastUpdatedSecondsAgo} ${lastUpdatedSecondsAgo == 1 ? "second" : "seconds"} ago`}
//                                 icon={<AccessPoint size={"1.2em"}/>}
//                                 style={{cursor:"auto", color: updatedThisTick ? "lightgreen" : ""}}
//                             />
//                         </div>
//                     </div>
//                 </Tabs.Panel>
//             </Tabs>
//         </div>
//     );

//     return (
//         <Segment 
//             id="discuss-mesh-info-v1" 
//             isLastSegment={true}
//             classes={"mt-1 mb-4"}
//         >
//             <Indicator
//                 position="bottom-center"
//                 color={updatedThisTick ? "#337b40" : "gray"}
//                 size={25}
//                 label={
//                     "\xa0\xa0" + `Last updated ${lastUpdatedSecondsAgo} ${lastUpdatedSecondsAgo == 1 ? "second" : "seconds"} ago` + "\xa0\xa0"
//                 }
//             >
//                 <Card 
//                     shadow="sm" 
//                     padding="lg" 
//                     // radius="xl" 
//                     withBorder={true}
//                     style={{
//                         borderColor: updatedThisTick ? "lightgreen" : "white",
//                         borderWidth: "0.5px",
//                         paddingTop: "0.9rem",
//                         paddingBottom: "0.9rem",
//                     }}
//                 >
//                     <Grid>
//                         <Grid.Col span={4}>
//                             <Text fz="md" style={{marginTop:"-0.5em"}}>
//                                 00.00% version 644 incycle verifiers
//                             </Text>
//                         </Grid.Col>
//                         <Grid.Col span={4}>
//                             <Center>
//                                 <Text fz="md"  style={{marginTop:"-0.5em"}}>
//                                     {/* apply some color to the percentage depending on amt (soft red, orange, yellow) */}
//                                     {/* 75% is not mentioned due to the presence of version percentage which would render it "moot" */}
//                                     <b>00.00% block votes for height 00000000</b>
//                                 </Text>
//                                 {/* <img
//                                     style={{
//                                         // height: "3em" --> not pos abs
//                                         position: "absolute",
//                                         height: "5em", // --> pos abs 
//                                         marginTop: "2.5em" // --> vert center pos abs 
//                                     }} 
//                                     src="https://cdn.7tv.app/emote/61f3c159356ca68083730732/4x.webp"
//                                 /> */}
//                             </Center>
//                         </Grid.Col>
//                         <Grid.Col span={4} style={{
//                             textAlign: "right"
//                         }}>
//                             <Text fz="md"  style={{marginTop:"-0.5em"}}>
//                                 {/* depending on the variant displayed, apply a different color , variants:  */}
//                                 0 days 0 hours 0 minutes since last block
//                                 {/* 0 hours 0 minutes since last block */}
//                                 {/* 0 minutes 0 seconds since last block */}
//                                 {/* 0 seconds since last block */}
//                             </Text>
//                         </Grid.Col>
//                     </Grid>
//                 </Card>
//             </Indicator>
            

            
//         </Segment>
//     )
// }

// Any chat module input is checked to not contain a bare private key before shipping it to the API
// Same principle will be applied in the backend - separate components but with intent to have similar or identical functionality
// Putting boolean checks in one component more than once or twice the component becomes convoluted quick, and the ternary operator 
// isn't particularly visible when you do it that way
function DiscussChatMobile({chatManager}){
    return (
        <Segment id="discuss-chat-mobile-segment">

        </Segment>
    )
}



// -- todo mv
// todo decrease title font size depending on width, ideally the screen should still be functionally when docked to a vertical half of the monitor (use window zoom to easily assert what the size should be), use the device monitor to determine
// ==> as it currently stands the toggle buttons overlap with the title, as a redundancy clicking the title could also toggle the sidebar width
// ==> micro optimisation but perhaps a small amt of text could be displayed when toggleStatus=false, the middle column title should be reserved for a description and if necessary [an amount] of interactable/icons
// ==> in terms of accessibility the entire Discuss page should have alt tags for icons, tooltips are more favorable, or both
function DiscussChatDesktopColumn({columnIdPart, title, position, toggleIcon=null, toggleStatus=true, children: dependant}) {
    const id = "discuss-chat-desktop-segment-" + (columnIdPart?.toLowerCase() ?? "") + "-column";
    const {classes : commonClasses} = useDesktopCommonStyles();
    const procPosition = position?.length ? position : "center";
    const procHiddenClass = !toggleStatus ? procPosition === "left" ? commonClasses.columnContentHiddenLeft : commonClasses.columnContentHiddenRight : "";
    const [procTitle, setProcTitle] = useState(title);

    const applyProcTitle = () => {
        setProcTitle(title.substring(0, Math.min(title?.length, 42)));
    }

    useEffect(() => {
        if((title?.length > 43) && procPosition === "center") {
            Logger.LogEvent(15, title);
        }

        applyProcTitle();
    }, []);

    useEffect(() => applyProcTitle(), [title]);

    return (
        <div 
            id={id}
            className={commonClasses.column}
        >
            <div
                id={id + "-header"}
                className={commonClasses.columnHeader}
                style={{
                    justifyContent: "space-between"
                }}
            >
                <div className={commonClasses.columnHeaderOpaqueBackground}/>
                <div 
                    className={commonClasses.columnHeaderTitle}
                    style={(() => {
                        switch(procPosition){
                            case "left":
                                return {
                                    left: 5
                                }
                            case "right":
                                return {
                                    right: 5
                                }
                            case "center":
                            default:
                                return {
                                    justifyContent: "center",
                                    textAlign: "center",
                                    width: "100%",
                                    maxWidth: "100%",
                                }
                        }
                    })()}
                >
                    {
                        toggleStatus ? procTitle : ""
                    }
                </div>
               {
                toggleIcon &&
                    // note: no case for center as it is untoggle-able 
                    <div className={procPosition === "left" ? commonClasses.columnToggleWrapperLeft : commonClasses.columnToggleWrapperRight}>
                        {
                            toggleIcon
                        }
                    </div>
               }
                
            </div>
            <div
                id={id + "-content"}
                className={`${commonClasses.columnContent} ${procHiddenClass}`}
            >
                {dependant}
            </div>
        </div>
        
    );
}

function DiscussChatDesktopLeftSidebar({span, toggle, showToggle, toggleStatus}){
    const {classes: commonClasses} = useDesktopCommonStyles();
    const {classes, theme } = useDesktopLeftSidebarStyles(); 

    const chatManager = useContext(ChatContext);
    
    return (
        <Grid.Col 
            id="discuss-chat-desktop-segment-left-sidebar"
            className={`${commonClasses.affixTopLevel} ${classes.column}`}
            span={span}
        >
            <Card
                id="discuss-chat-desktop-segment-left-sidebar-main"
                className={`${classes.card} ${commonClasses.affixTopLevel}`}
                style={{
                    backgroundColor: toggleStatus ? theme.colors.dark[6] : "transparent",
                }}
            >
                <DiscussChatDesktopColumn
                    columnIdPart={"channels"}
                    title={"Channels"}
                    position={"left"}
                    toggleIcon={
                        showToggle ? 
                        <ActionIcon onClick={() => toggle(!toggleStatus)}>
                            {
                                toggleStatus ?
                                    <ChevronsLeft size={"1em"}/>
                                : 
                                    <ChevronsRight size={"1em"}/>
                            }
                        </ActionIcon>
                        : null
                    }
                    toggleStatus={toggleStatus}
                >
                    <Stack w={"100%"} h={"100%"} justify="space-between">
                        <div
                            className="mt-1"
                        >
                            {
                                // chatManager.channels.map((channel, index, array) => 
                                //     <NavLink
                                //         label={channel.name}
                                //         icon={channel.icon}
                                //         variant="subtle"
                                //         active={chatManager.activeChannel?.identifier === channel.identifier}
                                //         onClick={() => chatManager.switchToChannel(channel)}
                                //     />
                                // )
                            }
                        </div>
                        {/* <DiscussMeshInfoV1/> */}
                    </Stack>
                </DiscussChatDesktopColumn>
            </Card>
        </Grid.Col>
    );
}


function InputEditorContent({id, trySendChatMessage, commonEditorExtensions, fetchingLatestEnabled, scrollToBottom}){
    const defaultInputFieldContent = "<p></p>";
    const { classes } = useDesktopMiddleConsoleStyles();

    const { isMobile } = useContext(AppContext);
    const authContext = useContext(AuthContext);
    // const { editor } = useContext(InputEditorContentContext);
    const editor = useEditor({
        extensions: commonEditorExtensions,
        content: "",
        // editable: interactionEnabled,
        editable: true, // < todo
        autofocus: false,
        injectCSS: false
    });

    const [writingControlsVisible, setWritingControlsVisible] = useLocalStorage({key: "writing-controls-visible", defaultValue: 0});
    const [messageContentIsTooLong, setMessageContentIsTooLong] = useState(false);

    const [unsentStoredMessage, setUnsentStoredMessage] = useLocalStorage({ key: "unsent-stored-message", defaultValue: "" });

    useHotkeys([
        ["mod+Enter", () => trySendChatMessage(editor)]
    ]); 

    useEffect(() => {
        // if(Math.random() < 0.03){
            // console.log("html",editor?.getHTML())

            let html = editor?.getHTML();
            
            html = html ?? defaultInputFieldContent;
            let encodedHTML = encodeURIComponent(html);
            let decodedUnsent = decodeURIComponent(unsentStoredMessage);

            if(html != defaultInputFieldContent && (decodedUnsent != html) && (decodedUnsent != defaultInputFieldContent)){
                setUnsentStoredMessage(encodedHTML)
            }

            if(encodedHTML.length > 37500){
                setMessageContentIsTooLong(true);
            } else if(messageContentIsTooLong){
                setMessageContentIsTooLong(false);
            }
        // }
    })

    return (
        <RichTextEditor 
            id="discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte"
            editor={editor}
            className={classes.rte}
        >
            {
                    (writingControlsVisible || !fetchingLatestEnabled) ?
            <Center>
                
                <RichTextEditor.Toolbar
                    id="discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte-toolbar"
                    className={classes.toolbar}
                    sticky={true} 
                    stickyOffset={60}
                    style={{
                        // display: writingControlsVisible ? "" : "none",
                        borderRadius: 0,
                        position: "initial",
                        // paddingTop: 0, // < todo test
                        paddingTop: "0.5em", // < this is only relevant if the messages content should be allowed to be visible "behind" the toolbar, the fit-content allows for this to happen except behind the toolbar buttons itself
                        paddingBottom: "0.5em",
                        width: "fit-content", // < "

                        borderTopLeftRadius: "0.5em", // < adjacent to paddingtop 0.5em
                        borderTopRightRadius: "0.5em",
                    }}
                >
                    <div
                        style={{
                            display:"flex",
                            flexDirection: "column",
                            // flexGrow: true
                        }}
                    >
                        {
                            (!fetchingLatestEnabled) &&
                            <div
                                onClick={() => {
                                    scrollToBottom();
                                }}
                            >
                                <Center>
                                <span>
                                    Scroll down to fetch the latest messages
                                </span>
                                <ChevronDown/>
                                </Center>
                                {
                                    (writingControlsVisible) ?
                                    <hr style={{padding:0, margin: "0.3em"}}/>
                                    : null
                                }
                            </div>
                        }
                        
                        { 
                        writingControlsVisible ?
                        <div style={{
                            display: writingControlsVisible ? "flex" : "none",
                            flexDirection: "row"
                        }}>
                            {/* <div> */}
                            {/* <RichTextEditor.ControlsGroup> */}
                                <RichTextEditor.Bold style={{width: "auto"}}/>
                                <RichTextEditor.Italic />
                                <RichTextEditor.Underline />
                                {
                                    !isMobile &&
                                    <><RichTextEditor.Strikethrough/></>
                                }
                                {/* <RichTextEditor.Strikethrough /> */}
                                <RichTextEditor.ClearFormatting />
                                {/* <RichTextEditor.Highlight /> */}
                                {
                                    !isMobile &&
                                    <><RichTextEditor.CodeBlock/></>
                                }
                                {/* <RichTextEditor.Code /> */}
                            {/* </RichTextEditor.ControlsGroup> */}

                            {
                                !isMobile &&
                                <>
                                {/* <RichTextEditor.ControlsGroup> */}
                                    <RichTextEditor.H3 />
                                    <RichTextEditor.H4 />
                                    {/* <RichTextEditor.H5 /> */}
                                    {/* <RichTextEditor.H6 /> */}
                                {/* </RichTextEditor.ControlsGroup> */}
                                </>
                            }
                            

                            {/* <RichTextEditor.ControlsGroup> */}
                                <RichTextEditor.Blockquote />
                                <RichTextEditor.Hr />
                                <RichTextEditor.BulletList />
                                {/* <RichTextEditor.OrderedList /> */}
                                {
                                    !isMobile && 
                                    <>
                                    <RichTextEditor.Subscript />
                                    <RichTextEditor.Superscript />
                                    </>
                                }
                            {/* </RichTextEditor.ControlsGroup> */}

                            {/* <RichTextEditor.ControlsGroup> */}
                                <RichTextEditor.Link />
                                <RichTextEditor.Unlink />
                            {/* </RichTextEditor.ControlsGroup> */}

                            {/* <RichTextEditor.ControlsGroup> */}
                                <RichTextEditor.Control
                                    onClick={() => editor?.chain().focus().undo().run()}
                                    disabled={!editor?.can().undo()}
                                    aria-label="Undo last change"
                                    title="Undo last change"
                                >
                                    <ArrowBackUp 
                                        size={"1.0em"}
                                    />
                                </RichTextEditor.Control>
                                <RichTextEditor.Control
                                    onClick={() => editor?.chain().focus().redo().run()}
                                    disabled={!editor?.can().redo()}
                                    aria-label="Redo last undo"
                                    title="Redo last undo"
                                >
                                    <ArrowBackUp 
                                        size={"1.0em"}
                                        className={classes.verticalMirror}
                                    />
                                </RichTextEditor.Control>
                            {/* </RichTextEditor.ControlsGroup> */}
                            {/* </div> */}
                        </div> : null
                        }
                    </div>
                </RichTextEditor.Toolbar>
                
            </Center>
            : null}

            
            <ScrollArea.Autosize
                mah={"15em"}
                className={classes.contentScrollArea}
                type="auto" 
                scrollbarSize={4}
                style={{
                    width: "95%",
                    marginLeft: "2.5%",
                    // marginBottom: "0.55em", // < todo here 
                    marginBottom: "0.55em",
                    // minHeight: "6.56875em",
                }}
                styles={(theme) => ({
                    root: {
                        borderRadius: "2em",
                    },
                    
                })}
            >
                {/* <Tooltip label="Message is too long and will not be accepted."> */}
                    {/* <ActionIcon
                        size="lg"
                        radius="xl"
                        variant="filled"
                        className={classes.notifyingMessageTooLong}
                        title="Message is too long and will not be accepted."
                    > */}
                    { messageContentIsTooLong &&
                    <div title="Message is too long and will not be accepted." style={{zIndex: 1000}}>
                        <IconExclamationCircle size={32} className={classes.notifyingMessageTooLong}/>
                    </div>
                    }
                    {/* </ActionIcon> */}
                {/* </Tooltip> */}
                {/* <Tooltip label="Toggle writing controls"> */}
                    <ActionIcon
                        size="lg"
                        radius="xl"
                        variant="filled"
                        className={classes.interactableToggleButton}
                        disabled={(messageContentIsTooLong || !authContext.Session.loggedIn)}
                        title="Toggle writing controls"

                        onClick={() => {
                            setWritingControlsVisible(writingControlsVisible ? 0 : 1);
                        }}
                    >
                        <Tools size={16}/>
                    </ActionIcon>
                {/* </Tooltip> */}
                {/* <Tooltip label="Send message (CTRL + Enter)"> */}
                    <ActionIcon // send chat message send icon
                        size="lg" 
                        radius="xl" 
                        variant="filled" 
                        className={classes.interactableButton}
                        disabled={(messageContentIsTooLong || !authContext.Session.loggedIn)}
                        title="Send message (CTRL + Enter)"

                        onClick={() => {
                            // console.log(editor, editor?.contentComponent?.editorContentRef?.current?.innerText);
                            
                            trySendChatMessage(editor);
                        }}
                    >
                        <IconSend size={16}/>
                    </ActionIcon>
                {/* </Tooltip> */}

                <div id="rte-content--1">
                    <RichTextEditor.Content
                        editor={editor}
                        disabled={!authContext.Session.loggedIn}
                        contentEditable={authContext.Session.loggedIn}
                        id={id}
                        // onUpdate={onUpdate}
                        autoFocus={authContext.Session.loggedIn ?? false}
                        style={{
                            backgroundColor: "#373A40",
                            borderRadius: "2em",
                            minHeight: "5%",
                            maxHeight: "50%",
                            width: "100%",
                            bottom: 0,
                        }}
                    />
                </div>
            </ScrollArea.Autosize>

        </RichTextEditor>
    )
}

function DiscussChatDesktopMiddleConsole({span}){
    const {classes: commonClasses} = useDesktopCommonStyles();
    const { classes } = useDesktopMiddleConsoleStyles();
    const inputRteId = "discuss-chat-desktop-segment-middle-console-main-interactable-rte-wrapper-rte-content";
    const defaultInputFieldContent = "<p></p>"; // < if signed out, placeholder is visible as long as p element does not contain html itself
    // const contentRteId = "rte-content-0";

    const chatManager = useContext(ChatContext);
    const { isMobile } = useContext(AppContext);
    const authContext = useContext(AuthContext);

    const [unsentStoredMessage, setUnsentStoredMessage] = useLocalStorage({ key: "unsent-stored-message", defaultValue: "" });
    const [lastSendAttempt, setLastSendAttempt] = useState(new Date(0));
    

    const _suspendFetchingLatestMessages = () => {
        if(!chatManager.suspendFetchingLatestMessages){
            chatManager.setSuspendFetchingLatestMessages(true);
            setTimeout(() => {
                chatManager.setSuspendFetchingLatestMessages(false);
            }, chatManager.eventPollingFrequencyMilliSeconds / 2);
        }
    }

    const _openSignInModal = () => {
        if(!authContext.Session.loggedIn){
            modals.openContextModal({
                modal: "NavbarSignInModal",
                centered: true,
                size: "xl",
                scrollAreaComponent: ScrollArea.Autosize,
                closeOnClickOutside: false,
                closeOnEscape: true,
                trapFocus: true,

                title: "Sign in",
                innerProps: {
                    authContext: authContext
                }
            });
        }
    }

    const trySendChatMessage = (editor) => {
        if(!authContext.Session.loggedIn) {
            return;
        }

        let html = editor?.getHTML();

        // console.log("html",html);

        if(html){
            if(html != defaultInputFieldContent){
                let encoded = encodeURIComponent(html);

                setLastSendAttempt(new Date(Date.now()));
                chatManager.fn.addMessage(encoded);
            } else {
                // It's possible to delete the ProseMirror div if you backspace too much in certain edge cases
                // It's reversible by user action
                // It's possible to detect with a per-render document.getbyid but that's too compute intensive
                // It's not desirable to restore by force but some solution will have to be found eventually
                notifications.show({
                    id: "msg-editor-fail",
                    color: "red",
                    title: "Failed to get message content",
                    message: `Undo your changes to fix the editor or refresh the browser window.`,
                    icon: <IconX/>,
                    autoClose: 30000,
                    withCloseButton: true
                });
            }
        }
    }

    useEffect(() => {
        if(lastSendAttempt < chatManager.lastMessageSentSuccessfully){
            let element = document.querySelector("#rte-content--1 > * .ProseMirror");
            
            if(element){
                element.innerHTML = defaultInputFieldContent;
                setUnsentStoredMessage(encodeURIComponent(defaultInputFieldContent));
            }
        }
    }, [chatManager.lastMessageSentSuccessfully]);

    // not authed, message sent did not show up in chatManager
    const [interactionEnabled, setInteractionEnabled] = useState(true);

    // This is a one-way component->variable subscription
    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

    // This controls the scroll position
    const viewport = useRef(null);
    const scrollToBottom = () => console.log("stbb") & viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: "smooth" });
    const scrollToTop = () => viewport.current.scrollTo({ top: 0, behavior: "smooth" });
    const scrollToHalfway = () => viewport.current.scrollTo({top: viewport.current.scrollHeight / 2, behavior: "smooth"});

    const commonEditorExtensions = [
        StarterKit.configure({
            highlight: false,
            // code: false, 
            codeBlock: false,
        }),

        Underline,
        Link,
        Superscript,
        SubScript,
        // Highlight,
        TextAlign.configure({ types: ['heading', 'paragraph'] }), 
        CodeBlockLowlight.configure({
            lowlight
        })
    ];

    // const editor = useEditor({
    //     extensions: commonEditorExtensions,
    //     content: "",
    //     // editable: interactionEnabled,
    //     editable: true, // < todo
    //     autofocus: false,
    //     injectCSS: false
    // });

    // the message content display "editor"
    // const editorView = useEditor({
    //     extensions: commonEditorExtensions,
    //     content: "",
    //     editable: true,
    //     autofocus: false,
    //     injectCSS: false
    // });

    // const getContentWrapperForId = (id) => {
    //     return 
    // }

    // all messages from other users 
    // const [messages, setMessages] = useState([

    // ]);

    // useEffect(() => {

    // }, [messages]);

    // -- todo rem -- adding content to message
    // -- something like this should be moved to the message component
    // restoring unsent user input 
    // useEffect(async () => {
    //     let viewMessageContent;

    //     while(true){
    //         viewMessageContent = getContentWrapperForId(0);

    //         if(viewMessageContent){
    //             break;
    //         }

    //         await new Promise(r => setTimeout(r, 50));
    //     }

    //     // userInputContent.style.left = 0;
    //     viewMessageContent.style.width = "100%";
    //     viewMessageContent.style.paddingBottom = "0px";
    //     viewMessageContent.setAttribute("spellcheck", "false");

    //     // this is changed manually, and editable:true is set when the useEditor hook is called
    //     // this due to the rendering differences when editable:false
    //     viewMessageContent.setAttribute("contenteditable", "false");

    //     if(view_temp_inputcontent?.length > 0){
    //         viewMessageContent.innerHTML = decodeURIComponent(view_temp_inputcontent);
    //     } else {
    //         viewMessageContent.innerHTML = "<p></p>";
    //     }
    // }, []); 
    // --

    // restoring unsent user input 
    // useEffect(async () => {
        // let userInputContent;

        // while(true){
        //     userInputContent = document.querySelector(`#rte-content--1 > * .tiptap`);

        //     if(userInputContent){
        //         break;
        //     }

        //     await new Promise(r => setTimeout(r, 50));
        // }

        // userInputContent.style.left = 0;
        // userInputContent.style.width = "95%";
        // userInputContent.setAttribute("spellcheck", "false");

        // if(stored_temp_inputcontent?.length > 0){
        //     userInputContent.innerHTML = decodeURIComponent(stored_temp_inputcontent);
        // } else {
        //     userInputContent.innerHTML = defaultInputFieldContent;
        // }
    // }, []); 

    const updateChatInputAttribs = () => {
        console.log("attribs")
        // let signedOutPlaceholder = "Sign in to start chatting";
        let chatInputElement = document.querySelector("#rte-content--1 > * .ProseMirror");

        if(chatInputElement){
            if(chatInputElement.style.width != "95%"){
                chatInputElement.style.width = "95%";
            }

            let currentlySpellChecking = chatInputElement.getAttribute("spellcheck") != "false";
            
            if(currentlySpellChecking){
                chatInputElement.setAttribute("spellcheck", "false");
            }

            let currentlyEditable = chatInputElement.getAttribute("contenteditable") == "true";

            if(authContext.Session.loggedIn){
                if(!currentlyEditable){
                    // This indicates the user just logged in
                    chatInputElement.setAttribute("contenteditable", "true");
                }

                let decodedUnsent = decodeURIComponent(unsentStoredMessage);

                if(unsentStoredMessage?.length && (decodedUnsent != defaultInputFieldContent)){
                    // This indicates the user had an unsent message
                    chatInputElement.innerHTML = decodedUnsent;
                }
            } else {
                if(currentlyEditable){
                    // This indicates the session expired or the user signed out while the page was open
                    // The default contenteditable=false on page load so this should not trigger on page open / during init
                    chatInputElement.setAttribute("contenteditable", "false");

                    // The default content is set, displaying the placeholder
                    // This value is ignored and the continually executing useEffect below ignores said value, making recovery possible when signing in again
                    chatInputElement.innerHTML = defaultInputFieldContent;
                }
            }
        }

        // let innerPlaceholderElement = document.querySelector("#rte-content--1 > * .ProseMirror > p");

        // if(innerPlaceholderElement){
        //     let currentlyPlaceholderEmpty = innerPlaceholderElement.getAttribute("data-placeholder") == "";

        //     if(authContext.Session.loggedIn){
        //         if(!currentlyPlaceholderEmpty){
        //             innerPlaceholderElement.setAttribute("data-placeholder", "");
        //         }
        //     } else {
        //         if(currentlyPlaceholderEmpty){
        //             innerPlaceholderElement.setAttribute("data-placeholder", signedOutPlaceholder);
        //         }
        //     }
        // }
    }

    useEffect(() => {
        updateChatInputAttribs();
    }, []);

    useEffect(() => {
        updateChatInputAttribs();
    }, [authContext.Session])

    // todo
    // const getInputRteContent = () => {
    //     console.log(editor.getHTML());

    //     // // all user input content is stored in a p element within the following content element
    //     // let contentElement = document.querySelector(`#${inputRteId} > .tiptap`);

    //     // // and should be stored as such,
    //     // console.log(contentElement.innerHTML);

    //     // for(const e of contentElement.children){
    //     //     e.innerText = e.innerText.replace(" ", "&nbsp;");
    //     // }

    //     // console.log(contentElement.innerHTML);
    //     // console.log(editor);

    //     // ----

    //     // const replaceSpaces = (element) => {
    //     //     if ((element.nodeType === Node.TEXT_NODE) || (element.nodeType === Node.ELEMENT_NODE)) {
    //     //         element.textContent = element.textContent.replace(" ", "&nbsp;");
    //     //     }
            
    //     //     if (element.childNodes && element.childNodes.length > 0) {
    //     //         for (let i = 0; i < element.childNodes.length; i++) {
    //     //             replaceSpaces(element.childNodes[i]);
    //     //         }
    //     //     }
    //     // }
        
    //     // let ptag = document.querySelector(`#${inputRteId} > .tiptap`);

    //     // console.log("ptag", ptag);
    //     // console.log("ptag", ptag.innerHTML);
    //     // console.log("ptag", ptag.textContent);
    //     // console.log("ptag", ptag.innerText);

    //     // let enc = encodeURI(ptag.innerHTML);
    //     // let dec = decodeURI(enc);

    //     // console.log("ptag", enc);
    //     // console.log("ptag", dec);

    //     // ptag.innerHTML = dec;

    //     // ----

    //     // replaceSpaces(ptag)

        
    //     // console.log("ptag2", ptag);
    //     // console.log("ptag2", ptag.innerHTML);
    //     // console.log("ptag2", ptag.textContent);
    //     // console.log("ptag2", ptag.innerText);

    //     // return editor.getHTML();
    
    //     // ----

    //     console.log("editor:",editor);
    // }

    // const temp_msgs = [{
    //     Id: 1,
    //     PublicIdentifierHexString: "",
    //     PublicIdentifierNyzoString: "",
    //     CreatedOn: Date.now,
    //     LastUpdatedOn: Date.now,

    //     ParentMessageId: null,
    //     IsReply: false,
    //     IsSpoilerHidden: false,
    //     IsSoftDeleted: false,
    //     IsDeleted: false,

    //     SourceContentSha256HexString: "",
    //     Content: ""
    // }]

    useEffect(() => {
        chatManager.setChatContext(viewport, scrollToBottom, scrollToTop, scrollToHalfway, scrollPosition);
    }, []);

    return (
        <Grid.Col
            id="discuss-chat-desktop-segment-middle-console"
            className={`${commonClasses.affixTopLevel} ${classes.column}`}
            span={span}
        >
            {
                // console.log(chatManager.chatFeed)
            }
            <Card
                id="discuss-chat-desktop-segment-middle-console-main"
                className={`${classes.card} ${commonClasses.affixTopLevel}`}
            >
                <DiscussChatDesktopColumn
                    // title={chatManager.activeChannel?.desc ?? "Select a channel"}
                    title={"Your vote matters"}
                    columnIdPart={"middle"}
                    position={"center"}
                >
                    {/* <Stack 
                        w={"100%"}
                        h={"100%"} 
                        justify="space-between"

                        style={{
                            gap: "0.5rem" // < 1 rem original , test todo 
                        }}
                    > */}
                        <div 
                            id="discuss-chat-desktop-segment-middle-console-main-messages"
                            className={classes.messages}
                        >
                            {/* scrollable messages map, scrollbar pos tracking <> auto load & unload */}
                            {
                                /**
                                 {
                                        (isTopMostMessage || isFirstMessage) && 
                                            <div>
                                                <div style={{
                                                    width: "100%",
                                                    height: "3.5em",
                                                    position: "initial",
                                                    backgroundColor: "#2c2e33",
                                                }}>
                                                    <Center 
                                                        className="mt-3 mb-3"
                                                        style={{
                                                            
                                                        }}
                                                    >
                                                        <Text weight={400}>
                                                            {
                                                                isFirstMessage ? "You have reached the very first message!" : "Loading.."
                                                            }
                                                        </Text>
                                                    </Center>
                                                </div>
                                                
                                            </div>
                                    }
                                 */
                            }
                            <div
                                style={{
                                    // overflow: "scroll",
                                    maxHeight: "85%",
                                    width: "95%",
                                    marginLeft: "2.5%",
                                    overflowX: "hidden",
                                    overflowY: "scroll",
                                    scrollbarWidth: "thin",
                                    scrollbarGutter: "stable",
                                    scrollbarColor: "darkgrey",
                                    paddingRight: "0.5em"
                                }}
                                ref={viewport}
                                onScroll={() => _suspendFetchingLatestMessages()}
                            >
                                {/* <div
                                    style={{
                                        position: "absolute",
                                        right: "3%",
                                        bottom: 0,
                                        marginBottom: "4.5em",
                                        backgroundColor: "#242222",
                                        padding: "0.45em",
                                        borderRadius: "1em"
                                    }}

                                    onClick={() => {
                                        scrollToBottom();
                                        
                                    }}
                                >
                                    <span>
                                        Scroll down to actively fetch the latest messages
                                    </span>
                                    <ChevronDown/>
                                </div> */}
                            
                            {/* <ScrollArea.Autosize
                                // mah={"15em"}
                                onScrollPositionChange={onScrollPositionChange}
                                viewportRef={viewport}
                                // onChange={}
                                className={classes.msgContentScrollArea}
                                type="always"
                                scrollbarSize={4}
                                offsetScrollbars={true}
                                style={{
                                    maxHeight: "85%",
                                    // maxHeight: "87.5%",
                                    
                                    // :
                                    width: "95%",
                                    marginLeft: "2.5%",

                                    // marginTop: "0.4em",
                                    // marginBottom: "0.55em",

                                    // minHeight: "6.56875em",
                                }}
                                styles={(theme) => ({
                                    root: {
                                        // borderRadius: "2em",
                                    },
                                    viewport:{
                                        width: "99.5%"
                                    }
                                })}
                            > */}
                                <div className="pb-3"></div>
                                {
                                    chatManager.chatFeed.map((feedItem, index, array) => {
                                        let isMessage = feedItem.type == "Message";
                                        let isEvent = !isMessage;

                                        let isFirstItemInFeed = index === 0;
                                        let isLastItemInFeed = index === (array.length - 1);

                                        if(isMessage){
                                            let isFirstMessage = feedItem.id === 1;

                                            let previousItemIsEvent = !isFirstItemInFeed && array[index - 1].type != "Message";
                                            let nextItemIsEvent = !isLastItemInFeed && array[index + 1].type != "Message";

                                            return (
                                                <div key={index}>
                                                    {
                                                        isFirstItemInFeed &&
                                                        <div>
                                                            <div style={{
                                                                width: "100%",
                                                                height: "2.5em",
                                                                position: "initial",
                                                                backgroundColor: "#2c2e33",
                                                            }}>
                                                                <Center 
                                                                    className="mb-3"
                                                                    style={{
                                                                        
                                                                    }}
                                                                >
                                                                    <Text weight={400}>
                                                                        { // todo
                                                                            isFirstMessage ? "You have reached the very first message!" : viewport?.current?.scrollTop == 0 ? "Loading.." : "Scroll up to load more messages."
                                                                        }
                                                                    </Text>
                                                                </Center>
                                                            </div>
                                                        </div>
                                                    }
                                                    <ChatMessage
                                                        key={"feed-msg-" + feedItem.id}
                                                        message={feedItem}
                                                        editorExtensions={commonEditorExtensions}
                                                        isFirstMessage={isFirstMessage}
                                                        isTopMostMessage={isFirstItemInFeed}
                                                        isLastItemInFeed={isLastItemInFeed}
                                                        addTopMargin={previousItemIsEvent}
                                                        addBottomMargin={nextItemIsEvent}
                                                        commonClasses={commonClasses}
                                                        className={"mb-2"} // < todo check
                                                        chatManager={chatManager}
                                                        authContext={authContext}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            let isFirstEvent = feedItem.id === 1; // < todo check

                                            return (
                                                <div key={index}>
                                                    <ChatEvent
                                                        isFirstItemInFeed={isFirstItemInFeed}
                                                        key={"feed-event-"+ feedItem.id}
                                                        id={index}
                                                        event={feedItem}
                                                    />
                                                </div>
                                            );
                                        }
                                    })
                                }
                            {/* </ScrollArea.Autosize> */}
                            </div>
                        </div>

                        {
                            true &&
                        <div 
                            id="discuss-chat-desktop-segment-middle-console-main-interactable"
                            className={classes.interactable}
                        >
                            <Grid style={{
                                margin: 0,
                                
                                // position: "relative",
                                position: "absolute",
                                // position: "sticky",
                                maxHeight: "fit-content",

                                bottom: 0,
                                width: "100%",

                                zIndex: 100,
                            }}>
                                <Grid.Col span={12} w={"100%"} h={"100%"} style={{
                                    padding: 0,
                                }}>
                                    {/*  */}

                                    {
                                    (   !authContext.Session.loggedIn 
                                        || authContext.Interaction.cooldownActive
                                        || authContext.Interaction.timeoutActive
                                        || authContext.Interaction.bannedActive
                                    ) &&
                                    <div
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            bottom: 0,
                                            zIndex: 9001,
                                            display: "flex",
                                            WebkitAlignItems: "center",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            overflow: "hidden",
                                        }}

                                        onClick={() => _openSignInModal()}
                                    >
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                left: 0,
                                                bottom: 0,
                                                zIndex: 9001,
                                                backgroundColor: "rgba(44, 46, 51, 0.75)",
                                                borderRadius: 0,
                                            }}
                                            // onClick={() => _openSignInModal()}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                width: "auto",
                                                flexGrow: true,
                                                zIndex: 9002,
                                                marginBottom: "0.35em"
                                            }}
                                            // onClick={() => _openSignInModal()}
                                        >
                                            <div
                                                style={{
                                                    height: "100%",
                                                    marginRight: "0.5em",
                                                    // marginTop: "0.7em"
                                                }}
                                                // onClick={() => _openSignInModal()}
                                            >
                                                {
                                                    !authContext.Session.loggedIn 
                                                    ?
                                                    <div><IconLogin2/></div>
                                                    :
                                                    authContext.Interaction.bannedActive 
                                                    ?
                                                    <div><Ban/></div>
                                                    :
                                                    authContext.Interaction.timeoutActive
                                                    ?
                                                    <div><ClockHour3/></div>
                                                    :
                                                    authContext.Interaction.cooldownActive
                                                    ?
                                                    <div><HourglassHigh/></div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div
                                                style={{
                                                    // width: "100%",
                                                }}
                                                // onClick={() => _openSignInModal()}
                                            >
                                                {
                                                    !authContext.Session.loggedIn 
                                                    ?
                                                    <div><span>Sign in to start chatting</span></div>
                                                    :
                                                    authContext.Interaction.bannedActive 
                                                    ?
                                                    <div><span>You are currently banned.<br style={{display: isMobile ? "" : "none"}}/> Expires on {authContext.Interaction.bannedUntil.toLocaleString()}</span></div>
                                                    :
                                                    authContext.Interaction.timeoutActive
                                                    ?
                                                    <div><span>You are currently timed out.<br style={{display: isMobile ? "" : "none"}}/> Expires on {authContext.Interaction.timeoutUntil.toLocaleString()}</span></div>
                                                    :
                                                    authContext.Interaction.cooldownActive
                                                    ?
                                                    <div><span>You are currently on a cooldown.<br style={{display: isMobile ? "" : "none"}}/> Expires on {authContext.Interaction.cooldownUntil.toLocaleString()}</span></div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    <LoadingOverlay visible={chatManager.inputOverlayShown} overlayBlur={0} zIndex={9000}/>
                                    {/* <InputEditorContentContext.Provider value={{
                                        editor: useEditor({
                                            extensions: commonEditorExtensions,
                                            content: "",
                                            // editable: interactionEnabled,
                                            editable: true, // < todo
                                            autofocus: false,
                                            injectCSS: false
                                        }) // todo cont.
                                    }}> */}
                                        <InputEditorContent
                                            id={inputRteId}
                                            trySendChatMessage={trySendChatMessage}
                                            commonEditorExtensions={commonEditorExtensions}
                                            fetchingLatestEnabled={chatManager.fetchingLatestEnabled}
                                            scrollToBottom={scrollToBottom}
                                        />
                                    {/* </InputEditorContentContext.Provider> */}
                                </Grid.Col>
                            </Grid>
                        </div>
                        }
                    {/* </Stack> */}
                </DiscussChatDesktopColumn>
            </Card>
        </Grid.Col>
    );
}

// function MemberEntry({idx, member}){
//     const { id, createdOn, registeredOn, publicIdentifierHexString, publicIdentifierShortHexString, publicIdentifierNyzoString, nickname, nicknameIsUnique, hasMultipleNicknames, chatCooldownFrom, chatCooldownUntil, isTimedOutFromChat, isBannedFromChat, isInCycle, hasOneOrMoreNodes, isChatAdmin } = member;
//     const shortNickname = nickname.length > 12 ? nickname.substring(0,12) + ".." : nickname;
//     const showNickname = !(nickname == publicIdentifierShortHexString);
//     const openModal = () => {
//         modals.openContextModal({
//             modal: "DiscussMemberEntryModal",
//             centered: true,
//             size: "auto",

//             title: `Verifier ${member.publicIdentifierShortHexString}`,
//             innerProps: {
//                 member: member
//             }
//         })
//     }

//     return (
//         <>
//         <Group 
//             position="center"
//             spacing={"xs"}
//             grow={true}
//             style={{
//                 height: "2.5em",
//                 backgroundColor: ((idx + 1) % 2 == 0) ? "transparent" : "#8080800f",
//                 marginTop: "0.25em",
//                 marginBottom: "0.25em",
//             }}
//             onClick={openModal}
//         >
//             <Group
//                 position="left"
//                 spacing={"xs"}
//                 grow={false}
//                 style={{
//                     height: "inherit",
//                     marginLeft: "0.5em"
//                 }}
//             >
//                 {
//                     isChatAdmin && 
//                     <Text size={"sm"} align="right">
//                         <Tooltip label={"Chat moderator"}>
//                             <div>
//                                 <Planet size="1.1em" color="white"/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     !nicknameIsUnique && 
//                     <Text size={"sm"} align="right">
//                         <Tooltip label={"Other verifier(s) use the same nickname"}>
//                             <div>
//                                 <AlertTriangle size="1.1em" color={"#ff2825"}/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     hasMultipleNicknames && 
//                     <Text size={"sm"} align="right">
//                         <Tooltip label={"Verifier has multiple nicknames"}>
//                             <div>
//                                 <AlertTriangle size="1.1em" color={"#ffec00"}/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     isTimedOutFromChat && 
//                     <Text size={"sm"} align="right">
//                         <Tooltip label={"Verifier is timed out from chat"}>
//                             <div>
//                                 <Clock size="1.1em" color={"#ffec00"}/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     isBannedFromChat &&
//                     <Text size={"sm"} align="right">
//                         <Tooltip label="Verifier is banned from chat">
//                             <div>
//                                 <Ban size="1.1em" color={"#ff2825"}/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     // isInCycle && 
//                     // <Text size={"sm"} align="right">
//                     //     <Tooltip label="Verifier is in cycle">
//                     //         <Spiral size="1.1em" color="#7bc62d"/>
//                     //     </Tooltip>
//                     // </Text>
//                 }
//                 {
//                     // !isInCycle &&
//                     // <Text size={"sm"} align="right">
//                     //     <Tooltip label="Verifier is out of cycle">
//                     //         <SpiralOff size="1.1em"/>
//                     //     </Tooltip>
//                     // </Text>
//                 }
//                 {
//                     hasOneOrMoreNodes &&
//                     <Text size={"sm"} align="right">
//                         <Tooltip label="Verifier has atleast one active node">
//                             <div>
//                                 <Network size="1.1em" color="#7bc62d"/>
//                             </div>
//                         </Tooltip>
//                     </Text>
//                 }
//                 {
//                     !hasOneOrMoreNodes && 
//                     <Text size={"sm"} align="right">
//                         <Tooltip label="Verifier has no active node(s)">
//                                 <div>
//                                 <NetworkOff size="1.1em"/>
//                                 </div>
//                         </Tooltip>
//                     </Text>
//                 }
                
                
//             </Group>
//             <Group
//                 position="right"
//                 spacing={"xs"}
//                 grow={false}
//                 style={{
//                     height: "inherit",
//                     marginRight: "0.5em"
//                 }}
//                 onClick={openModal}
//             >
//                 <Text size={"xs"} style={{lineHeight: "1.35"}} className="discuss-sidemenu-member" align="right" color="#ffffffba">{showNickname ? <div>{shortNickname}<br/>{publicIdentifierShortHexString}</div> : <div>{publicIdentifierShortHexString}</div>}</Text>
//             </Group>
//         </Group>
//         </>
//     );
// }

const MemberEntryGroup = React.memo(function MemberEntryGroup({ title, members }){

    const openModal = (member) => {
        modals.openContextModal({
            modal: "DiscussMemberEntryModal",
            centered: true,
            size: "auto",
            closeOnClickOutside: true,
            closeOnEscape: true,
            trapFocus: false,

            title: `Verifier ${member.publicIdentifierShortHexString}`,
            innerProps: {
                member: member
            }
        })
    }

    return (<>
        <p style={{marginRight: "0.5em", marginBottom: "0.4em"}}>{`${title} (${members?.length ?? "0"})`}</p>
        {
            members.map((member, index, array) => {
                const shortNickname = member.nickname.length > 12 ? member.nickname.substring(0,12) + ".." : member.nickname;
                const showNickname = !(member.nickname == member.publicIdentifierShortHexString);

                return (
                    <div
                        key={index}
                        style={{
                            height: "2.5em",
                            backgroundColor: ((index + 1) % 2 == 0) ? "transparent" : "#8080800f",
                            marginTop: "0.25em",
                            marginBottom: "0.25em",
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: true,
                            alignItems: "center"
                        }}
                        onClick={() => openModal(member)}
                    >
                        <div style={{flex: "0", flexDirection: "row", display: "flex"}}>
                        {
                                member.isChatAdmin && 
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label={"Chat moderator"}> */}
                                        <div title="Chat moderator">
                                            <Planet size="1.1em" color="white"/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                !member.nicknameIsUnique && 
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label={"Other verifier(s) use the same nickname"}> */}
                                        <div title="Other verifier(s) use the same nickname">
                                            <AlertTriangle size="1.1em" color={"#ff2825"}/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                member.hasMultipleNicknames && 
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label={"Verifier has multiple nicknames"}> */}
                                        <div title="Verifier has multiple nicknames">
                                            <AlertTriangle size="1.1em" color={"#ffec00"}/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                member.isTimedOutFromChat && 
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label={"Verifier is timed out from chat"}> */}
                                        <div title="Verifier is timed out from chat">
                                            <Clock size="1.1em" color={"#ffec00"}/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                member.isBannedFromChat &&
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label="Verifier is banned from chat"> */}
                                        <div title="Verifier is banned from chat">
                                            <Ban size="1.1em" color={"#ff2825"}/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                // isInCycle && 
                                // <Text size={"sm"} align="right">
                                //     <Tooltip label="Verifier is in cycle">
                                //         <Spiral size="1.1em" color="#7bc62d"/>
                                //     </Tooltip>
                                // </Text>
                            }
                            {
                                // !isInCycle &&
                                // <Text size={"sm"} align="right">
                                //     <Tooltip label="Verifier is out of cycle">
                                //         <SpiralOff size="1.1em"/>
                                //     </Tooltip>
                                // </Text>
                            }
                            {
                                member.hasOneOrMoreNodes &&
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label="Verifier has atleast one active node"> */}
                                        <div title="Verifier has atleast one active node">
                                            <Network size="1.1em" color="#7bc62d"/>
                                        </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                            {
                                !member.hasOneOrMoreNodes && 
                                <span style={{margin: "0.5em", marginRight: "0em"}}>
                                    {/* <Tooltip label="Verifier has no active node(s)"> */}
                                            <div title="Verifier has no active node(s)">
                                            <NetworkOff size="1.1em"/>
                                            </div>
                                    {/* </Tooltip> */}
                                </span>
                            }
                        </div>
                        <div style={{flex: "1", marginRight: "0.5em"}}>
                            {/* <Text size={"xs"} style={{lineHeight: "1.35"}} className="discuss-sidemenu-member" align="right" color="#ffffffba">{showNickname ? <div>{shortNickname}<br/>{publicIdentifierShortHexString}</div> : <div>{publicIdentifierShortHexString}</div>}</Text> */}
                            <span
                                style={{
                                    marginRight: "0.25em",
                                    marginLeft: "0.25em",
                                    marginBottom: 0,
                                    marginTop: 0
                                }}
                            >{showNickname ? <div>{shortNickname}<br/>{member.publicIdentifierShortHexString}</div> : <div>{member.publicIdentifierShortHexString}</div>}</span>
                        </div>
                    </div>
                )
            })
        }
    </>
    );
})

function DiscussChatDesktopRightSidebar({span, toggle, showToggle, toggleStatus}){
    const {classes: commonClasses} = useDesktopCommonStyles();
    const {classes} = useDesktopRightSidebarStyles();
    // const [opened, { close, open }] = useDisclosure(false);
    // const [registeredOpen, setRegisteredOpen] = useState(true);
    // const [knownOpen, setKnownOpen] = useState(true);

    const chatManager = useContext(ChatContext);

    const [recentlyActiveInCycleMembers, setRecentlyActiveInCycleMembers] = useState([]);
    const [recentlyActiveNotInCycleMembers, setRecentlyActiveNotInCycleMembers] = useState([]);

    const [offlineInCycleMembers, setOfflineInCycleMembers] = useState([]);
    const [offlineNotInCycleMembers, setOfflineNotInCycleMembers] = useState([]);

    const [knownInCycleMembers, setKnownInCycleMembers] = useState([]);
    const [knownNotInCycleMembers, setKnownNotInCycleMembers] = useState([]);

    useEffect(() => {
        setRecentlyActiveInCycleMembers(chatManager.members.filter(x => x.isInCycle && x.recentlyActive && x.registeredOn));
        setRecentlyActiveNotInCycleMembers(chatManager.members.filter(x => !x.isInCycle && x.recentlyActive && x.registeredOn));

        setOfflineInCycleMembers(chatManager.members.filter(x => x.isInCycle && !x.recentlyActive && x.registeredOn));
        setOfflineNotInCycleMembers(chatManager.members.filter(x => !x.isInCycle && !x.recentlyActive && x.registeredOn));

        setKnownInCycleMembers(chatManager.members.filter(x => x.isInCycle && !x.recentlyActive && !x.registeredOn));
        setKnownNotInCycleMembers(chatManager.members.filter(x => !x.isInCycle && !x.recentlyActive && !x.registeredOn));
    }, [chatManager.members])

    return (
        <Grid.Col
            id="discuss-chat-desktop-segment-right-sidebar"
            className={`${commonClasses.affixTopLevel} ${classes.column}`}
            span={span}
        >
            <Card
                id="discuss-chat-desktop-segment-right-sidebar-main"
                className={`${classes.card} ${commonClasses.affixTopLevel}`}
            >
                <DiscussChatDesktopColumn
                    columnIdPart={"members"}
                    title={"Members"}
                    position={"right"}
                    toggleIcon={
                        showToggle ? 
                        <ActionIcon onClick={() => toggle(!toggleStatus)}>
                            {
                                toggleStatus ?
                                    <ChevronsRight size={"1em"}/>
                                : 
                                    <ChevronsLeft size={"1em"}/>
                            }
                        </ActionIcon>
                        : null
                    }
                    toggleStatus={toggleStatus}
                >
                    { toggleStatus && 
                    <Stack 
                        id="discuss-chat-desktop-segment-members-column-content-v1"
                        w={"100%"} h={"100%"} justify="space-between"
                    >
                        <div>
                            <Tabs defaultValue="members">
                                <Tabs.List grow={true}>
                                    <Tabs.Tab value="info">Info</Tabs.Tab>
                                    <Tabs.Tab value="members">Overview</Tabs.Tab>
                                </Tabs.List>

                                <Tabs.Panel value="members" pt={"0.55em"}>
                                    <div style={{
                                        height: "auto",
                                        display: "contents",
                                    }}>
                                    {
                                        !chatManager.members &&
                                        <div>
                                            <Center><p>Loading..</p></Center>
                                        </div>
                                    }
                                    {
                                        chatManager.members &&
                                    <ScrollArea.Autosize
                                        scrollbarSize={6}
                                        type="hover"
                                        offsetScrollbars={false}
                                        style={{
                                            // maxHeight: "100%",
                                            width: "100%",
                                            height: "100%",
                                            position: "absolute"
                                        }}
                                        styles={{
                                            scrollbar: {
                                                maxHeight: "85%",
                                                height: "85%",
                                            }
                                        }}
                                    >
                                        {/* Recently active */}
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Recently active ({(recentlyActiveInCycleMembers.length + recentlyActiveNotInCycleMembers.length)})</b></p>

                                                {/* {console.log("render")} */}
                                                <MemberEntryGroup
                                                    key="recently-active-in-cycle"
                                                    title="In cycle"
                                                    members={recentlyActiveInCycleMembers}
                                                />

                                                {/* <p style={{marginRight: "0.5em", marginBottom: "0.4em"}}>In cycle ({chatManager.members?.filter(x => x.isInCycle && x.recentlyActive)?.length ?? 0})</p> */}
                                                {/* {
                                                    chatManager.members?.filter(x => x.isInCycle && x.recentlyActive).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}

                                                <MemberEntryGroup
                                                    key="recently-active-not-in-cycle"
                                                    title="Not in cycle"
                                                    members={recentlyActiveNotInCycleMembers}
                                                />
                                                {/* <p style={{marginTop: "0.6em", marginRight: "0.5em", marginBottom: "0.4em"}}>Not in cycle ({chatManager.members?.filter(x => !x.isInCycle && x.recentlyActive)?.length ?? 0})</p> */}
                                                {/* {
                                                    chatManager.members?.filter(x => !x.isInCycle && x.recentlyActive).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    </Group>

                                        {/* Offline */}
                                    <hr style={{marginBottom: "0.5em"}}/>
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Offline ({offlineInCycleMembers.length + offlineNotInCycleMembers.length})</b></p>

                                                <MemberEntryGroup
                                                    key="offline-in-cycle"
                                                    title="In cycle"
                                                    members={offlineInCycleMembers}
                                                />
                                                {/* <p style={{marginRight: "0.5em", marginBottom: "0.4em"}}>In cycle ({chatManager.members?.filter(x => x.isInCycle && !x.recentlyActive && x.registeredOn)?.length ?? 0})</p>
                                                {
                                                    chatManager.members?.filter(x => x.isInCycle && !x.recentlyActive && x.registeredOn).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry key={index} idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}

                                                <MemberEntryGroup
                                                    key="offline-not-in-cycle"
                                                    title="Not in cycle"
                                                    members={offlineNotInCycleMembers}
                                                />
                                                {/* <p style={{marginTop: "0.6em", marginRight: "0.5em", marginBottom: "0.4em"}}>Not in cycle ({chatManager.members?.filter(x => !x.isInCycle && !x.recentlyActive && x.registeredOn)?.length ?? 0})</p>
                                                {
                                                    chatManager.members?.filter(x => !x.isInCycle && !x.recentlyActive && x.registeredOn).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry key={index} idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    </Group>

                                        {/* Known */}
                                    <hr style={{marginBottom: "0.5em"}}/>
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Known ({knownInCycleMembers.length + knownNotInCycleMembers.length})</b></p>

                                                <MemberEntryGroup
                                                    key="known-in-cycle"
                                                    title="In cycle"
                                                    members={knownInCycleMembers}
                                                />
                                                {/* <p style={{marginRight: "0.5em", marginBottom: "0.4em"}}>In cycle ({chatManager.members?.filter(x => x.isInCycle && !x.recentlyActive && !x.registeredOn)?.length ?? 0})</p>
                                                {
                                                    chatManager.members?.filter(x => x.isInCycle && !x.recentlyActive && !x.registeredOn).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry key={index} idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}

                                                <MemberEntryGroup
                                                    key="known-not-in-cycle"
                                                    title="Not in cycle"
                                                    members={knownNotInCycleMembers}
                                                />
                                                {/* <p style={{marginTop: "0.6em", marginRight: "0.5em", marginBottom: "0.4em"}}>Not in cycle ({chatManager.members?.filter(x => !x.isInCycle && !x.recentlyActive && !x.registeredOn)?.length ?? 0})</p>
                                                {
                                                    chatManager.members?.filter(x => !x.isInCycle && !x.recentlyActive && !x.registeredOn).map((member, index, array) => {
                                                        return (
                                                            <MemberEntry key={index} idx={index} member={member}/>
                                                        );
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    </Group>

                                        {/* Padding */}
                                    <div style={{width: "auto", height: "5em"}}></div>

                                    </ScrollArea.Autosize>
                                    }
                                    </div>
                                </Tabs.Panel>

                                <Tabs.Panel value="info" pt="xs">
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p>Hover over message or member icons to gain insight into their meaning.<br/>In and out of cycle status is as observed by a v643 node.</p>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Recently active</b></p>
                                                <p style={{marginLeft:"0.5em",marginRight: "0.5em"}}>Registered (verifier) public identifiers, they have meaningfully interacted with the platform in the last 3 hours.</p>
                                            </div>
                                        </div>
                                    </Group>

                                    <hr style={{marginBottom: "0.5em"}}/>
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Offline</b></p>
                                                <p style={{marginLeft:"0.5em",marginRight: "0.5em"}}>Registered (verifier) public identifiers, they have <b>not</b> meaningfully interacted with the platform in the last 3 hours.</p>
                                            </div>
                                        </div>
                                    </Group>

                                    <hr style={{marginBottom: "0.5em"}}/>
                                    <Group
                                        position="center"
                                        spacing={"xs"}
                                        grow={true}
                                        style={{
                                            fontSize: "0.9em",
                                            lineHeight: "1"
                                        }}
                                    >
                                        <div style={{marginRight: "0em", marginTop: "0.2em"}}>
                                            <div style={{textAlign: "right" }}>
                                                <p style={{marginBottom: "0.4em", marginRight: "0.5em"}}><b>Known</b></p>
                                                <p style={{marginLeft:"0.5em", marginRight: "0.5em"}}>Known verifier public identifiers, they did not interact with the platform yet.</p>
                                            </div>
                                        </div>
                                    </Group>

                                    
                                </Tabs.Panel>
                            </Tabs>

                            {/* {
                                members.map((member, index, array) => {


                                    return (
                                        <div>

                                        </div>
                                    );
                                })
                            } */}
                        </div>
                        {/* <Group
                            position="center"
                            spacing={"xs"}
                            grow={true}
                            style={{
                                fontSize: "0.9em",
                                lineHeight: "1.1",
                                maxHeight: "1.1em",
                                zIndex: 100,
                                textAlign: "center",
                                verticalAlign: "center"
                            }}
                        >
                            <div><p>v643: 123456789 (1000)</p></div>
                            <div><p>v644: 123456789 (800)</p></div>
                        </Group> */}
                        {/* <DiscussMeshInfoV1/> */}
                        {/* <MusicPlayModule/> */}
                    </Stack> }
                </DiscussChatDesktopColumn>
            </Card>
        </Grid.Col>
    );
}

// - Main
function DiscussChatDesktop({authContext}){
    const {classes: commonClasses} = useDesktopCommonStyles();
    const [leftSidebarIsOpen, setLeftSidebarIsOpen] = useState(false);
    // const leftSpan = leftSidebarIsOpen ? 3 : 1;
    // const rightSpan = rightSidebarIsOpen ? 3 : 1;
    // const middleSpan = 12 - (leftSpan + rightSpan);

    const { isMobile, isTablet, mainMenuIsHidden, logo } = useContext(AppContext);
    const [rightSidebarIsOpen, setRightSidebarIsOpen] = useState(!isMobile);

    const leftSpan = leftSidebarIsOpen ? 5 : 1;
    const rightSpan = rightSidebarIsOpen ? (isMobile ? 23 : 4) : 1;
    const middleSpan = 24 - (leftSpan + rightSpan);

    const chatManager = useContext(ChatContext);

    // todo
    // const loadingOverlayActive = !chatManager.initialStateReady;
    const loadingOverlayActive = !(chatManager.initialStateReady && chatManager.chatContextReady && !chatManager.sessionIsStale);

    return (
        <DynamicHeightSegment
            preludeElements={["navbar" /*, "discuss-footer-segment"*/]}
            id="discuss-chat-desktop-segment"
            classes={isMobile ? " " : "mt-3 mb-3"}
            style={{maxWidth: "100%", width: "100%"}}
        >
            <Card
                id="discuss-chat-desktop-segment-main-wrapper"
                className={commonClasses.cardTopLevel}
            >
                <LoadingOverlay visible={loadingOverlayActive} overlayBlur={0} zIndex={9000}/>
                <Grid 
                    columns={leftSpan + middleSpan + rightSpan}
                    id="discuss-chat-desktop-segment-main"
                    grow={true} // < width maximizer
                    className={`${commonClasses.affixTopLevel} ${commonClasses.gridTopLevel}`} // < height maximizer
                >
                    {/* any grid layout configs are determined here & pass through - allowing for a distinct space in case there were to be logic introduced (closing sidebars comes to mind) */}
                    <DiscussChatDesktopLeftSidebar
                        span={leftSpan}
                        toggle={setLeftSidebarIsOpen}
                        // toggleStatus={leftSidebarIsOpen}
                        toggleStatus={false}
                        showToggle={false}
                    />
                    {
                        (!isMobile || isMobile && !rightSidebarIsOpen) &&
                        <DiscussChatDesktopMiddleConsole
                        span={middleSpan}
                        toggleStatus={true}
                        authContext={authContext}
                    />
                    }
                    
                    <DiscussChatDesktopRightSidebar 
                        span={rightSpan}
                        toggle={setRightSidebarIsOpen}
                        toggleStatus={rightSidebarIsOpen}
                        // toggleStatus={false}
                        showToggle={true}
                    />
                </Grid>
            </Card>
        </DynamicHeightSegment>
    )
}

// This container acts as the mediator between the user and the chat modules, the intent is to inform the user of important aspects and possible pathways for 
// interacting with the chat. Due to the nyzo.org JS being minified there will be a barebones self-signing HTML page which the user can open, sign their message in, 
// and paste the string bytes into a separate input field
function DiscussChatSegment(){
    // const { isMobile } = useContext(AppContext);
    // const chatManager = useChatManager({
        // eventPollingFrequencyMilliSeconds: 1000
    // });
    const authContext = useContext(AuthContext);
    const { isMobile } = useContext(AppContext);

    return (
        <Segment id="discuss-segment" classes={ isMobile ? " " : null }>
            {
                // isMobile ?
                // <DiscussChatMobile chatManager={chatManager}/>
                // <DiscussChatDesktop chatManager={chatManager}/>
                // :
                <DiscussChatDesktop 
                    // chatManager={chatManager}
                    authContext={authContext}
                />
            }
        </Segment>
    );
}

// JSON.safeStringify = (obj, indent = 2) => {
//     let cache = [];
//     const retVal = JSON.stringify(
//       obj,
//       (key, value) =>
//         typeof value === "object" && value !== null
//           ? cache.includes(value)
//             ? undefined // Duplicate reference found, discard key
//             : cache.push(value) && value // Store value in our collection
//           : value,
//       indent
//     );
//     cache = null;
//     return retVal;
//   };


function Discuss() {
    const { isMobile } = useContext(AppContext);

    return (
        <Content 
            id="discuss-page" 
            
            style={{
                maxWidth: "96.5rem", 
                width: "100%", 
                paddingLeft: isMobile ? 0 : "1rem", 
                paddingRight: isMobile ? 0 : "1rem", 
            }}
        >
            <ChatWrapper>
                <DiscussChatSegment/>
            </ChatWrapper>
            {/* <DiscussMeshInfoV1/> */}
        </Content>
    );
}

export default Discuss;