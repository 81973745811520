
export const LogLevel = {
    DEBUG: "DEBUG",
    ERROR: "ERROR"
}

// todo possible to relay this to backend
export class Logger {
    static LogEvent(number, data=null, level=LogLevel.DEBUG) {
        return Logger.Log(`[EVENT]: ${number}`, data, level);
    }

    static LogError(number, data=null, level=LogLevel.ERROR){
        return Logger.Log(`[EVENT]: ${number}`, data, level);
    }

    static LogError(number, data=null, exception=null, level=LogLevel.ERROR){
        return Logger.Log(`[EVENT]: ${number}`, data, exception, level);
    }

    static Log(msg, data=null, exception=null, level=LogLevel.ERROR){
        console.error(msg, data, exception);
    }

    static Log(msg, data=null, level=LogLevel.DEBUG){
        switch(level){
            case LogLevel.DEBUG:
                console.debug(msg, data)
                break;
            case LogLevel.ERROR:
                console.error(msg, data);
                break;
            default:
                console.error(msg, data);
                break;
        }
    }
}