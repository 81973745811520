import { HoverCard, Group, Button, UnstyledButton, Text, SimpleGrid, ThemeIcon, Anchor, Divider, Center, Box, Burger, Drawer, Collapse, ScrollArea, rem, useMantineTheme, Image, createStyles, Header, Container, Grid, Menu, Avatar, useMantineColorScheme } from '@mantine/core';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Affiliate, Book2, BrandGithub, CalendarPlus, CalendarTime, Cash, ChartBar, ChevronDown, Code, CubeUnfolded, DeviceDesktop, Files, GitCommit, GitFork, Login, Logout, Map, Messages, Moon, Network, Prompt, Scale, Schema, Sun, Terminal, Wallet } from 'tabler-icons-react';
import { RouterPaths, UserPaths } from '../Static/RouterPaths';
import { AuthContext } from './Wrappers/AuthWrapper';
import { AppContext } from './Wrappers/AppWrapper';
import { IconBrandDiscordFilled, IconBrandTelegram, IconBrandTwitterFilled, IconCloudDollar, IconJson, IconUserDollar } from '@tabler/icons-react';
import logo2 from "../Assets/logo2.svg";
import logo3 from "../Assets/logo3.svg";
import nlogo from "../Assets/nlogo.png";
import intersectionsymbol from "../Assets/intersectionsymbol.png";
import { modals } from '@mantine/modals';

import white_bg_c0 from "../Assets/white_bg_c0.png";


// todo navbar & content color matching 
const useStyles = createStyles((theme) => ({
    logoImage:{
        // marginTop: "2.25em",
        opacity: "0.75",
        height: "1.7em!important",
        width: "1.7em!important", // 2 em todo
        [theme.fn.smallerThan("sm")]: {
           height: "1.7em!important",
           width: "1.7em!important",
           marginLeft: "-0.8em"
        },
        "&:hover": {
            opacity: "1.0"
        }
    },

    logoText: {
        fontSize: "1.2em", 
        fontWeight: 500,
        // [theme.fn.smallerThan("sm")]: {
        "@media (max-width: 898px)": {
            fontSize: "1.1em",
            fontWeight: 500
        },
        "@media (max-width: 357px)": {
            fontSize: "1em",
            fontWeight: 500
        },
        "@media (max-width: 340px)": {
            fontSize: "0.9em",
            fontWeight: 500
        },
        "@media (max-width: 320px)": {
            display: "none"
        }
    },

    header: {
        // backgroundColor: theme.colorScheme === "dark" ? "#212529" : "#ebebeb",
        // borderWidth: 0,

        // background: "linear-gradient(#090909f7, #1c1c1c),linear-gradient(90deg, #ec2a23, #008e16)",
        // background: "linear-gradient(#0e0e0e, #1a1b1e),linear-gradient(90deg, #ff0900, #ff00003d)",
        background: theme.colorScheme === "dark" ? "linear-gradient(#0f0f0f, #1a1b1e),linear-gradient(90deg, #ff0900, #ff0900)" : "#ebebeb",
        backgroundOrigin: "padding-box, border-box",
        backgroundRepeat: "no-repeat",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: "0.5px solid transparent",
        borderRadius: 5
        // background-origin: padding-box, border-box;
        // background-repeat: no-repeat; /* this is important */
        // border-top: 0px;
        // border-left: 0px;
        // border-right: 0px;
        // border-bottom: 0.2rem solid transparent;
        // border-radius: 5px;
    },

    dualColumn : {
        "@media (max-width: 898px)": { //806
            maxWidth: "50%", 
            flexBasis: "50%"
        },
    },

    inner: {
        maxWidth: "96.5rem", // < prev 1390
        height: 50, // < prev 70, adjust w h{x} applied to top div 
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },

    innerGrid: {
        width: "100%"
    },

    links: {
        width: "100%",
        // [theme.fn.smallerThan('sm')]: {
        //     display: 'none',
        // },
        "@media (max-width: 898px)": {
            display: "none"
        }
    },

    user: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        transition: 'background-color 100ms ease',

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
        },
    },

    username: {
        "@media (max-width: 984px)": {
            display: "none"
        },

        "@media (max-width: 1112px)": {
            fontSize: "0.7em"
        },

        "@media (min-width: 1112px, max-width: 1208px)": {
            fontSize: "0.5em"
        },

        "@media (min-width: 1208px)": {
            fontSize: "1em"
        }
    },
    
    //   burger: {
    //     [theme.fn.largerThan('xs')]: {
    //       display: 'none',
    //     },
    //   },
    
    userActive: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    burger: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: '8px 12px',
        borderRadius: theme.radius.lg,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.md,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === "dark" ? "rgba(255,20,12,0.31)" : "rgba(255, 8, 0, 0.26)", // theme.colors.dark[6] : 
            color: theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.colors.dark[7],
        },
    },

    linkLabel: {
        marginRight: 5,
    },
}));

// todo cleanup unused props

const Navbar = () => {
    const Navigator = useNavigate();
    // const [opened, setOpened] = useState(false);
    const [userMenuOpened, setUserMenuOpened] = useState(false);
    const { classes, theme, cx } = useStyles();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();

    const { isMobile, isTablet, mainMenuIsHidden, logo } = useContext(AppContext);
    const authContext = useContext(AuthContext);

    return (
        <Header id="navbar" height={50} mb={0} className={classes.header}>

            {/* <div
                style={{ 
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    marginRight: "2.4em",
                    marginBottom: "50vh",
                    display: (isMobile || isTablet) ? "none" : "",
                    zIndex: 10000
                }}
            >
                <Image 
                    src={white_bg_c0}
                    style={{
                        width: "8em",
                        height: "8em"
                    }}    
                />
            </div> */}

            <Container style={{
                maxWidth: "96.5rem" // prev prop xl 
            }}>
                <div className={classes.inner}>
                    <Grid className={classes.innerGrid} align="center">
                        <Grid.Col xs={6} sm={2} className={classes.dualColumn}>
                            <Group position="left" onClick={() => Navigator(RouterPaths.User.DISCUSS)}>
                                {/* <Image className={classes.logoImage} src={logo3}/> */}
                                {/* <span style={{fontFamily: "Arial, sans-serif", fontSize: "1.2em"}}>{`(◣_◢)`}</span> */}
                                <Image className={classes.logoImage} src={intersectionsymbol}/>
                            </Group>
                        </Grid.Col>
                        <Grid.Col sm={8} className={classes.links}>
                            <Group position="center" spacing={5}>
                                <Link
                                    key="goto-mesh-beta"
                                    className={classes.link}
                                    to={RouterPaths.User.MESH}
                                >
                                    Mesh
                                </Link>
                                <Link
                                    key="goto-docs"
                                    className={classes.link}
                                    to={"https://tech.nyzo.org"}
                                    target="_blank"
                                >
                                    Tech
                                </Link>
                                <Menu
                                    key="goto-core"
                                    trigger="click"
                                    closeDelay={0}
                                    openDelay={0}
                                    position="bottom"
                                >
                                    <Menu.Target>
                                        <Link
                                            className={classes.link}
                                        >
                                            Links
                                        </Link>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        <div>
                                            <center>
                                                <Menu.Label>Platform owner</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://construct0.com", "_blank").focus()}
                                                key="goto-platform-owner"
                                                icon={<CubeUnfolded size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0
                                                    </Text>
                                                }
                                            />

                                            <Divider/>

                                            <center>
                                                <Menu.Label>Code</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://github.com/n-y-z-o/nyzoVerifier", "_blank").focus()}
                                                key="goto-core-repo-official"
                                                icon={<GitCommit size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Official (archived)
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://github.com/construct0/nyzoVerifier", "_blank").focus()}
                                                key="goto-core-repo-c0"
                                                icon={<GitFork size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0 fork (active)
                                                    </Text>
                                                }
                                            />

                                            <Divider/>

                                            <center>
                                                <Menu.Label>Data</Menu.Label>
                                            </center>

                                            <Menu.Item
                                                onClick={() => window.open("https://blocks.nyzo.org", "_blank").focus()}
                                                key="goto-core-blocks"
                                                icon={<Files size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Block files
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://seed.nyzo.org", "_blank").focus()}
                                                key="goto-core-seed"
                                                icon={<Cash size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Seed transactions
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://dumps0.nyzo.org", "_blank").focus()}
                                                key="goto-core-dumps0"
                                                icon={<IconJson size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Dumps
                                                    </Text>
                                                }
                                            />

                                            <Divider/>
                                            
                                            <center>
                                                <Menu.Label>Social</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://nyzo.construct0.com", "_blank").focus()}
                                                key="goto-core-nyzo-discord"
                                                icon={<IconBrandDiscordFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Nyzo discord
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://discord.construct0.com", "_blank").focus()}
                                                key="goto-core-c0-discord"
                                                icon={<IconBrandDiscordFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0 discord
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://twitter.com/nyzo_io", "_blank").focus()}
                                                key="goto-core-nyzo-io-twitter"
                                                icon={<IconBrandTwitterFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        @nyzo_io twitter
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://twitter.com/nyzochain", "_blank").focus()}
                                                key="goto-core-nyzo-chain-twitter"
                                                icon={<IconBrandTwitterFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        @nyzochain twitter (inactive)
                                                    </Text>
                                                }
                                            />

                                            <Menu.Item
                                                onClick={() => window.open("https://t.me/NyzoCo", "_blank").focus()}
                                                key="goto-core-nyzo-tg-en"
                                                icon={<IconBrandTelegram size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        EN Telegram 
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://t.me/nyzo_cn", "_blank").focus()}
                                                key="goto-core-nyzo-tg-cn"
                                                icon={<IconBrandTelegram size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        CN Telegram 
                                                    </Text>
                                                }
                                            />

                                            <Divider/>

                                            <center>
                                                <Menu.Label>Other</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://client0.nyzo.org", "_blank").focus()}
                                                key="goto-core-client0"
                                                icon={<Terminal size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Client (v642)
                                                    </Text>
                                                }
                                            />
                                            
                                        </div>
                                    </Menu.Dropdown>
                                </Menu>
                                <Link
                                    key="goto-discuss"
                                    className={classes.link}
                                    to={RouterPaths.User.DISCUSS}
                                >
                                    Discuss
                                </Link>

                                {
                                    /* <Link
                                    key="goto-explore"
                                    className={classes.link}
                                    to={UserPaths.EXPLORE}
                                >
                                    Explore
                                </Link>
                                <Link
                                    key="goto-learn"
                                    className={classes.link}
                                    to={UserPaths.LEARN}
                                >
                                    Learn
                                </Link>
                                <Link
                                    key="goto-trade"
                                    className={classes.link}
                                    to={UserPaths.TRADE}
                                >
                                    Trade
                                </Link>
                                <Link
                                    key="goto-transact"
                                    className={classes.link}
                                    to={UserPaths.TRANSACT}
                                >
                                    Transact
                                </Link>
                                <Link
                                    key="goto-verify"
                                    className={classes.link}
                                    to={UserPaths.VERIFY}
                                >
                                    Verify
                                </Link>
                                <Link
                                    key="goto-vote"
                                    className={classes.link}
                                    to={UserPaths.VOTE}
                                >
                                    Vote
                                </Link>
                                <Link
                                    key="goto-discuss"
                                    className={classes.link}
                                    to={UserPaths.DISCUSS}
                                >
                                    Discuss
                                </Link> */
                                }

                                {
                                /* <ManagementMenu userIsAdmin={userIsAdmin} classes={classes}/> */
                                }

                            </Group>
                        </Grid.Col>
                        <Grid.Col xs={6} sm={2} className={classes.dualColumn}>
                            <Group position="right">
                                <Menu
                                    key="goto-user-dropdown"
                                    trigger="click"
                                    closeDelay={0}
                                    openDelay={0}
                                    position="bottom"
                                    onOpen={() => {
                                        document.body.style.overflow = "";
                                        document.body.style.overflowY = "scroll";
                                        document.body.style.overflowX = "hidden";
                                    }}
                                    onClose={() => {
                                        document.body.style.overflow = "hidden";
                                        document.body.style.overflowY = "hidden";
                                        document.body.style.overflowX = "hidden";
                                        window.scrollTo(0,0);
                                    }}
                                    style={{
                                        zIndex: 1000000
                                    }}
                                >
                                    <Menu.Target>
                                        <UnstyledButton
                                            className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                                        >
                                            <Group spacing={7}>
                                                {/* <Avatar src={authContext.User.Avatar} alt={authContext.User.Name} radius="xl" size={30} /> */}
                                                <Text className={classes.username} weight={500} size="sm" sx={{ lineHeight: 1 }} mr={0}>
                                                    {authContext.Session.nickname}
                                                </Text>
                                                <ChevronDown size={12} />
                                            </Group>
                                        </UnstyledButton>
                                    </Menu.Target>

                                    <Menu.Dropdown style={{
                                        zIndex: 1000000
                                    }}>
                                        <div style={!mainMenuIsHidden ? {display : "none"} : {overflow: "scroll"}}>
                                            {/* todo: */}
                                            <center>
                                                <Menu.Label>{authContext.Session.nickname}</Menu.Label>
                                            </center>

                                            <Menu.Item
                                                onClick={() => Navigator(RouterPaths.User.MESH)} 
                                                key="goto-mesh-beta"
                                                icon={<Network size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Mesh
                                                    </Text>
                                                }
                                            />

                                            <Menu.Item
                                                onClick={() => window.open("https://tech.nyzo.org", "_blank").focus()}
                                                key="goto-docs"
                                                icon={<Book2 size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Tech
                                                    </Text>
                                                }
                                            />

                                            <Menu.Item
                                                onClick={() => Navigator(RouterPaths.User.DISCUSS)} 
                                                key="goto-discuss"
                                                icon={<Messages size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Discuss
                                                    </Text>
                                                }
                                            />
                                            <center>
                                                <Menu.Label>Platform owner</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://construct0.com", "_blank").focus()}
                                                key="goto-platform-owner"
                                                icon={<CubeUnfolded size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0
                                                    </Text>
                                                }
                                            />

                                            <Divider/>
                                            <center>
                                                <Menu.Label>Code</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://github.com/n-y-z-o/nyzoVerifier", "_blank").focus()}
                                                key="goto-core-repo-official"
                                                icon={<GitCommit size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Official (archived)
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://github.com/construct0/nyzoVerifier", "_blank").focus()}
                                                key="goto-core-repo-c0"
                                                icon={<GitFork size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0 fork (active)
                                                    </Text>
                                                }
                                            />

                                            <Divider/>

                                            <center>
                                                <Menu.Label>Data</Menu.Label>
                                            </center>

                                            <Menu.Item
                                                onClick={() => window.open("https://blocks.nyzo.org", "_blank").focus()}
                                                key="goto-core-blocks"
                                                icon={<Files size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Block files
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://seed.nyzo.org", "_blank").focus()}
                                                key="goto-core-seed"
                                                icon={<Cash size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Seed transactions
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://dumps0.nyzo.org", "_blank").focus()}
                                                key="goto-core-dumps0"
                                                icon={<IconJson size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Dumps
                                                    </Text>
                                                }
                                            />

                                            <Divider/>
                                            
                                            <center>
                                                <Menu.Label>Social</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://nyzo.construct0.com", "_blank").focus()}
                                                key="goto-core-nyzo-discord"
                                                icon={<IconBrandDiscordFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Nyzo discord
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://discord.construct0.com", "_blank").focus()}
                                                key="goto-core-c0-discord"
                                                icon={<IconBrandDiscordFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0 discord
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => window.open("https://twitter.com/nyzo_io", "_blank").focus()}
                                                key="goto-core-nyzo-io-twitter"
                                                icon={<IconBrandTwitterFilled size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        @nyzo_io twitter
                                                    </Text>
                                                }
                                            />

                                            <Divider/>

                                            <center>
                                                <Menu.Label>Other</Menu.Label>
                                            </center>
                                            <Menu.Item
                                                onClick={() => window.open("https://client0.nyzo.org", "_blank").focus()}
                                                key="goto-core-client0"
                                                icon={<Terminal size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Client (v642)
                                                    </Text>
                                                }
                                            />

                                            {/* <Menu.Item
                                                onClick={() => window.open("https://construct0.com", "_blank").focus()}
                                                key="goto-construct0"
                                                icon={<Code size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        construct0
                                                    </Text>
                                                }
                                            /> */}
                                             
                                            {/* <Menu.Item
                                                onClick={() => Navigator(UserPaths.DISCUSS)} 
                                                key="goto-explore"
                                                icon={<Network size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Explore
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DEBUG)} 
                                                key="goto-learn"
                                                icon={<Schema size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Learn
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DOCUMENT)} 
                                                key="goto-trade"
                                                icon={<ChartBar size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Trade
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DOCUMENT)} 
                                                key="goto-transact"
                                                icon={<Wallet size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Transact
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DOCUMENT)} 
                                                key="goto-verify"
                                                // icon={<IconUserDollar size={16}/>}
                                                icon={<IconCloudDollar size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Verify
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DOCUMENT)} 
                                                key="goto-vote"
                                                icon={<Scale size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Vote
                                                    </Text>
                                                }
                                            />
                                            <Menu.Item
                                                onClick={() => Navigator(UserPaths.DOCUMENT)} 
                                                key="goto-discuss"
                                                icon={<Messages size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Discuss
                                                    </Text>
                                                }
                                            /> */}
                                            <Divider/>
                                            {/* todo admin menu? */}
                                        </div>
                                        {/* ugly mode <Menu.Item
                                            onClick={() => toggleColorScheme()} 
                                            key="toggle-colorscheme"
                                            icon={colorScheme === "dark" ? <Sun size={16}/> : <Moon size={16}/>}
                                            rightSection={
                                                <Text>
                                                    {colorScheme === "dark" ? "Light mode" : "Dark mode"}
                                                </Text>
                                            }
                                        /> */}
                                        {
                                            !authContext.Session.loggedIn ? 
                                            <Menu.Item
                                                onClick={() => {
                                                    modals.openContextModal({
                                                        modal: "NavbarSignInModal",
                                                        centered: true,
                                                        size: "xl",
                                                        scrollAreaComponent: ScrollArea.Autosize,
                                                        closeOnClickOutside: false,
                                                        closeOnEscape: true,
                                                        trapFocus: true,

                                                        title: "Sign in",
                                                        innerProps: {
                                                            authContext: authContext
                                                        }
                                                    });
                                                }} 
                                                key="goto-sign-in"
                                                icon={<Login size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Sign in
                                                    </Text>
                                                }
                                            />
                                            : 
                                            <Menu.Item
                                                onClick={() => {
                                                    modals.openContextModal({
                                                        modal: "NavbarSignOutModal",
                                                        centered: true,
                                                        size:"sm",
                                                        closeOnClickOutside: true,
                                                        closeOnEscape: true,
                                                        trapFocus: false,

                                                        title: "Sign out",
                                                        innerProps: {
                                                            authContext: authContext
                                                        }
                                                    })
                                                }}
                                                key="goto-sign-out"
                                                icon={<Logout size={16}/>}
                                                rightSection={
                                                    <Text>
                                                        Sign out
                                                    </Text>
                                                }
                                            />
                                        }
                                        {/* <Menu.Item
                                            onClick={() => {
                                                authContext.Logout();
                                                Navigator(UserPaths.HOMEPAGE);
                                            }} 
                                            key="goto-logout"
                                            icon={<Logout size={16}/>}
                                            rightSection={
                                                <Text>
                                                    Sign out
                                                </Text>
                                            }
                                        /> */}
                                    </Menu.Dropdown>
                                    
                                </Menu>
                            </Group>
                        </Grid.Col>
                    </Grid>
                </div>
            </Container>
        </Header>
    );
}

export default Navbar;

