const UserPaths = {
    HOMEPAGE: "/",

    // AUTH: "/auth",

    // EXPLORE: "/explore",
    // LEARN: "/learn",
    // TRADE: "/trade",
    // TRANSACT: "/transact",
    // VERIFY: "/verify",
    // VOTE: "/vote",
    DISCUSS: "/discuss",
    MESH: "/mesh",

    TEST1: "/test1",
};

// todo repl with actual values:
const ApiPaths = {
    // INIT: "/api/initiation",
    INIT: "/",
    AUTH: "https://api.construct0.com/api/chat/mini/v1",
    DISCUSS: "https://api.construct0.com/api/chat/mini/v1"
}

const ExternalPaths = {
    NCO: "https://nyzo.co",
    N_DISCORD: "https://discord.gg/JY92JeewNR",
    C0: "https://construct0.com",
    C0_DISCORD: "https://discord.construct0.com",
}

export const RouterPaths = {
    User: UserPaths, 
    API: ApiPaths,
    External: ExternalPaths
}
