import { createContext, useEffect, useState } from "react";
import { InitiationWrapper } from "./InitiationWrapper";
import { User } from "tabler-icons-react";
import { RouterPaths } from "../../Static/RouterPaths";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useLocalStorage } from "@mantine/hooks";

const cloneDeep = require("lodash.clonedeep");

export const AuthContext = createContext();

// Specific purpose: authentication and authorisation collective
export function AuthWrapper({children}){
    const signedOutSession = {
        loggedIn: false,
        expiresOn: null,

        publicIdentifierHexString: null,
        token: null,
        nickname: "Anonymous",
    
        isAdmin: null,
        chatCooldownUntil: null,
        timedOutUntil: null
    };

    const API = RouterPaths.API.AUTH;
    const _api = (path) => {
        return `${API}${path}`;
    }

    const [session, setSession] = useState(signedOutSession);
    const [loopActive, setLoopActive] = useState(false);
    const [loopDatetime, setLoopDatetime] = useState(new Date(0));

    const [_cooldownUntil, _setCooldownUntil] = useLocalStorage({
        key: "chat-cooldown-until", 
        defaultValue: new Date(0),
        serialize: (value) => {
            return value.toISOString();
        },
        deserialize: (lsvalue) => {
            return new Date(lsvalue);
        }
    });

    const [_timeoutUntil, _setTimeoutUntil] = useLocalStorage({
        key: "chat-timeout-until", 
        defaultValue: new Date(0),
        serialize: (value) => {
            return value.toISOString();
        },
        deserialize: (lsvalue) => {
            return new Date(lsvalue);
        }
    });

    const [_bannedUntil, _setBannedUntil] = useLocalStorage({
        key: "chat-banned-until", 
        defaultValue: new Date(0),
        serialize: (value) => {
            return value.toISOString();
        },
        deserialize: (lsvalue) => {
            return new Date(lsvalue);
        }
    });

    const Onload = async () => {
        let cookies = TryGetAuthBrowserCookies();

        if(cookies.publicIdentifierHexString){
            // SetActiveSession(cookies.publicIdentifierHexString, cookies.token, cookies.expiresOn, cookies.nickname);

            await fetch(new Request(_api(`/user/verifier/${cookies.publicIdentifierHexString}`), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
            }))
            .then(response => {
                if(!response.ok){
                    throw new Error("Failed to connect to endpoint", response);
                }

                return response.json();
            })
            .then(data => {
                if(data.success){
                    if(data.content && Array.isArray(data.content) && data.content.length > 0){
                        let responseItem = data.content[0]; // todo isobject check, same in other fns in this file !

                        SetActiveSession(cookies.publicIdentifierHexString, cookies.token, cookies.expiresOn, responseItem.nickname ?? cookies.nickname, responseItem.isChatAdmin, responseItem.chatCooldownUntil, responseItem.timedOutUntil, responseItem.bannedUntil);
                        setLoopActive(true);
                    } else {
                        //log
                        ExpireSession();
                        
                        notifications.show({
                            id: "onload-fetch-user-0",
                            withCloseButton: true,
                            autoClose: 10000,
                            title: "Authentication failed",
                            message: "Sign in to re-establish a session.",
                            color: "red",
                            icon: <IconX/>
                        });
                    }
                } else {
                    // log
                    throw 1;
                }
            })
            .catch(e => {
                ExpireSession();
                
                notifications.show({
                    id: "onload-fetch-user-1",
                    withCloseButton: true,
                    autoClose: 10000,
                    title: "Failed to fetch information about your account",
                    message: "Sign in to re-establish a session.",
                    color: "red",
                    icon: <IconX/>
                });
            })
            ;
        }
    }

    const SignIn = async (publicIdentifierHexString, signedMessageHexString) => {
        if(session.loggedIn){
            notifications.show({
                id: "signin-fetch-0",
                withCloseButton: true,
                autoClose: 10000,
                title: "Signing in is not possible",
                message: "You are already signed in.",
                color: "red",
                icon: <IconX/>
            });
            // log
            return;
        }

        notifications.show({
            id: "signin-fetch-1",
            loading: true,
            title: "Signing in",
            autoClose: false,
            withCloseButton: false,
        });

        await fetch(new Request(_api("/auth/signin"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                PublicIdentifierHexString: publicIdentifierHexString,
                SignedMessageHexString: signedMessageHexString
            })
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                if(data.content && Array.isArray(data.content) && data.content.length > 0){
                    let responseItem = data.content[0];
                
                    if(responseItem.token && responseItem.publicIdentifierHexString && responseItem.ends && responseItem.nickname){
                        SetBrowserCookie(responseItem.publicIdentifierHexString, responseItem.token, new Date(responseItem.ends), responseItem.nickname);
                        SetActiveSession(responseItem.publicIdentifierHexString, responseItem.token, new Date(responseItem.ends), responseItem.nickname, responseItem.isAdmin, responseItem.chatCooldownUntil, responseItem.timedOutUntil, responseItem.bannedUntil);
                        setLoopActive(true);
                        notifications.update({
                            id: "signin-fetch-1",
                            color: "teal",
                            title: "Signing in successful",
                            message: `Welcome back ${responseItem.nickname}!`,
                            icon: <IconCheck size="1rem"/>,
                            autoClose: 3000
                        });
                    } else {
                        notifications.update({
                            id: "signin-fetch-1",
                            color: "red",
                            title: "Signing in failed",
                            message: "Contact support.",
                            icon: <IconX/>,
                            autoClose: 10000
                        });
                        // missing data, notif and log
                    }
                } else {
                    notifications.update({
                        id: "signin-fetch-1",
                        color: "red",
                        title: "Signing in failed",
                        message: "Contact support.",
                        icon: <IconX/>,
                        autoClose: 10000
                    });
                    // unexpected content
                }
            } else {
                notifications.update({
                    id: "signin-fetch-1",
                    color: "red",
                    title: "Signing in failed",
                    message: "Session rejected. Verify that the session is currently active and the information provided is correct.",
                    icon: <IconX/>,
                    autoClose: 20000
                    // autoClose: false
                });
                // if msgs contains "Signing in failed." in body prop of >=1 msg
            }
        })
        .catch(e => {
            // log

            notifications.update({
                id: "signin-fetch-1",
                color: "red",
                title: "Signing in failed",
                message: "An unexpected error occurred, contact support.",
                icon: <IconX/>,
                autoClose: 10000
            });
        })
    }

    const SignOut= async (expireAllSessions=false) => {
        if(!session.loggedIn){
            if(TryGetAuthBrowserCookies()){
                DeleteBrowserCookie();
            }

            notifications.show({
                id: "signout-fetch-0",
                withCloseButton: true,
                autoClose: 10000,
                title: "Signing out failed",
                message: "You are currently not signed in.",
                color: "red",
                icon: <IconX/>
            });            
            // notif
            return;
        }

        notifications.show({
            id: "signout-fetch-1",
            loading: true,
            title: "Signing out",
            autoClose: false,
            withCloseButton: false,
        });

        await fetch(new Request(_api("/auth/signout"), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "PublicIdentifierHexString": session.publicIdentifierHexString,
                "Token": session.token
            },
            body: JSON.stringify({
                endAllActiveSessions: expireAllSessions ? true : false // truthy to bool
            })
        }))
        .then(response => {
            if(!response.ok){
                throw new Error("Failed to connect to endpoint", response);
            }

            return response.json();
        })
        .then(data => {
            if(data.success){
                // notif
                ExpireSession();

                notifications.update({
                    id: "signout-fetch-1",
                    color: "teal",
                    title: "Signing out successful",
                    icon: <IconCheck size="0.75rem"/>,
                    autoClose: 3000
                });
            } else {
                // notif
                notifications.update({
                    id: "signout-fetch-1",
                    color: "red",
                    title: "Signing out failed",
                    message: "Your session may have expired, try refreshing your browser window.",
                    icon: <IconX/>,
                    autoClose: 20000
                });
            }
        })
        .catch(e => {
            // notif
            notifications.update({
                id: "signout-fetch-1",
                color: "red",
                title: "Signing out failed",
                message: "An unexpected error occurred.",
                icon: <IconX/>,
                autoClose: 20000
            });
        })
    }

    const TryGetAuthBrowserCookies= () => {
        let result = {
            publicIdentifierHexString: null,
            token: null,
            expiresOn: null,
            nickname: null
        }

        let allCookies = document.cookie;

        // console.log(allCookies);

        if(allCookies?.length > 0){
            let allCookiesList = allCookies.split(";");

            for(let i=0; i<allCookiesList.length;i++){
                let cookie = allCookiesList[i];
                cookie = cookie.trim().replace("\t", "").replace("\n", "");

                let kvp=cookie.split("=");

                if(kvp.length == 2){
                    let key=kvp[0];
                    let value=kvp[1];

                    if(key == "publicIdentifier"){
                        result.publicIdentifierHexString = value;
                    } else if(key == "token"){
                        result.token = value;
                    } else if(key == "nickname"){
                        result.nickname = value;
                    }
                }
            }
        }

        // if(Object.values(result).find(x => !x)){
        //     if(session.loggedIn){
        //         ExpireSession();

        //         notifications.show({
        //             id: "try-get-cookies-expired-session",
        //             withCloseButton: true,
        //             autoClose: false,
        //             title: "Session expired",
        //             message: "Your session has reached its expiration date, you are now signed out.",
        //             color: "red",
        //             icon: <IconX/>
        //         });
        //     }

        //     return null;
        // }

        return result;
    }

    const ExpireSession= () => {
        console.log("expire");
        setSession(signedOutSession);
        DeleteBrowserCookie();
        setLoopActive(false);
        _setCooldownUntil(new Date(0));
        _setTimeoutUntil(new Date(0));
        _setBannedUntil(new Date(0));
    }

    const DeleteBrowserCookie= () => {
        document.cookie = "publicIdentifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure";
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure";
        document.cookie = "nickname=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure";
    }

    // // Do not call within wrapper itself
    // const SetSessionChatCooldownUntil= (dt)=> {
    //     let sessionCopy = cloneDeep(session);
    //     sessionCopy.chatCooldownUntil = dt;

    //     setSession(sessionCopy);
    // }

    // // Do not call within wrapper itself
    // const SetSessionTimedOutUntil= (dt) => {
    //     let sessionCopy = cloneDeep(session);
    //     sessionCopy.timedOutUntil = dt;

    //     setSession(sessionCopy);
    // }

    const SetBrowserCookie= (publicIdentifier, token, expiresOn, nickname) => {
        document.cookie = `publicIdentifier=${publicIdentifier}; expires=${expiresOn.toUTCString()}; Secure`;
        document.cookie = `token=${token}; expires=${expiresOn.toUTCString()}; Secure`;
        document.cookie = `nickname=${nickname}; expires=${expiresOn.toUTCString()}; Secure`;
    }

    const SetLocalStoragePrivilegeLimiters = (chatCooldownUntil, timedOutUntil, bannedUntil) => {
        let now = new Date(Date.now());
        let cooldownUntilDateTime = new Date(chatCooldownUntil);

        if(timedOutUntil){
            let timedOutUntilDateTime = new Date(timedOutUntil);

            _setTimeoutUntil(timedOutUntilDateTime);
        }

        if(bannedUntil){
            let bannedUntilDateTime = new Date(bannedUntil);

            _setBannedUntil(bannedUntilDateTime);
        }

        _setCooldownUntil(cooldownUntilDateTime);
    }

    const SetActiveSession= (publicIdentifier, token, expiresOn, nickname, isAdmin, chatCooldownUntil, timedOutUntil, bannedUntil) => {
        SetLocalStoragePrivilegeLimiters(chatCooldownUntil, timedOutUntil, bannedUntil);

        setSession({
            loggedIn: true,
            publicIdentifierHexString: publicIdentifier,
            token: token,
            expiresOn: expiresOn,
            nickname: nickname,

            isAdmin: isAdmin,
            chatCooldownUntil: chatCooldownUntil,
            timedOutUntil: timedOutUntil
        });
    }

    const ExpireSessionByCheckingFailedAuthedRequestResult= (response) => {
        if(!response.success){
            if(Array.isArray(response.messages) && response.messages.length > 0){
                let shouldExpire = false;

                for(let i=0; i<response.messages.length;i++){
                    let msg = response.messages[i];

                    if(msg.type.startsWith("Authentication failed")){
                        shouldExpire = true;
                    }
                }

                if(shouldExpire) {
                    ExpireSession();
                    // notif

                    notifications.show({
                        id: "failed-authed-req",
                        withCloseButton: true,
                        autoClose: 20000,
                        title: "Authentication failed",
                        message: "Your session may have expired or too many sessions are active. Try logging out of one of your other existing sessions.",
                        color: "red",
                        icon: <IconX/>
                    });
                }
            }
        }
    }

    const NotifyThirdPartyLoggedIn = (nickname, publicIdentifierHexString) => {
        // Shit workaround but its not critical or w/e
        if(session.loggedIn){


            if(session.publicIdentifierHexString != publicIdentifierHexString){
                notifications.show({
                    id: "user-signed-in-event",
                    color: "teal",
                    title: `${nickname} has signed in`,
                    message: ``,
                    icon: <User/>,
                    autoclose: 15000,
                    withCloseButton: true
                })
            }
        }
    }

    useEffect(async () => {
        await Onload();
    }, [])

    useEffect(async () => {
        if(loopActive){
            await new Promise(r => setTimeout(r, 1000));

            // console.log("check");
            let cookies = TryGetAuthBrowserCookies();

            // console.log(cookies);

            if(!cookies.publicIdentifierHexString){
                await new Promise(r => setTimeout(r, (2500)));

                setSession((curr) => {
                    if(curr.loggedIn){
                        ExpireSession();

                        notifications.show({
                            id: "try-get-cookies-expired-session",
                            withCloseButton: true,
                            autoClose: false,
                            title: "Session expired",
                            message: "Your session has reached its expiration date, you are now signed out.",
                            color: "red",
                            icon: <IconX/>
                        });

                        return signedOutSession;
                    }

                    return curr;
                })
            }

            setLoopDatetime(new Date(Date.now()));
        }
    }, [loopActive, loopDatetime]);

    return (
        <AuthContext.Provider value={{
            Session: session,
            Interaction: {
                cooldownUntil: _cooldownUntil,
                cooldownActive: (+new Date()) < (+_cooldownUntil),

                timeoutUntil: _timeoutUntil,
                timeoutActive: (+new Date()) < (+_timeoutUntil),
                
                bannedUntil: _bannedUntil,
                bannedActive: (+new Date()) < (+_bannedUntil),
            },
            fn: {
                SignIn: SignIn,
                SignOut: SignOut,
                TryGetAuthBrowserCookies: TryGetAuthBrowserCookies,
                ExpireSession: ExpireSession,
                DeleteBrowserCookie: DeleteBrowserCookie,
                // SetSessionChatCooldownUntil: SetSessionChatCooldownUntil,
                // SetSessionTimedOutUntil: SetSessionTimedOutUntil,
                SetBrowserCookie: SetBrowserCookie,
                SetLocalStoragePrivilegeLimiters: SetLocalStoragePrivilegeLimiters,
                SetActiveSession: SetActiveSession,
                ExpireSessionByCheckingFailedAuthedRequestResult: ExpireSessionByCheckingFailedAuthedRequestResult,
                NotifyThirdPartyLoggedIn: NotifyThirdPartyLoggedIn,
            },
        }}>
            {children}
        </AuthContext.Provider>
    );
}




